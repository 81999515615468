import { authSplitApi } from 'antd/redux/helpers/slice.helpers';
import { EMaintainerUrl } from 'antd/data/urls/maintainer.url';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { queryParamConverter } from 'antd/data/converters/general.converters';
import { pageableDataConverter } from 'antd/data/converters/pagableData.converters';
import {
  TPageableDataParamUI,
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'antd/data/types/pagableData.type';
import {
  TAssignedPropertyDB,
  TAssignedPropertyUI,
  TAssignPropertiesUI,
  TInviteMaintainerUI,
  TMaintainerDB,
  TMaintainerDetailsDB,
  TMaintainerDetailsUI,
  TMaintainerUI,
  TUpdateCommunicationUI,
} from 'antd/data/types/maintainer.types';
import {
  assignedPropertiesGetConverter,
  assignPropertiesConverter,
  inviteMaintainerConverter,
  maintainerDetailsConverter,
  maintainerGetConverter,
  updateCommunicationConverter,
} from 'antd/data/converters/maintainer.converters';

const MAINTAINER = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EMaintainerUrl.MAINTAINER}`;

export const maintainerApi = authSplitApi('maintainerApi', [
  'maintainers',
  'maintainer',
  'assignedProperties',
  'unassignedProperties',
]).injectEndpoints({
  endpoints: (build) => ({
    getMaintainers: build.query<TPageableDataWithContentUi<TMaintainerUI[]>, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: MAINTAINER,
          method: 'GET',
          params: queryParamConverter(search, pagination),
        };
      },
      transformResponse: (data: TPageableDataWithContent<TMaintainerDB[]>) => {
        const { content, ...other } = data;
        return {
          content: content.map((item) => maintainerGetConverter.fromDb(item)),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
      providesTags: ['maintainers'],
    }),
    getMaintainer: build.query<TMaintainerDetailsUI, string>({
      query(id) {
        return {
          url: `${MAINTAINER}/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['maintainer'],
      transformResponse: (data: TMaintainerDetailsDB) => maintainerDetailsConverter.fromDb(data),
    }),
    getAssignedProperties: build.query<
      TPageableDataWithContentUi<TAssignedPropertyUI[]>,
      TPageableDataParamUI & { maintainerId: string }
    >({
      query({ search, pagination, maintainerId }) {
        return {
          url: `${MAINTAINER}/${EMaintainerUrl.PROPERTIES}/${EMaintainerUrl.ASSIGNED}`,
          method: 'GET',
          params: queryParamConverter(search, pagination, { maintainerId }),
        };
      },
      transformResponse: (data: TPageableDataWithContent<TAssignedPropertyDB[]>) => {
        const { content, ...other } = data;
        return {
          content: content.map((item) => assignedPropertiesGetConverter.fromDb(item)),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
      providesTags: ['assignedProperties'],
    }),
    getUnassignedProperties: build.query<null, { maintainerId: string }>({
      query({ maintainerId }) {
        return {
          url: `${MAINTAINER}/${EMaintainerUrl.PROPERTIES}/${EMaintainerUrl.UNASSIGNED}`,
          method: 'GET',
          params: { maintainerId },
        };
      },
      providesTags: ['unassignedProperties'],
    }),
    inviteMaintainer: build.mutation<void, TInviteMaintainerUI>({
      query(body) {
        return {
          url: `${MAINTAINER}/${EMaintainerUrl.INVITE}`,
          method: 'POST',
          body: inviteMaintainerConverter.toDb(body),
        };
      },
      invalidatesTags: ['maintainer', 'maintainers'],
    }),
    assignProperties: build.mutation<void, TAssignPropertiesUI>({
      query(body) {
        return {
          url: `${MAINTAINER}/${EMaintainerUrl.PROPERTIES}`,
          method: 'POST',
          body: assignPropertiesConverter.toDb(body),
        };
      },
      invalidatesTags: ['maintainer', 'maintainers', 'assignedProperties', 'unassignedProperties'],
    }),
    unassignProperties: build.mutation<void, TAssignPropertiesUI>({
      query(body) {
        return {
          url: `${MAINTAINER}/${EMaintainerUrl.PROPERTIES}`,
          method: 'DELETE',
          body: assignPropertiesConverter.toDb(body),
        };
      },
      invalidatesTags: ['maintainer', 'maintainers', 'assignedProperties', 'unassignedProperties'],
    }),
    updateCommunication: build.mutation<void, TUpdateCommunicationUI>({
      query(body) {
        return {
          url: `${MAINTAINER}/${EMaintainerUrl.COMMUNICATION}`,
          method: 'PATCH',
          body: updateCommunicationConverter.toDb(body),
        };
      },
      invalidatesTags: ['maintainer', 'maintainers'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetMaintainersQuery,
  useGetMaintainerQuery,
  useUpdateCommunicationMutation,
  useInviteMaintainerMutation,
  useAssignPropertiesMutation,
  useUnassignPropertiesMutation,
  useGetAssignedPropertiesQuery,
  useGetUnassignedPropertiesQuery,
} = maintainerApi;
