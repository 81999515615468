import { authSplitApi } from 'antd/redux/helpers/slice.helpers';
import { EGeneralUrls } from 'antd/data/urls/general.url';
import { TMultiOptionTypeMaterialUI, TOptionType } from 'antd/data/types/general.types';
import { materialOptionConverter } from 'antd/data/converters/general.converters';

const baseUrl = `${EGeneralUrls.TENANT}/api/v2`;

export const generalApi = authSplitApi('generalApi', ['primaryColor', 'species']).injectEndpoints({
  endpoints: (build) => ({
    getSpecies: build.query<TMultiOptionTypeMaterialUI[], void>({
      query() {
        return {
          url: `${baseUrl}/${EGeneralUrls.ANIMALS}/${EGeneralUrls.SPECIES}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TOptionType[]) => (data || []).map((item) => materialOptionConverter.fromDb(item)),
      providesTags: ['species'], // for caching and update cache
    }),
    getColors: build.query<TMultiOptionTypeMaterialUI[], void>({
      query() {
        return {
          url: `${baseUrl}/${EGeneralUrls.ANIMALS}/${EGeneralUrls.COLORS}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TOptionType[]) => (data || []).map((item) => materialOptionConverter.fromDb(item)),
      providesTags: ['primaryColor'], // for caching and update cache
    }),
  }),
  overrideExisting: false,
});

export const { useGetSpeciesQuery, useGetColorsQuery } = generalApi;
