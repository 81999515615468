import { ERentalsUrls } from 'antd/data/urls/rentals.url';
import {
  TRentalMetadataDB,
  TRentalMetadataUI,
  TRentalUnitCreateUI,
  TRentalUnitResponseUI,
} from 'antd/data/types/rentals.types';
import { rentalMetadataConverter } from 'antd/data/converters/rentals.converters';
import { authSplitApi } from '../helpers/slice.helpers';

const URL = `${import.meta.env.REACT_APP_HOSTNAME}/api/v1/${ERentalsUrls.RENTALS}`;

export const renatalsApi = authSplitApi('rentals').injectEndpoints({
  endpoints: (build) => ({
    createRentalUnit: build.mutation<TRentalUnitResponseUI, TRentalUnitCreateUI>({
      query({ rentalId, unitNumbers }) {
        return {
          url: `${URL}/${rentalId}/${ERentalsUrls.UNITS}`,
          method: 'POST',
          body: { unitNumbers },
        };
      },
    }),
    getRentalUnitsMetadata: build.query<TRentalMetadataUI[], string | void>({
      query(id) {
        return {
          url: `${URL}/${ERentalsUrls.METADATA}`,
          method: 'GET',
          ...(id && { params: { landlordId: id } }),
        };
      },
      transformResponse: (data: TRentalMetadataDB[]) =>
        (data || []).map((item) => rentalMetadataConverter.fromDb(item)),
    }),
  }),

  overrideExisting: false,
});

export const { useCreateRentalUnitMutation, useGetRentalUnitsMetadataQuery } = renatalsApi;
