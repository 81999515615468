import mammoth from 'mammoth';

export const downloadFile = (file: Blob, fileName: string, ext?: string) => {
  const link = window.URL.createObjectURL(file);
  const anchorTag = document.createElement('a');
  anchorTag.setAttribute('href', link);
  let fn = `${fileName}`;
  const fileNameMap = fileName.split('.');

  if (ext) {
    fn += `.${ext}`;
  } else if (file.type === 'application/octet-stream' && !['csv'].includes(fileNameMap[fileNameMap.length - 1])) {
    fn += '.doc';
  }

  anchorTag.setAttribute('download', fn);
  anchorTag.style.display = 'none';
  document.body.appendChild(anchorTag);
  anchorTag.click();
  document.body.removeChild(anchorTag);
};

export const downloadFileData = (url: string, name: string) =>
  fetch(url, {
    cache: 'no-cache',
  }).then((response) => response.blob().then((blob) => downloadFile(blob, name)));

export const filePreviewData = (fileUrl: string) =>
  fetch(fileUrl)
    .then((response) => response.text())
    .then((text) => text);

export const getArrayBufferFromUrl = async (url: string) => {
  const response = await fetch(url);
  return response.arrayBuffer();
};

export const convertDocToHtml = async (file: ArrayBuffer) => {
  const result = await mammoth.convertToHtml({ arrayBuffer: file });
  return result.value;
};

export function b64toBlob(base64String: string, type: string) {
  const decodedString = Buffer.from(base64String, 'base64');
  return new Blob([decodedString], { type });
}

export function b64toFile(byteArrayString: string, fileName: string, fileType: string) {
  // Parse byte array string and convert it to Uint8Array
  const byteArray = new Uint8Array(byteArrayString.split(',').map(Number));

  // Create Blob from Uint8Array
  const blob = new Blob([byteArray], { type: fileType });

  // Create File object from Blob
  return new File([blob], fileName, { type: fileType });
}
