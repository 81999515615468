export enum EComplaintTypeUI {
  COMPLAINT = 'Complaint',
  VIOLATION = 'Violation',
}
export enum EComplaintTypeDB {
  COMPLAINT = 'COMPLAINT',
  VIOLATION = 'VIOLATION',
}

export enum ECreatorTypeUI {
  MAINTAINER = 'Maintainer',
  PROPERTY_MANAGER = 'Property manager',
  TENANT = 'Tenant',
}

export enum ECreatorTypeDB {
  MAINTAINER = 'MAINTAINER',
  PROPERTY_MANAGER = 'PROPERTY_MANAGER',
  TENANT = 'TENANT',
}
export enum EComplaintTitleUI {
  UNAUTHORIZED_ANIMAL = 'Unauthorized animal',
  DAMAGE = 'Damage',
  NOISE_DISTURBANCE = 'Noise disturbance',
  WASTE_MANAGEMENT = 'Waste management',
  PET_RULE_VIOLATION = 'Pet rule violation',
  AGGRESSIVE_BEHAVIOR = 'Aggressive Behavior',
  OTHER = 'Other',
}
export enum EComplaintTitleDB {
  UNAUTHORIZED_ANIMAL = 'UNAUTHORIZED_ANIMAL',
  DAMAGE = 'DAMAGE',
  NOISE_DISTURBANCE = 'NOISE_DISTURBANCE',
  WASTE_MANAGEMENT = 'WASTE_MANAGEMENT',
  PET_RULE_VIOLATION = 'PET_RULE_VIOLATION',
  AGGRESSIVE_BEHAVIOR = 'AGGRESSIVE_BEHAVIOR',
  OTHER = 'OTHER',
}

export type TCommentDB = {
  creatorId: string;
  creatorName: string;
  createdAt: string;
  owenComment: boolean;
  massage: string;
};

export type TCommentUI = {
  creatorId: string;
  creatorName: string;
  createdAt: string;
  owenComment: boolean;
  message: string;
};

export type TCommentDataUI = {
  commentsCount: number;
  commentsList: TCommentUI[];
};
export type TCommentDataDB = {
  commentsCount: number;
  commentsList: TCommentDB[];
};

export type TCreatorUI = {
  creatorId: string;
  creatorName: string;
  creatorPhoto: string;
  creatorType: ECreatorTypeUI;
  firstLetters?: string;
};
export type TCreatorDB = {
  reporterId: string;
  reporterName: string;
  reporterPhoto: string;
  reporterProfileType: ECreatorTypeDB;
};
export type TResidentUI = {
  residenceId: string;
  residentName: string | null;
};

export type TResidentDB = {
  residenceId: string;
  residentName: string | null;
};

export type TViolatorUI = {
  violatorPropertyId: string;
  violatorPropertyName: string;
  violatorName: string | null;
  violatorUnitName: string | null;
  firstLetters?: string;
};

export type TViolatorDB = {
  violatorPropertyId: string;
  violatorPropertyName: string;
  violatorName: string | null;
  violatorUnitName: string | null;
};

export type TMainChildComplaintUI = {
  createdAt: string;
  type: EComplaintTypeUI;
  title: EComplaintTitleUI;
  description: string;
  hasUnreadComment: boolean;
  commentsCount: number;
};
export type TMainChildComplaintDB = {
  createdAt: string;
  type: EComplaintTypeDB;
  title: EComplaintTitleDB;
  description: string;
  hasUnreadComment: boolean;
  commentsCount: number;
};
