import { formattedDate } from 'antd/helpers/utils';
import {
  TEmailUpdate,
  TEmailUpdateUI,
  TFaxUpdate,
  TFaxUpdateUI,
  TNameUpdate,
  TNameUpdateUI,
  TPhoneUpdate,
  TPhoneUpdateUI,
  TSADocPacketCreate,
  TSADocPacketCreateUI,
  TSADocPacketGet,
  TSADocPacketGetUI,
  TSALetterUpdate,
  TSALetterUpdateUI,
  TTrainedForUpdate,
  TTrainedForUpdateUI,
  TTrainerInfo,
  TTrainerInfoUI,
  TTrainingEndDateUpdate,
  TTrainingEndDateUpdateUI,
  TTrainingStartDateUpdate,
  TTrainingStartDateUpdateUI,
} from '../types/animalTraining.types';
import { TAuthConverter, TCreateConverter, TFetchConverter } from '../types/converter.types';
import { phoneNumberConverter, verificationStatusConverter } from './general.converters';
import { docPacketStatusConverter } from './docPacket.convertors';

export const trainerInfoConvertor: TAuthConverter<TTrainerInfoUI, TTrainerInfo, TTrainerInfoUI, TTrainerInfo> = {
  toDb: (data) => {
    return {
      name: data.name,
      emailAddress: data.emailAddress?.trim() || undefined,
      phoneNumber: data.phoneNumber ? `${data.phoneNumber[0] === '+' ? '' : '+'}${data.phoneNumber}` : undefined,
      faxNumber: data.faxNumber?.trim() || undefined,
    };
  },
  fromDb: (data) => {
    return {
      name: data.name,
      emailAddress: data.emailAddress,
      phoneNumber: data.phoneNumber,
      faxNumber: data.faxNumber,
    };
  },
};

export const saLetterUpdateConvertor: TCreateConverter<TSALetterUpdateUI, TSALetterUpdate> = {
  toDb: (data) => ({
    animalId: data.animalId,
    saDocumentId: data.id,
    newFiles: data.newFiles || [],
    removedLettersId: data.removedLettersId || [],
    tenantResidencyId: data.tenantResidencyId,
  }),
};

export const trainerNameUpdateConvertor: TCreateConverter<TNameUpdateUI, TNameUpdate> = {
  toDb: (data) => ({
    animalId: data.animalId,
    saDocumentId: data.saDocumentId,
    name: data.value || null,
    tenantResidencyId: data.tenantResidencyId,
  }),
};

export const trainerEmailUpdateConvertor: TCreateConverter<TEmailUpdateUI, TEmailUpdate> = {
  toDb: (data) => ({
    animalId: data.animalId,
    saDocumentId: data.saDocumentId,
    email: data.value || null,
    tenantResidencyId: data.tenantResidencyId,
  }),
};

export const trainerPhoneUpdateConvertor: TCreateConverter<TPhoneUpdateUI, TPhoneUpdate> = {
  toDb: (data) => ({
    animalId: data.animalId,
    saDocumentId: data.saDocumentId,
    phone: phoneNumberConverter.toDb(data.value),
    tenantResidencyId: data.tenantResidencyId,
  }),
};

export const trainerFaxUpdateConvertor: TCreateConverter<TFaxUpdateUI, TFaxUpdate> = {
  toDb: (data) => ({
    animalId: data.animalId,
    saDocumentId: data.saDocumentId,
    fax: data.value || null,
    tenantResidencyId: data.tenantResidencyId,
  }),
};

export const trainedForUpdateConvertor: TCreateConverter<TTrainedForUpdateUI, TTrainedForUpdate> = {
  toDb: (data) => ({
    animalId: data.animalId,
    saDocumentId: data.saDocumentId,
    trainedFor: data.value || null,
    tenantResidencyId: data.tenantResidencyId,
  }),
};

export const trainingStartDateUpdateConvertor: TCreateConverter<TTrainingStartDateUpdateUI, TTrainingStartDateUpdate> =
  {
    toDb: (data) => ({
      animalId: data.animalId,
      saDocumentId: data.saDocumentId,
      trainingStartDate: data.value ? formattedDate(data.value, 'YYYY-MM-DD') : null,
      tenantResidencyId: data.tenantResidencyId,
    }),
  };

export const trainingEndDateUpdateConvertor: TCreateConverter<TTrainingEndDateUpdateUI, TTrainingEndDateUpdate> = {
  toDb: (data) => ({
    animalId: data.animalId,
    saDocumentId: data.saDocumentId,
    trainingEndDate: data.value ? formattedDate(data.value, 'YYYY-MM-DD') : null,
    tenantResidencyId: data.tenantResidencyId,
  }),
};

export const getSADocPacketConvertor: TFetchConverter<TSADocPacketGetUI, TSADocPacketGet> = {
  fromDb: (data) => {
    debugger;

    return {
      id: data.id,
      trainerInfo: trainerInfoConvertor.fromDb(data.trainerInfo),
      saLetters: data.saLetters,
      trainedFor: data.trainedFor,
      startTime: data.trainingStartDate,
      endTime: data.trainingEndDate,
      canEdit: data.modifiable,
      ...(data.status && {
        status: docPacketStatusConverter.fromDb(data.status),
      }),
    };
  },
};

export const createSADocPacketsConvertor: TCreateConverter<TSADocPacketCreateUI, TSADocPacketCreate> = {
  toDb: (data) => {
    const trainerInfo = {
      name: data.name,
      emailAddress: data.emailAddress,
      faxNumber: data.faxNumber,
      phoneNumber: data.phoneNumber,
    };

    return {
      animalId: data.animalId,
      trainerInfo: trainerInfoConvertor.toDb(trainerInfo),
      saLetters: data.saLetters,
      trainingStartDate: data.startTime ? formattedDate(data.startTime, 'YYYY-MM-DD') : undefined,
      trainingEndDate: data.endTime ? formattedDate(data.endTime, 'YYYY-MM-DD') : undefined,
      tenantResidencyId: data.tenantResidencyId,
    };
  },
};
