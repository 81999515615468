import styled, { ThemedStyledProps } from 'styled-components';
import ReactPhoneInput from 'react-phone-input-2';
import { FontFamilies } from 'core/CssVariables';
import { TThemeProps } from 'antd/theme/theme';
import { TPhoneInputProps } from './PhoneInput.types';

export const SPhoneInput = styled(ReactPhoneInput)<ThemedStyledProps<TPhoneInputProps, TThemeProps>>`
  &.react-tel-input {
    font-family: ${FontFamilies.FontPrimary};

    & .form-control {
      height: unset;
      width: 100%;
      padding: 6px 12px 7px 48px;
      :disabled,
      :disabled + .flag-dropdown {
        cursor: default;
        border-color: ${({ theme }) => theme.input.borderColorDisabled};
      }
    }

    & .form-control,
    & .flag-dropdown {
      border-color: ${({ invalid, theme }) => (invalid ? theme.input.errorBorderColor : theme.input.borderColor)};
    }
  }
`;
