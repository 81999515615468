import { TConvertor, TFetchConverter } from '../types/converter.types';
import { TOptionTypeUi } from '../types/general.types';
import {
  TDocHtmlUI,
  TGeneratedPolicyDataDB,
  TGeneratedPolicyDataUI,
  TPolicyOptionType,
  TPolicyOptionTypeUI,
} from '../types/policy.types';
import { b64toBlob, b64toFile } from '../../api/sevices/fileServices';
import { fileConverter } from './general.converters';

export const policyOptionConverter: TConvertor<TOptionTypeUi, TPolicyOptionType> = {
  fromDb: (data) => {
    return {
      value: data.resourceId || '',
      label: data.name || '',
      state: data.state,
      internalId: data.id,
    };
  },
  toDb: (data) => {
    return {
      name: data.value,
    };
  },
};

export const getPolicyListConvertor: TFetchConverter<TPolicyOptionTypeUI[], TPolicyOptionType[]> = {
  fromDb: (data) => {
    return data?.map((item) => policyOptionConverter.fromDb(item)) || [];
  },
};

export const docHtmlConvertor: TFetchConverter<TDocHtmlUI, string> = {
  fromDb: (data) => {
    const content = {
      html: /<body.*?>([\s\S]*)<\/body>/.exec(data)?.[1] || '',
      css: /<style.*?>([\s\S]*)<\/style>/.exec(data)?.[1] || '',
    };

    // To convert wierd character to bullet points
    content.html = content.html.replaceAll('​&nbsp;', '&#9679;');
    return content;
  },
};

export const generatedPolicyDataConverter: TFetchConverter<TGeneratedPolicyDataUI, TGeneratedPolicyDataDB> = {
  fromDb: (data) => {
    const qrFile = b64toBlob(data.qr, 'image/png');
    return {
      policyName: data.policyName,
      leasePacketCode: data.leasePacketCode,
      link: data.link,
      qr: URL.createObjectURL(qrFile),
      template: fileConverter.fromDb(data.signUpDoc),
      policyId: data.policyId,
    };
  },
};
