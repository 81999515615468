import { EStateUrls } from 'antd/data/urls/states.url';
import { supportedStatesConverter } from 'antd/data/converters/states.converters';
import { TSelectOptionTypeUi } from 'antd/components/Select';
import { authSplitApi } from '../helpers/slice.helpers';

export const statesApi = authSplitApi('states', []).injectEndpoints({
  endpoints: (build) => ({
    getSupportedStates: build.query<TSelectOptionTypeUi[], void>({
      query() {
        return {
          url: `${import.meta.env.REACT_APP_HOSTNAME}/api/v1/${EStateUrls.STATEPOLICY}/${EStateUrls.SUPPORTEDSTATES}`,
          method: 'GET',
        };
      },
      transformResponse: (data: string[]) => {
        return data.map((item) => supportedStatesConverter.fromDb(item));
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetSupportedStatesQuery } = statesApi;
