import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

export const bugsnagClient =
  import.meta.env.REACT_APP_ENV_NAME === 'OurPetPolicy-prod'
    ? Bugsnag.start({
        apiKey: import.meta.env.REACT_APP_BUGSNAG_KEY,
        plugins: [new BugsnagPluginReact()],
        releaseStage: 'production',
        appVersion: '0.1.0',
      })
    : null;
