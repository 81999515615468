import { authSplitApi } from 'antd/redux/helpers/slice.helpers';
import { EProfileUrl } from 'antd/data/urls/profile.url';
import { TProfileSearchDB, TProfileSearchUI } from 'antd/data/types/profile.types';
import { profileSearchGetConverter } from 'antd/data/converters/profile.converters';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';

const URL = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EProfileUrl.PROFILE}`;
export const profileApi = authSplitApi('profileApi', ['profilesSearch']).injectEndpoints({
  endpoints: (build) => ({
    getProfilesSearch: build.query<TProfileSearchUI[], void>({
      query() {
        return {
          url: `${URL}/${EProfileUrl.SEARCH}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TProfileSearchDB[]) => data.map((item) => profileSearchGetConverter.fromDb(item)),
      providesTags: ['profilesSearch'], // for caching and update cache
    }),
  }),
  overrideExisting: false,
});

export const { useGetProfilesSearchQuery } = profileApi;
