import {Row} from 'antd/components/Grid';
import React, {useEffect, useRef} from 'react';
import {Link as TypographyLink, Paragraph, Text} from "antd/components/Typography";
import Form, {FormItem, validationRules} from 'antd/components/Form';
import {TextField} from "antd/components/TextField";
import useForm from "antd/hooks/useForm";
import {Link, useHistory} from "react-router-dom";
import Countdown from "antd/components/Countdown";
import {Colors} from 'core/CssVariables';
import Button from "antd/components/Button";
import {RoutesNames} from "routes/RoutesNames";
import AuthFormsWrapper from "antd/componentsShared/AuthFormsWrapper";
import get from "lodash/get";
import Alert from "antd/components/Alert";
import {useStateSafely} from "antd/hooks/useStateSafely";
import {useConfirmSignUpMutation, useResetPasswordCasesMutation} from "antd/redux/apiSlices/auth.slice";

const Confirmation = ({email, onResetStates}: { email: string, onResetStates?: () => void }) => {
    const history = useHistory();
    const [error, setError] = useStateSafely('');
    const {form} = useForm<{ code: string }>();
    const [showResend, setShowResend] = useStateSafely(true);
    const [sendConfirmationCode] = useResetPasswordCasesMutation()
    const [confirmSignUp, {isLoading: signupConfirmLoading}] = useConfirmSignUpMutation();
    const alertRef = useRef<HTMLDivElement | null>(null);
    const onSubmit = async ({code}: { code: string }) => {
        try {
            await confirmSignUp({email, code}).unwrap();
            history.push(RoutesNames.SignIn);
            setError('');
        } catch (e) {
            const message = get(e, ['data', 'message']) || 'Something went wrong!';
            setError(message);
        }
    }
    const onResend = async () => {
        await sendConfirmationCode({email}).unwrap();
        setShowResend(false);
    }
    useEffect(() => {
        if (error) {
            alertRef.current?.scrollIntoView({block: 'end'})
        }
    }, [error])
    return (
        <Row justify='center'>
            <AuthFormsWrapper title='Enter the code we’ve just sent.'>
                <Paragraph className='mb-6' fontLevel={4}>Enter the verification code sent to you to login.</Paragraph>
                <Form onFinish={onSubmit} className='w-100' form={form}>
                    <div ref={alertRef}>
                        <Alert type='error' message={error}/>
                    </div>
                    <FormItem label='Code' rules={[validationRules.required()]} name='code'>
                        <TextField placeholder='Enter confirmation code'/>
                    </FormItem>
                    <div>
                        {showResend ?
                            <TypographyLink color={Colors.DarkBlue} underline className='pointer' onClick={onResend}>
                                Resend
                            </TypographyLink> :
                            <Text>
                                <Row>
                                    Resend in
                                    <Countdown className='mx-1' format='ss' onFinish={() => setShowResend(true)}
                                               sec={60}/>
                                    seconds
                                </Row>
                            </Text>
                        }
                    </div>
                    <div className='mb-3 mt-3'>
                        <Button htmlType='submit' loading={signupConfirmLoading}> Confirm </Button>
                    </div>
                    <Paragraph fontLevel={5}>Or go back to {' '}
                        <Text fontLevel={5} color={Colors.DarkBlue} underline>
                            <Link to={{pathname: RoutesNames.SignUp, state: null}}
                                  onClick={onResetStates && onResetStates}> Sign
                                up</Link>
                        </Text>
                    </Paragraph>
                </Form>
            </AuthFormsWrapper>
        </Row>
    );
};

export default Confirmation;