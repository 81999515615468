import { authSplitApi } from 'antd/redux/helpers/slice.helpers';
import { TOptionTypeUi } from 'antd/data/types/general.types';
import { EAdminUrl } from 'antd/data/urls/admin.url';

const baseUrl = `${EAdminUrl.ADMIN}/${EAdminUrl.ANALYTICS}`;

export const analyticsApi = authSplitApi('analytics', ['units']).injectEndpoints({
  endpoints: (build) => ({
    getUnitsAnalytics: build.query<
      number,
      { propertyManagementIds: TOptionTypeUi[]; propertyManagementStatuses: TOptionTypeUi[] }
    >({
      query({ propertyManagementIds, propertyManagementStatuses }) {
        return {
          url: `${baseUrl}/${EAdminUrl.UNITS}`,
          method: 'GET',
          params: {
            ...(propertyManagementIds.length && {
              propertyManagementIds: propertyManagementIds.map((item) => item.value),
            }),
            ...(propertyManagementStatuses.length && {
              propertyManagementStatuses: propertyManagementStatuses.map((item) => item.value),
            }),
          },
        };
      },
      providesTags: ['units'], // for caching and update cache
    }),
  }),
  overrideExisting: false,
});

export const { useGetUnitsAnalyticsQuery } = analyticsApi;
