import { TFileDataType, TFileDB, TFileUI } from './general.types';
import { EVerificationStatus, EVerificationStatusUi } from './generalTypes';

export enum EHPVisitationUI {
  REMOTE = 'REMOTE',
  IN_PERSON = 'IN_PERSON',
}
export enum EHPVisitation {
  REMOTE = 'REMOTE',
  IN_PERSON = 'IN_PERSON',
}

export enum EHPDocPacketTypesUI {
  SA = 'SA',
  ESA = 'ESA',
}
export enum EHPDocPacketTypes {
  SA = 'SA',
  ESA = 'ESA',
}

export type THPDocFieldUpdateUI = {
  documentPacketId: string;
  value: string;
  tenantResidencyId: string;
};
export type THPVisitationUI = {
  websiteAdvertised: string;
  websites?: string;
};
export type THPVisitation = { websiteAdvertised: boolean; websites?: string[] };

export type THPVisitationGetUI = THPVisitationUI;
export type THPVisitationGet = {
  websiteAdvertised: boolean;
  websites?: { value: string }[];
};

export type THPContactInfoUI = {
  name: string;
  emailAddress?: string;
  phoneNumber?: string;
  faxNumber?: string;
};
export type THPContactInfo = THPContactInfoUI;

export type TNameUpdateUI = THPDocFieldUpdateUI;
export type TNameUpdate = {
  documentPacketId: string;
  hpContactName: string | null;
  tenantResidencyId: string;
};

export type TEmailUpdateUI = THPDocFieldUpdateUI;
export type TEmailUpdate = {
  documentPacketId: string;
  hpContactEmail: string | null;
  tenantResidencyId: string;
};

export type TPhoneUpdateUI = THPDocFieldUpdateUI;
export type TPhoneUpdate = {
  documentPacketId: string;
  hpContactPhone: string | null;
  tenantResidencyId: string;
};

export type TFaxUpdateUI = THPDocFieldUpdateUI;
export type TFaxUpdate = {
  documentPacketId: string;
  hpContactFaxNumber: string | null;
  tenantResidencyId: string;
};

export type THPVisitationUpdateUI = {
  documentPacketId: string;
  websiteAdvertised: THPVisitationUI;
};
export type THPVisitationUpdate = {
  documentPacketId: string;
  websiteAdvertised: THPVisitation;
};

export type THPLetterUpdateUI = {
  hpDocumentPacketId: string;
  newHpLetters: TFileDB[];
  removedDocumentsIds?: string[];
  tenantResidencyId: string;
};
export type THPLetterUpdate = {
  documentPacketId: string;
  newHpLetters: TFileDB[];
  removedHpLetterIds?: string[];
  tenantResidencyId: string;
};

export type THPFormUpdateUI = {
  tenantId: string;
  hpDocumentPacketId: string;
  newHpForms: TFileDB[];
  removedHpFormIds?: string[];
};
export type THPFormUpdate = {
  tenantId: string;
  documentPacketId: string;
  newHpForms: TFileDB[];
  removedHpFormIds?: string[];
};

export type TDocPacketUI = {
  hpDocType?: EHPDocPacketTypesUI;
  name: string;
  emailAddress?: string;
  phoneNumber?: string;
  faxNumber?: string;
  websites?: string;
  websiteAdvertised: string;
  receivedAfterCompletingQuestionnaireOrInterview: string;
  hpDocPacketLetter?: TFileUI[];
  tenantResidencyId?: string;
};
export type TDocPacket = {
  hpDocType?: EHPDocPacketTypes;
  hpContactInfo: THPContactInfo;
  hpVisitation?: THPVisitation;
  hpDocPacketLetter?: TFileUI[];
  tenantResidencyId?: string;
};

export enum EDocPacketStatus {
  NONE = 'NONE',
  VERIFIED = 'VERIFIED',
}
export enum EDocPacketStatusUi {
  NONE = 'Not verified',
  VERIFIED = 'Verified',
}

export type TDocPacketGetUI = {
  type: EHPDocPacketTypesUI;
  hpContactInfo: THPContactInfoUI;
  hpDocForms?: TFileDataType[];
  hpVisitation: boolean;
  websites?: string[];
  hpDocLetters?: TFileDataType[];
  canEdit: boolean;
  status: EVerificationStatusUi;
  hpDocumentPacketId: string;
  receivedAfterCompletingQuestionnaireOrInterview: boolean | null;
  isDeleted: boolean;
};
export type TDocPacketGet = {
  type: EHPDocPacketTypes;
  hpContactInfo: THPContactInfo;
  hpDocForms?: TFileDataType[];
  hpVisitation: THPVisitationGet;
  hpDocLetters?: TFileDataType[];
  modifiable: boolean;
  verificationStatus: EVerificationStatus;
  hpDocumentPacketId: string;
  receivedAfterCompletingQuestionnaireOrInterview: boolean | null;
  isDeleted: boolean;
};

export enum ETemplateTypeUI {
  MENTAL_HEALTH_PROFESSIONAL = 'MENTAL_HEALTH_PROFESSIONAL',
  SERVICES_TRAINED = 'SERVICES_TRAINED',
}
export enum ETemplateType {
  MENTAL_HEALTH_PROFESSIONAL = 'MENTAL_HEALTH_PROFESSIONAL',
  SERVICES_TRAINED = 'SERVICES_TRAINED',
}

export type TDocPacketInfoDB = {
  hpDocumentPacketId: string;
  receivedAfterCompletingQuestionnaireOrInterview: boolean;
  hpVisitation: { websiteAdvertised: boolean; websites?: string[] };
  tenantResidencyId?: string;
};
export type TDocPacketInfoUI = {
  hpDocumentPacketId: string;
  receivedAfterCompletingQuestionnaireOrInterview: string;
  websiteAdvertised: string;
  websites: string;
  tenantResidencyId?: string;
};
