import { ELeasePacketUrls } from 'antd/data/urls/leasePacket.url';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { TLeasePacketAddendumUI } from 'antd/data/types/leasePacket.types';
import { authSplitApi } from 'antd/redux/helpers/slice.helpers';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { filePreviewData } from '../../api/sevices/fileServices';

const URL = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${ELeasePacketUrls.LEASE_PACKET}`;
export const leasePacketApi = authSplitApi('leasePacket', ['leasePacketAddendum']).injectEndpoints({
  endpoints: (build) => ({
    getLeasePacketAddendum: build.query<TLeasePacketAddendumUI, string>({
      query(policyId) {
        return {
          url: `${URL}/${ELeasePacketUrls.ADDENDUM}`,
          method: 'POST',
          body: { policyId },
        };
      },
      providesTags: ['leasePacketAddendum'],
    }),
  }),
});

export const { useGetLeasePacketAddendumQuery } = leasePacketApi;
