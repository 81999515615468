import { TFetchConverter } from 'antd/data/types/converter.types';
import { TProfileSearchDB, TProfileSearchUI } from '../types/profile.types';

export const profileSearchGetConverter: TFetchConverter<TProfileSearchUI, TProfileSearchDB> = {
  fromDb: (data) => {
    return {
      name: data.name,
      value: data.id,
    };
  },
};
