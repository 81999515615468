import { TConvertor, TCreateConverter, TFetchConverter } from '../types/converter.types';
import { TOptionTypeUi } from '../types/general.types';
import {
  ELandlordPaymentStatusDB,
  ELandlordPaymentStatusUI,
  ELandlordStatusDB,
  ELandlordStatusUI,
  TChangeLandlordStatusDB,
  TChangeLandlordStatusUI,
  TLandlordGetDB,
  TLandlordGetUI,
  TPetChargeReportDB,
  TPetChargeReportUI,
  TPMEmailsExportDB,
  TPMEmailsExportUI,
  TUpdateBusinessName,
  TUpdateBusinessNameUI,
} from '../types/landlord.types';
import { queryParamConverter } from './general.converters';

export const updateBusinessNameConverter: TCreateConverter<TUpdateBusinessNameUI, TUpdateBusinessName> = {
  toDb: ({ name, propertyManagementId }) => {
    return {
      name,
      propertyManagementId,
    };
  },
};

export const generatePetChargeReportConverter: TCreateConverter<TPetChargeReportUI, TPetChargeReportDB> = {
  toDb: (data) => {
    return {
      landlordId: data.landlordId,
      ...(data.propertyIds && {
        propertyIds: data.propertyIds.map((item) => item.value),
      }),
    };
  },
};

export const propertyManagementEmailsExportConverter: TCreateConverter<TPMEmailsExportUI, TPMEmailsExportDB> = {
  toDb: (data) => {
    if (data.search) {
      return queryParamConverter(data.search, undefined);
    }
    return {
      propertyManagementIds: data.propertyManagementIds || [],
    };
  },
};

export const landlordStatusConverter: TConvertor<ELandlordStatusUI, ELandlordStatusDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in ELandlordStatusDB]: ELandlordStatusUI } = {
      [ELandlordStatusDB.ACTIVE]: ELandlordStatusUI.ACTIVE,
      [ELandlordStatusDB.TEST]: ELandlordStatusUI.TEST,
      [ELandlordStatusDB.PROSPECT]: ELandlordStatusUI.PROSPECT,
      [ELandlordStatusDB.NON_LANDLORD]: ELandlordStatusUI.NON_LANDLORD,
      [ELandlordStatusDB.INACTIVE]: ELandlordStatusUI.INACTIVE,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in ELandlordStatusUI]: ELandlordStatusDB } = {
      [ELandlordStatusUI.ACTIVE]: ELandlordStatusDB.ACTIVE,
      [ELandlordStatusUI.TEST]: ELandlordStatusDB.TEST,
      [ELandlordStatusUI.INACTIVE]: ELandlordStatusDB.INACTIVE,
      [ELandlordStatusUI.NON_LANDLORD]: ELandlordStatusDB.NON_LANDLORD,
      [ELandlordStatusUI.PROSPECT]: ELandlordStatusDB.PROSPECT,
    };

    return dataMapper[data];
  },
};

export const landlordPaymentStatusConverter: TFetchConverter<ELandlordPaymentStatusUI, ELandlordPaymentStatusDB> = {
  fromDb: (data) => {
    const dataMapper: {
      [key in ELandlordPaymentStatusDB]: ELandlordPaymentStatusUI;
    } = {
      [ELandlordPaymentStatusDB.PAYMENT_FAILED]: ELandlordPaymentStatusUI.PAYMENT_FAILED,
      [ELandlordPaymentStatusDB.PAID]: ELandlordPaymentStatusUI.PAID,
    };
    return dataMapper[data];
  },
};

export const landlordConverter: TFetchConverter<TLandlordGetUI, TLandlordGetDB> = {
  fromDb: (data) => {
    return {
      id: data.id,
      name: data.businessName,
      username: data.landlordUsername,
      status: landlordStatusConverter.fromDb(data.status),
      propertyManagementId: data.propertyManagementId,
      resourceId: data.landlordId,
    };
  },
};

export const changeLandlordStatusConverter: TCreateConverter<TChangeLandlordStatusUI, TChangeLandlordStatusDB> = {
  toDb: (data) => {
    return {
      propertyManagementId: data.propertyManagementId,
      status: landlordStatusConverter.toDb(data.propertyManagementStatus),
    };
  },
};

export const analyticsLandlordsQueryConverter: TCreateConverter<TOptionTypeUi[], { statuses: ELandlordStatusDB[] }> = {
  toDb: (data) => {
    return {
      statuses: data.map((i) => i.value),
    };
  },
};
