import React, { useEffect } from 'react';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Collapse from '@material-ui/core/Collapse';

import { useAppDispatch } from 'contexts/PropertyEditContext';
import { WarningFilledIcon, WarningIcon, StopIcon, CheckIcon, RemoveIconCircle } from '../Icons';

import { useStyles } from './alert.style';

export default function SimpleAlerts({ severity, type, className, fadeout, alertText, fullWidth, onClose, children }) {
  const classes = useStyles({ fullWidth });
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!alertText) {
      return;
    }
    const hideAlert = setTimeout(() => {
      dispatch({
        type: 'CLOSE_ALERT',
      });
    }, 5000);
    return () => clearTimeout(hideAlert);
  }, [alertText]);

  function renderAlertIcon(severity) {
    switch (type || severity) {
      case 'success-check':
        return <CheckIcon width='16' height='16' />;
      case 'warning-stop':
        return <StopIcon width='16' height='16' color='#F6911A' />;
      case 'warning':
        return <WarningFilledIcon width='16' height='16' color='#F6911A' />;
      case 'error-cross':
        return <RemoveIconCircle width='16' height='16' color='#FF8282' />;
      case 'error':
        return <WarningIcon width='16' height='16' color='#FF8282' />;
    }
  }

  return (
    <div className={fadeout ? classes.fadeoutAlert : classes.fixedAlert}>
      {fadeout ? (
        <Collapse in={!!alertText} timeout='auto'>
          <Alert
            severity={severity}
            icon={renderAlertIcon(severity)}
            action={
              <IconButton aria-label='close' size='small' data-cy='close-toast' onClick={onClose}>
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
          >
            {alertText}
          </Alert>
        </Collapse>
      ) : (
        <Alert severity={severity} icon={renderAlertIcon(severity)} className={className}>
          {children}
        </Alert>
      )}
    </div>
  );
}
