import { TFetchConverter } from 'antd/data/types/converter.types';
import { TPageableDataDB, TPageableDataUi } from 'antd/data/types/pagableData.type';

export const pageableDataConverter: TFetchConverter<TPageableDataUi, TPageableDataDB> = {
  fromDb: (data) => {
    return {
      totalElements: data.totalElements,
      totalPages: data.totalPages,
      number: data.number,
      size: data.size,
    };
  },
};
