import { validationRules} from "antd/components/Form";
import {FormattedMessage} from "react-intl";
import { TLoginFields } from "./Login.types";


export const loginFields:TLoginFields = {
    email: {
        name: 'email',
        label: 'login.form.emailLabel',
        rules: [
            { required: true, message: <FormattedMessage id="login.form.emailRequired" />},
            { type: 'email',  message:  <FormattedMessage  id="login.form.incorrectEmail" /> },
        ],
        placeholder: 'login.form.emailPlaceholder',
    },
    password: {
        name: 'password',
        label: 'login.form.passwordLabel',
        rules: [
            ...validationRules.password,
            validationRules.required('password'),
            validationRules.withoutSpace('Password'),
        ],
        placeholder: 'login.form.passwordPlaceholder',
    },
};