export type TSignupDataUI = {
  password: string;
  email: string;
  phone?: string;
  fullName: string;
  businessName: string;
};

export type TSignupDataDB = {
  password: string;
  email: string;
  phone?: string;
  name: string;
  businessName: string;
};

// SIGN_IN Amplify
export type TSignInAmplifyDB = { username: string; password: string };
export type TSignInAmplifyUI = { email: string; password: string };

export type TResetPasswordCasesDB = { username: string };
export type TResetPasswordCasesUI = { email: string };

export type TResetPasswordDB = {
  passwordResendStatus: EResetPasswordCasesDB;
  admin: boolean;
};

export type TResetPasswordUI = {
  passwordResendStatus: EResetPasswordCasesUI;
  isAdmin: boolean;
};

export enum EResetPasswordCasesDB {
  RESENT_CODE = 'RESENT_CODE',
  RESENT_PASSWORD = 'RESENT_PASSWORD',
  RESEND_INVITATION = 'RESEND_INVITATION',
}

export enum EResetPasswordCasesUI {
  RESENT_CODE = 'RESEND_CODE',
  RESENT_PASSWORD = 'RESET_PASSWORD',
  RESEND_INVITATION = 'RESEND_INVITATION',
}

export type TResetPasswordFormUI = {
  code: string;
  password: string;
  email: string;
};

export type TResetPasswordFormDB = {
  code: string;
  password: string;
  username: string;
};

export type TConfirmSignUpUI = {
  email: string;
  code: string;
};

export type TConfirmSignUpDB = {
  username: string;
  code: string;
};

export type TSetPasswordFormUI = {
  password: string;
  email: string;
  temporaryPassword: string;
};

export type TSetPasswordFormDB = {
  newPassword: string;
  username: string;
  temporaryPassword: string;
};
