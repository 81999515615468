import { Moment } from 'moment';
import {
  TMultiOptionTypeMaterialUI,
  TOptionTypeUi,
  TPolicyUI,
  TStatusChangeInfoDB,
  TStatusChangeInfoUI,
  TStatusInfoWithSourceUI,
} from './general.types';
import { EAnimalTypes, EAnimalTypesUI, TAnimalWeightDB, TStatusInfoWithSourceDB } from './animals.types';
import { EVerificationStatus, EVerificationStatusUi } from './generalTypes';
import { TPropertyUI, TUnitUI } from './property.types';
import { TSpeciesProps } from './application.types';

export type TResidenceUnitDataDB = {
  id: string;
  number: string;
};

export type TResidencePropertyDataDb = {
  name: string;
  propertyId: string;
};

export type TResidenceUnitDataUI = {
  id: string;
  number: string;
};

export type TResidencePropertyDataUI = {
  id: string;
  name: string;
};

export type TResidenceDB = {
  residenceId: string;
  residentName: string;
  unit: TResidenceUnitDataDB;
  property: TResidencePropertyDataDb;
  status: EResidenceStatusDB;
};

export type TResidenceUI = {
  residenceId: string;
  residentName: string;
  unit: TResidenceUnitDataUI;
  property: TResidencePropertyDataUI;
  status: EResidenceStatusUi;
};

export type TResidenciesOptionsDB = {
  name: string;
  value: string;
  status: EResidenceStatusesDB;
};

export type TResidenciesOptionsUI = {
  label: string;
  value: string;
  status: EResidenceStatusesUI;
  moveInDate?: string;
};

export type TResidenceFilterDB = {
  propertyName: string | null;
  residentName: string;
  unitNumber: string | null;
  residenceIds: string[];
  residencyApplicationIds: string[];
  residencyIdToDetails: { [id: string]: { status: EResidenceStatusesDB } };
  moveInDate?: string;
};

export type TResidenceFilterUI = {
  residenceProfile: string;
  residenceIds: string[];
  residencyApplicationIds: string[];
  residenciesOptions: TResidenciesOptionsUI;
  moveInDate?: string;
};

export type TResidenceMoveInfoUI = {
  isPolicyChanging: boolean;
};

export type TResidenceMoveInfo = {
  isPolicyChanging: boolean;
};

export type TResidentMoveFormDataProps = {
  property: TOptionTypeUi;
  unit: TOptionTypeUi;
  startTime: Moment;
  endTime: Moment;
};

export type TResidentNoticeFormDataProps = {
  moveOutDate: Moment;
};

export type TResidentNoticeUi = TResidentNoticeFormDataProps & {
  residenceId: string;
};

export type TMoveOutToResidentUi = {
  residencyId: string;
  reason?: string;
  animalResidencyIds: string[];
};
export type TMoveOutToResidentDb = {
  residencyId: string;
  reason?: string;
  animalResidencyIds: string[];
};

export type TMoveOutToNoticeUi = TMoveOutToResidentUi & { moveOutDate: Moment };
export type TMoveOutToNoticeDb = TMoveOutToResidentDb & { moveOutDate: string };

export type TResidentNotice = {
  tenantResidencyId: string;
  moveOutDate: string;
};

export type TResidentMoveUi = TResidentMoveFormDataProps & {
  residenceId: string;
};

export type TResidentMove = {
  residenceId: string;
  propertyId: string;
  unitNumber: string;
  startTime?: string;
  endTime?: string;
};

export enum EResidenceStatusDB {
  MOVED_IN = 'MOVED_IN',
  MOVED_OUT = 'MOVED_OUT',
}
export enum EResidenceStatusUi {
  MOVED_IN = 'Moved in',
  MOVED_OUT = 'Moved out',
}

export enum EResidenceStatusesDB {
  APPLICANT = 'APPLICANT',
  REJECTED = 'REJECTED',
  FUTURE_RESIDENT = 'FUTURE_RESIDENT',
  RETRACTED = 'RETRACTED',
  MOVED_OUT = 'MOVED_OUT',
  RESIDENT = 'RESIDENT',
  FORMER_RESIDENT = 'FORMER_RESIDENT',
  NOTICE = 'NOTICE',
  DENIED = 'DENIED',
}

export enum EResidenceStatusesUI {
  APPLICANT = 'Applicant',
  REJECTED = 'Not Approved',
  FUTURE_RESIDENT = 'Future Resident',
  RETRACTED = 'Retracted',
  MOVED_OUT = 'Moved Out',
  RESIDENT = 'Resident',
  FORMER_RESIDENT = 'Former Resident',
  NOTICE = 'Notice',
  DENIED = 'Not Approved',
}

export enum EResidencyFilterStatusDB {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  MOVED_IN = 'MOVED_IN',
}
export enum EResidencyFilterStatusUI {
  PENDING = 'Applicant',
  APPROVED = 'Approved',
  MOVED_IN = 'Moved In',
}

export type TResidentSearchUI = TMultiOptionTypeMaterialUI;
export type TResidentSearchDB = { residentId: string; name: string };

export enum EPooCompaniesUi {
  POO_PRINTS = 'Poo Prints',
  MR_DOG_POOP = 'Mr. Dog Poop',
  OTHER = 'other',
}

export enum EPooCompaniesDB {
  POO_PRINTS = 'POO_PRINTS',
  MR_DOG_POOP = 'MR_DOG_POOP',
  OTHER = 'OTHER',
}

export type TCompanyDB = {
  companyOption: EPooCompaniesDB | string | null;
  companyCustomName: string | null;
};

export type TCompanyUi = {
  label: EPooCompaniesUi | string | null;
  value: EPooCompaniesDB | string | null;
};

export type TCollectDNAUi = {
  residenceId: string;
  animalResidencyId: string;
  company?: TOptionTypeUi;
  dnaNumber?: string;
};

export type TCollectDNA = {
  residenceId: string;
  animalResidencyId: string;
  company?: TCompanyDB;
  dnaNumber?: string;
};

export type TUnCollectDNAUi = {
  residenceId: string;
  animalResidencyId: string;
};

export type TUnCollectDNA = {
  residenceId: string;
  animalResidencyId: string;
};

export enum EAnimalResidencyStatusUI {
  PENDING = 'Pending',
  NOT_APPROVED = 'Not Approved',
  ARCHIVED = 'Archived',
  APPROVED = 'Future Animal',
  MOVED_OUT = 'Moved Out',
  RETRACTED = 'Retracted',
  MOVED_IN = 'Moved In',
  CONDITIONALLY_MOVED_IN = 'Conditionally Moved In',
  CONDITIONALLY_APPROVED = 'Conditionally Approved',
}

export enum EAnimalResidencyStatusDB {
  PENDING = 'PENDING',
  NOT_APPROVED = 'NOT_APPROVED',
  ARCHIVED = 'ARCHIVED',
  APPROVED = 'APPROVED',
  MOVED_OUT = 'MOVED_OUT',
  RETRACTED = 'RETRACTED',
  MOVED_IN = 'MOVED_IN',
  CONDITIONALLY_MOVED_IN = 'CONDITIONALLY_MOVED_IN',
  CONDITIONALLY_APPROVED = 'CONDITIONALLY_APPROVED',
}

export enum EResidencyStatusUI {
  RESIDENT = 'Resident',
  APPLICANT = 'Applicant',
  FUTURE_RESIDENT = 'Future Resident',
  FORMER_RESIDENT = 'Former Resident',
  DENIED = 'Not Approved',
  RETRACTED = 'Retracted',
  NOTICE = 'Noticed',
}

export enum EResidencyStatusDB {
  RESIDENT = 'RESIDENT',
  APPLICANT = 'APPLICANT',
  FUTURE_RESIDENT = 'FUTURE_RESIDENT',
  FORMER_RESIDENT = 'FORMER_RESIDENT',
  DENIED = 'DENIED',
  RETRACTED = 'RETRACTED',
  NOTICE = 'NOTICE',
}
export enum EResidentHasAnimalDB {
  HAS_NOT_ANIMAL = 'HAS_NOT_ANIMAL',
  HAS_ANIMAL = 'HAS_ANIMAL',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
}

export enum EResidentHasAnimalUI {
  HAS_NOT_ANIMAL = 'HAS_NOT_ANIMAL',
  HAS_ANIMAL = 'HAS_ANIMAL',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
}

export type TResidentProfileUI = {
  name: string;
  profilePicture: string;
  email: string;
  phoneNumber: string;
};

export type TResidentProfileDB = {
  name: string;
  profilePicture: string | null;
  email: string | null;
  phoneNumber: string | null;
};

export type TResidentResidencyDB = {
  residenceId: string;
  propertyId: string;
  propertyName: string;
  unitName: string;
  moveInDate: string;
  leaseToDate: string;
  nextLeaseToDate: string | null;
};

export type TApplicantResidencyDB = {
  residencyApplicationId: string;
  propertyId: string;
  propertyName: string;
  unitName: string;
  moveInDate: string | null;
  leaseToDate: string | null;
  nextLeaseToDate: string | null;
};
export type TResidentResidencyUI = {
  id: string;
  propertyId: string;
  propertyName: string;
  unitName: string;
  moveInDate: string | null;
  leaseToDate: string | null;
  nextLeaseToDate: string | null;
};

export type TResidentAnimalDB = {
  type: EAnimalTypes;
  name: string;
  profilePicture: string | null;
  species: string | null;
  breed: string | null;
  weight: TAnimalWeightDB | null;
  verificationStatus: EVerificationStatus;
  residencyStatus: EAnimalResidencyStatusDB;
  id: string;
  existsNonApprovedAnimalApplication: string | null;
  statusChangeInfoDto: TStatusChangeInfoDB;
  isSigned: boolean;
  signedBy: string | null;
  dateSigned: string | null;
  policyName: string | null;
  policyContent: string | null;
  policyId: string;
};

export type TResidentAnimalUI = {
  type: EAnimalTypesUI;
  name: string;
  profilePicture: string;
  species: string;
  breed: string;
  weight: string;
  verificationStatus: EVerificationStatusUi;
  residencyStatus: EAnimalResidencyStatusUI;
  id: string;
  existsNonApprovedAnimalApplication: string | null;
  statusChangeInfo: TStatusChangeInfoUI;
  isSigned: boolean;
  signedBy: string;
  dateSigned: string;
  policyName: string | null;
  policyContent: Blob | null;
  policyId: string;
};

export enum EResidentTagsDB {
  KNOWN = 'KNOWN',
}
export enum EResidentTagsUI {
  KNOWN = 'Known',
}

export type TResidentDetailsDB = {
  isPropertyCustom: boolean;
  residencyStatus: EResidencyStatusDB;
  residentId: string;
  residenceId: string;
  propertyId: string;
  propertyName: string;
  unitName: string;
  moveInDate: string;
  leaseToDate: string;
  name: string;
  profilePicture: string | null;
  email: string | null;
  phoneNumber: string | null;
  policyId: string;
  policyName: string;
  policyState: string;
  petStatus: EResidentHasAnimalDB;
  roommatePetStatus: EResidentHasAnimalDB;
  animals: TResidentAnimalDB[];
  residencyStatusChangeInfo: TStatusInfoWithSourceUI | null;
  tags: EResidentTagsDB[];
  statusUpdateLocked: boolean;
  isScreeningEnabled: boolean;
  nextLeaseToDate: string | null;
};

export type TResidentDetailsUI = {
  propertyCustom: boolean;
  residencyStatus: EResidencyStatusUI;
  residentId: string;
  residentProfile: TResidentProfileUI;
  residentResidency: TResidentResidencyUI;
  policy: TPolicyUI;
  petStatus: EResidentHasAnimalUI;
  roommatePetStatus: EResidentHasAnimalUI;
  animals: TResidentAnimalUI[];
  residencyStatusChangeInfo: TStatusInfoWithSourceUI | null;
  tags: EResidentTagsUI[];
  statusUpdateLocked: boolean;
  isScreeningEnabled: boolean;
};

export type TApplicantDetailsDB = {
  residencyStatus: EResidencyStatusDB;
  residentId: string;
  residencyApplicationId: string;
  propertyId: string;
  propertyName: string;
  unitName: string;
  moveInDate: string;
  leaseToDate: string;
  nextLeaseToDate: string | null;
  name: string;
  profilePicture: string | null;
  email: string | null;
  phoneNumber: string | null;
  policyId: string;
  policyName: string;
  policyState: string;
  petStatus: EResidentHasAnimalDB;
  roommatePetStatus: EResidentHasAnimalDB;
  animals: TResidentAnimalDB[];
  residencyStatusChangeInfo: TStatusInfoWithSourceDB | null;
  isScreeningEnabled: boolean;
};

export type TApplicantDetailsUI = {
  residencyStatus: EResidencyStatusUI;
  residentId: string;
  residentProfile: TResidentProfileUI;
  residentResidency: TResidentResidencyUI;
  policy: TPolicyUI;
  petStatus: EResidentHasAnimalUI;
  roommatePetStatus: EResidentHasAnimalUI;
  animals: TResidentAnimalUI[];
  residencyStatusChangeInfo: TStatusInfoWithSourceUI | null;
  isScreeningEnabled: boolean;
};

export type TResidencyApplicationApplyDB = {
  tenantResidencyId: string;
  propertyName: string;
  unitNumber?: string | null;
  moveInDate: string;
  leaseToDate: string | null;
};

export type TResidencyApplicationApplyUI = {
  id: string;
  propertyName: TOptionTypeUi;
  unitNumber?: TOptionTypeUi;
  moveInDate: Moment;
  leaseToDate?: Moment;
};

export type TResidencyApplicationRejectDB = {
  residencyId: string;
  reason: string;
};

export type TResidencyApplicationRejectUI = {
  id: string;
  reason: string;
};

export type TResidentDuplicateDB = {
  id: string;
  name: string;
  username: string;
};
export type TResidentDuplicateUI = {
  id: string;
  name: string;
  username: string;
};
export type TCheckTenantDuplicationParamUI = {
  unitNumber?: string;
  propertyId?: string;
  residentName?: string;
};

export type TResidentAnimalsDB = {
  applicationId: string;
  name: string;
  profilePicture: string | null;
  status: EAnimalResidencyStatusDB;
  type: EAnimalTypes;
  verificationStatus: EVerificationStatus;
  specie: TSpeciesProps | null;
  breed: string | null;
};

export type TResidentAnimalsUI = {
  id: string;
  name: string;
  profilePicture: string | null;
  status: EAnimalResidencyStatusUI;
  type: EAnimalTypesUI;
  verificationStatus: EVerificationStatusUi;
  specie?: string;
  breed: string | null;
};

export type EResidencyMoveInDB = {
  tenantResidencyId: string;
  propertyId: string;
  unitName: string;
  leaseToDate: string;
  moveInDate: string;
  applicationIds: string[];
};

export type EResidencyMoveInUI = {
  id: string;
  property: { value: TPropertyUI | string; label: string };
  unit: { value: TUnitUI | string; label: string };
  leaseToDate: Moment;
  moveInDate: Moment;
  applicationIds: string[];
};

export type TRetractAnimalDB = {
  id: string;
  name: string;
  profilePicture: string | null;
  status: EAnimalResidencyStatusDB;
  type: EAnimalTypes;
  verificationStatus: EVerificationStatus;
  specie: TSpeciesProps | null;
  breed: string | null;
};
export type TRetractAnimalUI = {
  id: string;
  name: string;
  profilePicture: string | null;
  status: EAnimalResidencyStatusUI;
  type: EAnimalTypesUI;
  verificationStatus: EVerificationStatusUi;
  specie?: string;
  breed: string | null;
};

export type TRevokeAnimalDB = {
  animalResidencyId: string;
  id: string;
  name: string;
  profilePicture: string | null;
  status: EAnimalResidencyStatusDB;
  type: EAnimalTypes;
  verificationStatus: EVerificationStatus;
  specie: TSpeciesProps | null;
  breed: string | null;
};
export type TRevokeAnimalUI = {
  animalId: string;
  id: string;
  name: string;
  profilePicture: string | null;
  status: EAnimalResidencyStatusUI;
  type: EAnimalTypesUI;
  verificationStatus: EVerificationStatusUi;
  specie?: string;
  breed: string | null;
};
export enum EUnitAnimalPresenceStatusDB {
  YES = 'YES',
  NO = 'NO',
  UNSPECIFIED = 'UNSPECIFIED',
  CONFLICT = 'CONFLICT',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}
export enum EUnitAnimalPresenceStatusUI {
  YES = 'Yes',
  NO = 'No',
  UNSPECIFIED = 'Unspecified',
  CONFLICT = 'Conflict',
  NOT_APPLICABLE = 'Not applicable',
}

export type TResidentsTableDB = {
  isPropertyCustom: boolean;
  actionActorName: string;
  actionReason: string | null;
  actionTime: string;
  canApprove: boolean;
  canMoveIn: boolean;
  canMoveOut: boolean;
  canReject: boolean;
  canRevokeApproval: boolean;
  canRevokeMoveIn: boolean;
  canRevokeNotice: boolean;
  canNotice: boolean;
  email: string | null;
  id: string;
  leaseToDate: string | null;
  moveInDate: string | null;
  name: string;
  numOfAnimals: number;
  petStatus: EPetStatusDB;
  phone: string | null;
  policyId: string | null;
  policyName: string | null;
  propertyId: string | null;
  propertyName: string | null;
  residencyStatus: EResidencyStatusDB;
  statusChangeInfo: TStatusInfoWithSourceDB;
  tenantId: string;
  unitId: string | null;
  unitNumber: string | null;
  numOfUnitAnimals: number;
  unitAnimalPresenceStatus: EUnitAnimalPresenceStatusDB;
};
export type TResidentsTableUI = {
  customProperty: boolean;
  actionActorName: string;
  actionReason: string | null;
  actionTime: string;
  canApprove: boolean;
  canMoveIn: boolean;
  canMoveOut: boolean;
  canReject: boolean;
  canRevokeApproval: boolean;
  canRevokeMoveIn: boolean;
  canRevokeNotice: boolean;
  canNotice: boolean;
  email: string | null;
  id: string;
  leaseToDate: string | null;
  moveInDate: string | null;
  name: string;
  numOfAnimals: number;
  petStatus: EPetStatusUI;
  phone: string | null;
  policyId: string | null;
  policyName: string | null;
  propertyId: string | null;
  propertyName: string | null;
  residencyStatus: EResidencyStatusUI;
  statusChangeInfo: TStatusInfoWithSourceUI | null;
  tenantId: string;
  unitId: string | null;
  unitNumber: string | null;
  unitAnimalPresenceStatus: EUnitAnimalPresenceStatusUI;
  numOfUnitAnimals: number;
};

export type TAddNewResidentDB = {
  emailAddress: string;
  leaseToDate: string;
  moveInDate: string;
  name: string;
  phoneNumber?: string;
  policyId: string;
  propertyId: string;
  status: string;
  unitId?: string;
  needToScreen: boolean;
};
export type TAddNewResidentUI = {
  emailAddress: string;
  startTime: Moment;
  endTime: Moment;
  name: string;
  phoneNumber?: string;
  policy: TOptionTypeUi;
  property: TOptionTypeUi & { rentalId: string };
  status: string;
  unit?: TOptionTypeUi;
  newUnitId: string;
  needToScreen: boolean;
};

export type TAnimalsScreeningUI = {
  tenantResidencyId: string;
  needToScreen: boolean;
};

export type TAnimalsScreeningDB = {
  tenantResidencyId: string;
  needToScreen: boolean;
};

export enum EPetStatusDB {
  HAS_ANIMAL = 'HAS_ANIMAL',
  HAS_NOT_ANIMAL = 'HAS_NOT_ANIMAL',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
}

export enum EPetStatusUI {
  HAS_ANIMAL = 'Yes',
  HAS_NOT_ANIMAL = 'No',
  NOT_SPECIFIED = 'Not specified',
}

export type TBulkScreeningDB = {
  needToScreen: boolean;
};
export type TBulkScreeningUI = {
  needToScreen: boolean;
  search: string;
};
