import { useEffect, useRef, useState, Dispatch, SetStateAction } from 'react';

// Hooks for safe state update during ansyncronous actions if component unmount.
export const useStateSafely = <T,>(initialValue: T): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState(initialValue);
  const isSafe = useRef(true);

  useEffect(() => {
    isSafe.current = true;
    return () => {
      isSafe.current = false;
    };
  }, []);

  const setValueSafely = (newValue: T) => {
    if (isSafe.current) {
      setValue(newValue);
    }
  };

  return [value, setValueSafely as Dispatch<SetStateAction<T>>];
};

export const useStateSafelyNoParams = <T,>(initialValue?: T): [T | undefined, (newValue: T) => void] => {
  const [value, setValue] = useState(initialValue);
  const isSafe = useRef(true);

  useEffect(() => {
    isSafe.current = true;
    return () => {
      isSafe.current = false;
    };
  }, []);

  const setValueSafely = (newValue: T) => {
    if (isSafe.current) {
      setValue(newValue);
    }
  };

  return [value, setValueSafely];
};
