import {
  TPageableDataParamUI,
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'antd/data/types/pagableData.type';
import { authSplitApi } from 'antd/redux/helpers/slice.helpers';
import {
  TAddAttachmentUI,
  TAddCommentUI,
  TAssignComplaintDB,
  TAssignComplaintUI,
  TComplaintDB,
  TComplaintDetailsDB,
  TComplaintDetailsUI,
  TComplaintUI,
  TCreateComplaintDB,
  TCreateComplaintFromResidenceDB,
  TCreateComplaintFromResidenceUI,
  TCreateComplaintUI,
  TReporterDB,
  TReporterUI,
} from 'antd/data/types/complaint.types';
import {
  addAttachmentConvertor,
  addCommentConvertor,
  complaintAssingConverter,
  complaintCreateConverter,
  complaintDetailsConvertor,
  complaintFromResidenceCreateConverter,
  complaintGetConverter,
  reporterConvertor,
} from 'antd/data/converters/complaint.converters';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { EComplaintUrl } from 'antd/data/urls/complaint.url';
import { pageableDataConverter } from 'antd/data/converters/pagableData.converters';
import { residentsSearchGetConverter } from 'antd/data/converters/residence.converters';
import { TResidentSearchDB, TResidentSearchUI } from 'antd/data/types/residence.types';
import { queryParamConverter } from 'antd/data/converters/general.converters';

const COMPLAINTS = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EComplaintUrl.COMPLAINTS}`;

export const complaintsApi = authSplitApi('complaintsApi', [
  'complaints',
  'complaint', // caching data get data tag names
  'residentsSearch',
  'reporters',
]).injectEndpoints({
  endpoints: (build) => ({
    getComplaints: build.query<TPageableDataWithContentUi<TComplaintUI[]>, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: COMPLAINTS,
          method: 'GET',
          params: queryParamConverter(search, pagination),
        };
      },
      transformResponse: (data: TPageableDataWithContent<TComplaintDB[]>) => {
        const { content, ...other } = data;
        return {
          content: data.content.map((item) => complaintGetConverter.fromDb(item)),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
      providesTags: ['complaints'], // for caching and update cache
    }),
    getComplaint: build.query<TComplaintDetailsUI, string>({
      query(id) {
        return {
          url: `${COMPLAINTS}/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['complaint', 'complaints'],
      keepUnusedDataFor: 0.0001,
      transformResponse: (data: TComplaintDetailsDB) => complaintDetailsConvertor.fromDb(data),
    }),
    createComplaint: build.mutation<TCreateComplaintDB, TCreateComplaintUI>({
      query(body) {
        return {
          url: COMPLAINTS,
          method: 'POST',
          body: complaintCreateConverter.toDb(body),
        };
      },
      invalidatesTags: ['complaints'],
    }),
    createComplaintFromResidence: build.mutation<TCreateComplaintFromResidenceDB, TCreateComplaintFromResidenceUI>({
      query(body) {
        return {
          url: COMPLAINTS,
          method: 'POST',
          body: complaintFromResidenceCreateConverter.toDb(body),
        };
      },
      invalidatesTags: ['complaints'],
    }),
    assingComplaint: build.mutation<TAssignComplaintDB, TAssignComplaintUI>({
      query(body) {
        return {
          url: `${COMPLAINTS}/${EComplaintUrl.ASSIGN}`,
          method: 'PATCH',
          body: complaintAssingConverter.toDb(body),
        };
      },
      invalidatesTags: ['complaint', 'complaints'],
    }),
    getResidentsSearch: build.query<TResidentSearchUI[], void>({
      query() {
        return {
          url: `${COMPLAINTS}/${EComplaintUrl.RESIDENTS}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TResidentSearchDB[]) => {
        return data?.map((item) => residentsSearchGetConverter.fromDb(item)) || [];
      },
      providesTags: ['residentsSearch'], // for caching and update cache
    }),
    addAttachment: build.mutation<void, TAddAttachmentUI>({
      query(data) {
        return {
          url: `${COMPLAINTS}/${EComplaintUrl.ATTACHMENT}`,
          method: 'PATCH',
          body: addAttachmentConvertor.toDb(data),
        };
      },
      invalidatesTags: ['complaint'],
    }),
    addComment: build.mutation<void, TAddCommentUI>({
      query(data) {
        return {
          url: `${COMPLAINTS}/${EComplaintUrl.COMMENT}`,
          method: 'PATCH',
          body: addCommentConvertor.toDb(data),
        };
      },
      invalidatesTags: ['complaint'],
    }),
    getComplaintReporters: build.query<TReporterUI[], void>({
      query() {
        return {
          url: `${COMPLAINTS}/${EComplaintUrl.REPORTERS}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TReporterDB[]) => data.map((item) => reporterConvertor.fromDb(item)),
      providesTags: ['reporters'],
    }),
    updateViewedStatus: build.mutation<void, { complaintId: string }>({
      query(data) {
        return {
          url: `${COMPLAINTS}/${EComplaintUrl.VIEW}`,
          method: 'PATCH',
          body: data,
        };
      },
      invalidatesTags: ['complaints'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddAttachmentMutation,
  useGetComplaintsQuery,
  useCreateComplaintMutation,
  useCreateComplaintFromResidenceMutation,
  useAssingComplaintMutation,
  useGetResidentsSearchQuery,
  useGetComplaintQuery,
  useAddCommentMutation,
  useGetComplaintReportersQuery,
  useUpdateViewedStatusMutation,
} = complaintsApi;
