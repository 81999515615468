import Amplify from 'aws-amplify';
import { Provider } from 'react-redux';
import { store } from 'antd/redux/store';
import ReactDOM from 'react-dom';
import { Buffer } from 'buffer';
import App from './App';
import { PropertyEditProvider } from './contexts/PropertyEditContext';
import awsExports from './aws-exports.js';

window.global ||= window;
global.Buffer = Buffer;

Amplify.configure(awsExports);
ReactDOM.render(
  <Provider store={store}>
    <PropertyEditProvider>
      <App />
    </PropertyEditProvider>
  </Provider>,
  document.getElementById('root')
);
