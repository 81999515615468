import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { Link, useLocation } from 'react-router-dom';
import MAppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useStyles } from './appbar.style';
import { RoutesNames } from '../../RoutesNames';

const AppBarLoggedOut = forwardRef(({ handleMenuClick }, ref) => {
  const { pathname } = useLocation();
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:768px)');

  const profile = 'landlord';

  return (
    <MAppBar className={classes.stickyMainHeader}>
      <Toolbar className={classes.topBar}>
        {matches && pathname !== '/intro' && (
          <IconButton onClick={handleMenuClick} className={clsx(classes.customHover, classes.menuIcon)}>
            <MenuIcon />
          </IconButton>
        )}
        <div className={classes.buttonsWrapper}>
          <div className={classes.iconsContainer}>
            <IconButton className={classes.customHover}>
              <Link to={`${RoutesNames.SignIn}`}>
                {profile && profile.profilePicture ? (
                  <img src={profile.profilePicture} alt='profile pic' className={classes.photo} />
                ) : (
                  <AccountCircle className={clsx(classes.headerIcons)} />
                )}
              </Link>
            </IconButton>
          </div>
        </div>
      </Toolbar>
    </MAppBar>
  );
});

export default AppBarLoggedOut;
