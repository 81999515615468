import { createSlice } from '@reduxjs/toolkit';
import { EUserRolesUI, TGetUserDataUI } from 'antd/data/types/user.types';
import { userApi } from 'antd/redux/apiSlices/user.slice';
import { TCurrentAuthUserUI } from 'antd/data/types/amplify.types';
import { authApi } from 'antd/redux/apiSlices/auth.slice';
import { cloneDeep } from 'lodash';

export type TAuthState = TGetUserDataUI & { amplifyUser: null | TCurrentAuthUserUI };
const initialState: TAuthState = {
  userType: null,
  users: null,
  impersonateOn: false,
  amplifyUser: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '') : null,
};

const usersSlice = createSlice({
  name: 'usersSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(userApi.endpoints.getUsers.matchFulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          userType: action.payload.userType,
          users: action.payload.users,
          impersonateOn: action.payload.impersonateOn,
        };
      }
      return state;
    });
    builder.addMatcher(userApi.endpoints.impersonateUser.matchFulfilled, (state, action) => {
      if (action.payload && state.users) {
        const newState = cloneDeep(state);
        return {
          ...state,
          userType: action.payload.type,
          users: {
            [EUserRolesUI.PROPERTY_MANAGER]: null,
            [EUserRolesUI.LANDLORD]: null,
            ...(newState.users && newState.users),
            [action.payload.type]: action.payload,
            [EUserRolesUI.ADMIN]: newState.users?.admin
              ? {
                  ...newState.users[EUserRolesUI.ADMIN],
                  impersonateOn: { userId: action.payload.id, name: action.payload.name },
                }
              : null,
          },

          impersonateOn: true,
        };
      }
      return state;
    });
    builder.addMatcher(userApi.endpoints.deleteImpersonation.matchFulfilled, (state) => {
      if (state.users) {
        const newState = cloneDeep(state);
        return {
          ...state,
          userType: EUserRolesUI.ADMIN,
          users: {
            [EUserRolesUI.ADMIN]: newState.users?.admin
              ? {
                  ...newState.users[EUserRolesUI.ADMIN],
                  impersonateOn: null,
                }
              : null,
            [EUserRolesUI.PROPERTY_MANAGER]: null,
            [EUserRolesUI.LANDLORD]: null,
          },
          impersonateOn: false,
        };
      }
      return state;
    });
    builder.addMatcher(authApi.endpoints.signIn.matchFulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          amplifyUser: action.payload,
        };
      }
      return state;
    });
    builder.addMatcher(authApi.endpoints.signOut.matchFulfilled, () => {
      localStorage.clear();
      return {
        userType: null,
        users: null,
        impersonateOn: null,
        amplifyUser: null,
      };
    });
    builder.addMatcher(authApi.endpoints.setNewPassword.matchFulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          amplifyUser: action.payload,
        };
      }
      return state;
    });
  },
});

export default usersSlice;
