import { CognitoUser } from '@aws-amplify/auth';
import { ChallengeNameType } from 'aws-sdk/clients/cognitoidentityserviceprovider';

export type TChallengeNameType = ChallengeNameType;

export interface UserEmailAttributes {
  sub: string;
  email: string;
  email_verified: boolean;
  name: string;
  updated_at: string;
}

export interface ICurrentAuthUser extends CognitoUser {
  username: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  attributes: UserEmailAttributes;
}

export type TCurrentAuthUserUI = {
  username: string;
  name?: string;
  email?: string;
  challengeName?: TChallengeNameType;
};

export type TAmplifyError = {
  code: string;
  name: string;
  message: string;
  username: string;
};
export type TAmplifyErrorPayload = { data: TAmplifyError };

export enum EErrorCode {
  USER_NOT_CONFIRMED_EXCEPTION = 'UserNotConfirmedException',
  INVALID_PARAMETER_EXCEPTION = 'InvalidParameterException',
  NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException',
  USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException',
}

export const SIGN_IN_FAILURE_MESSAGE = {
  'UserMigration failed with error Bad credentials.': 'Incorrect username or password.',
};

export const RESET_PASSWORD_FAILURE_MESSAGE = {
  'UserMigration failed with error Bad credentials.': 'User does not exist.',
};

export enum ESignUpErrors {
  'PreSignUp failed with error User already exists.' = 'User already exists.',
  'PreSignUp failed with error Your country is not supported.' = 'Your country is not supported.',
}
