import { useCallback, useEffect, useRef } from 'react';
import { useCheckIfSessionExists } from 'antd/hooks/useCheckIfSessionExists';

export const useTabRelocation = (onSuccess = () => {}, onError = () => {}) => {
  const intervalId = useRef();
  const checkIfSessionExists = useCheckIfSessionExists();

  const onMessageListening = (event) => {
    const devOrigins = ['http://localhost:3001'];
    const prodOrigins = [import.meta.env.REACT_APP_ADMIN_HOSTNAME];
    const origins = import.meta.env.MODE === 'development' ? devOrigins : prodOrigins;
    if (!origins.includes(event.origin)) {
      return;
    }
    if (event.data === 'received') {
      clearInterval(intervalId.current);
    }
  };
  useEffect(() => {
    window.addEventListener('message', onMessageListening);
    return () => {
      window.removeEventListener('message', onMessageListening);
    };
  }, []);
  const refetch = useCallback(async (to) => {
    await checkIfSessionExists();
    const admin = JSON.parse(localStorage.getItem('user'));
    const redirectionLink =
      import.meta.env.MODE === 'development' ? 'http://localhost:3001' : import.meta.env.REACT_APP_ADMIN_HOSTNAME;
    const newWindow = window.open(`${redirectionLink}/redirect`, '_blank');
    if (!newWindow) {
      onError();
      return;
    }
    intervalId.current = setInterval(() => {
      newWindow.postMessage(JSON.stringify({ ...admin, to }), `${redirectionLink}/redirect`);
      onSuccess();
    }, 100);
  }, []);

  return { refetch };
};
