import { TStatusChangeInfoDB, TStatusChangeInfoUI } from './general.types';

export type TDocsDB = {
  ANIMAL_VISITATION: TAnimalVisitationDocsDB;
};
export type TDocsUI = {
  animalVisitation: TAnimalVisitationDocsUI;
};
export type TAnimalVisitationDocsDB = {
  animalBreed: string;
  animalColors: string[];
  animalType: string;
  didUserConfirmLearningEfforts: boolean;
  explanation: string;
  isServiceAnimal: boolean;
  name: string;
  precautions?: string;
  requiredBecauseOfDisability: boolean;
  serviceAnimal: boolean;
  trainedFor?: string;
  visitEndDate: string;
  visitStartDate: string;
  willBeLeftWithoutSupervision: boolean;
};
export type TAnimalVisitationDocsUI = {
  animalBreed: string;
  animalColors: string[];
  animalType: string;
  didUserConfirmLearningEfforts: boolean;
  explanation: string;
  isServiceAnimal: boolean;
  name: string;
  precautions?: string;
  requiredBecauseOfDisability: boolean;
  serviceAnimal: boolean;
  trainedFor?: string;
  visitEndDate: string;
  visitStartDate: string;
  willBeLeftWithoutSupervision: boolean;
  dateRange: TDateRangeUI;
};
export type TDateRangeDB = {
  date: string;
  durationInDays: string;
  remainingHours: string;
};
export type TDateRangeUI = {
  date: string;
  durationInDays: string;
  durationInHours: string;
};
export type TDocumentsTemplateDB = {
  docType: string;
  preSignedUrl: string;
};
export type TDocumentsTemplateUI = {
  docType: string;
  preSignedUrl: string;
};
export type TTenantInfoDB = {
  residenceId: string;
  name: string;
  email: string | null;
  phone: string | null;
  profilePicture: string;
};
export type TTenantInfoUI = {
  tenantId: string;
  tenantName: string;
  email: string | null;
  phone: string | null;
  profilePicture: string;
};

export type TVisitationAnimalDB = {
  canApprove: boolean;
  canReject: boolean;
  docs: TDocsDB;
  documentsTemplate: TDocumentsTemplateDB[];
  id: string;
  leaseToDate: string;
  moveInDate: string;
  name: string;
  petPhotoUrl: string;
  policyName: string;
  policyId: string;
  policyUrl: string;
  status: EVisitationStatusDB;
  tenantInfo: TTenantInfoDB;
  type: string;
  unitNumber: string;
  propertyName: string;
  customProperty: boolean;
  statusChangeInfo: TStatusChangeInfoDB | null;
};
export type TVisitationAnimalUI = {
  canApprove: boolean;
  canReject: boolean;
  docs: TDocsUI;
  documentsTemplate: TDocumentsTemplateUI[];
  id: string;
  date: string;
  name: string;
  petPhotoUrl: string;
  policyName: string;
  policyId: string;
  policyUrl: string;
  status: EVisitationStatusUI;
  tenantInfo: TTenantInfoUI;
  type: string;
  unitNumber: string;
  propertyName: string;
  customProperty: boolean;
  statusChangeInfo: TStatusChangeInfoUI | null;
};

export enum EVisitationStatusDB {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  ARCHIVED = 'ARCHIVED',
}
export enum EVisitationStatusUI {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Not Approved',
  ARCHIVED = 'Archived',
}

export type TVisistationAnimalsDB = {
  animalName: string;
  endDate: string;
  id: string;
  propertyName: string;
  isPropertyCustom: boolean;
  rejectReason: string | null;
  residentName: string;
  startDate: string;
  status: EVisitationStatusDB;
  submissionDate: string;
  unitNumber: string;
  statusChangerName?: string;
  statusChangerRole?: string;
};
export type TVisistationAnimalsUI = {
  animalName: string;
  endDate: string;
  id: string;
  propertyName: string;
  customProperty: boolean;
  rejectReason: string | null;
  residentName: string;
  startDate: string;
  status: EVisitationStatusUI;
  submissionDate: string;
  unitNumber: string;
  statusChangeInfo?: TStatusChangeInfoUI;
};
