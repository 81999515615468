import React, {useEffect, useRef} from 'react';
import {Col, Row} from "antd/components/Grid";
import {Paragraph, Text} from 'antd/components/Typography';
import {TSignupDataUI} from "antd/data/types/auth.types";
import useForm from 'antd/hooks/useForm';
import {TextField} from "antd/components/TextField";
import Checkbox from "antd/components/Checkbox";
import Form, {FormItem} from 'antd/components/Form';
import PhoneInput from 'antd/components/PhoneInput';
import Button from 'antd/components/Button';
import {Colors} from 'core/CssVariables';
import get from "lodash/get";
import {useSignUpMutation} from "antd/redux/apiSlices/auth.slice";
import Alert from "antd/components/Alert";
import AuthFormsWrapper from "antd/componentsShared/AuthFormsWrapper";
import {RoutesNames} from "routes/RoutesNames";
import {useStateSafely} from "antd/hooks/useStateSafely";
import {Link} from 'react-router-dom';
import {signUpFields} from "./Signup.data";
import TermsAndConditions from "./TermsAndConditions";
import Confirmation from "../Confirmation";


const {name, passwordConfirm, terms, password, email, phone, businessName} = signUpFields
const Signup = () => {
    const {form} = useForm<TSignupDataUI>();
    const [error, setError] = useStateSafely<string>('');
    const alertRef = useRef<HTMLDivElement | null>(null);
    const [signup, {isLoading, data, reset}] = useSignUpMutation();
    const onSubmit = async (values: TSignupDataUI) => {
        try {
            await signup(values).unwrap();
        } catch (err) {
            const message = get(err, ['data', 'message']) || 'Something went wrong!';
            setError(message);
        }
    }
    useEffect(() => {
        if (error) {
            alertRef.current?.scrollIntoView({block: 'end'})
        }
    }, [error])
    if (data) return <Confirmation email={form.getFieldValue('email')} onResetStates={reset}/>
    return (
        <Row gutter={[24, 24]} justify='center'>
            <AuthFormsWrapper title="Let's get started as landlord">
                <Form form={form} onFinish={onSubmit} requiredMark={false} scrollToFirstError>
                    <Row>
                        <Col span={24} ref={alertRef}>
                            <Alert type='error' message={error}/>
                        </Col>
                        <Col span={24}>
                            <FormItem name={name.name} rules={name.rules} label={name.label}>
                                <TextField placeholder={name.placeholder}/>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem name={businessName.name} rules={businessName.rules} label={businessName.label}>
                                <TextField placeholder={businessName.placeholder}/>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem name={email.name} rules={email.rules} label={email.label}>
                                <TextField placeholder={email.placeholder}/>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem name={phone.name} rules={phone.rules} label={phone.label}>
                                <PhoneInput placeholder={phone.placeholder}/>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem name={password.name} rules={password.rules} label={password.label}>
                                <TextField placeholder={password.placeholder} type='password'/>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem name={passwordConfirm.name} rules={passwordConfirm.rules}
                                      label={passwordConfirm.label}>
                                <TextField type='password' placeholder={passwordConfirm.placeholder}/>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem name={terms.name} rules={terms.rules} valuePropName='checked'>
                                <Checkbox label={<TermsAndConditions/>}/>
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <Button htmlType='submit' loading={isLoading}>Sign up</Button>
                            <Paragraph className='mt-4' fontLevel={5}>
                                Already have an account?{' '}
                                <Text color={Colors.DarkBlue} fontLevel={5} underline>
                                    <Link to={RoutesNames.SignIn}>
                                        Login here
                                    </Link>
                                </Text>
                            </Paragraph>
                        </Col>
                    </Row>
                </Form>
            </AuthFormsWrapper>
        </Row>
    );
};

export default Signup;