import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { EResidenceUrl } from 'antd/data/urls/residence.url';
import { TRoommatesDB, TRoommatesUI } from 'antd/data/types/roommates.types';
import { roommatesConverter } from 'antd/data/converters/roommates.converters';
import { emptySplitApi } from '../helpers/slice.helpers';

const URL = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.RESIDENCE}`;

export const roommatesApi = emptySplitApi('roommates').injectEndpoints({
  endpoints: (build) => ({
    getRoommates: build.query<TRoommatesUI, { id: string }>({
      query(params) {
        return {
          url: `${URL}/${EResidenceUrl.WITH_ROOMMATES}`,
          method: 'GET',
          params: { tenantResidencyId: params.id },
        };
      },
      transformResponse: (data: TRoommatesDB) => roommatesConverter.fromDb(data),
    }),
  }),
  overrideExisting: false,
});

export const { useGetRoommatesQuery } = roommatesApi;
