import GlobalStyles, { DropDownGlobalStyle } from 'antd/styles/globalStyles';
import React, { FC, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { LightTheme } from './theme';
import 'antd/styles/main.less';

const ThemeWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <ThemeProvider theme={LightTheme}>
      <GlobalStyles />
      <DropDownGlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default ThemeWrapper;
