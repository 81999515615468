export const chargingMethodLabel = {
  UNIT: 'rental units',
  CONTRACT: 'active contracts',
  UNIT_WITH_APPLICATION: 'units with animals',
};

export const completePaymentText = {
  contracts: 'Please complete your payment to enter this section.',
  settings: 'Please complete your payment.',
};
