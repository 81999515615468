import { EAdminUrl } from 'antd/data/urls/admin.url';
import { EAnimalUrl } from 'antd/data/urls/animal.url';
import {
  TChangeLandlordStatusUI,
  TLandlordGetDB,
  TLandlordGetUI,
  TPetChargeReportUI,
} from 'antd/data/types/landlord.types';
import {
  changeLandlordStatusConverter,
  generatePetChargeReportConverter,
  landlordConverter,
  landlordStatusConverter,
} from 'antd/data/converters/landlord.converter';
import { authSplitApi } from 'antd/redux/helpers/slice.helpers';
import {
  TPageableDataParamUI,
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'antd/data/types/pagableData.type';
import { ELandlordsUrl } from 'antd/data/urls/landlord.url';
import { queryParamConverter } from 'antd/data/converters/general.converters';
import { pageableDataConverter } from 'antd/data/converters/pagableData.converters';

const URL = `${EAdminUrl.ADMIN}`;
export const landlordApi = authSplitApi('landlords', ['landlordDetails', 'landlordsTable']).injectEndpoints({
  endpoints: (build) => ({
    generatePetChargeStatusReport: build.query<any, TPetChargeReportUI>({
      query(params) {
        return {
          url: `${URL}/${EAnimalUrl.ANIMAL}/${EAnimalUrl.PET_CHARGING_INFO}/${EAnimalUrl.EXPORT}`,
          method: 'GET',
          params: generatePetChargeReportConverter.toDb(params),
          responseHandler: (response) => response.blob(),
          cache: 'no-cache',
        };
      },
    }),
    getLandlords: build.query<TPageableDataWithContentUi<TLandlordGetUI[]>, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: `${URL}/${ELandlordsUrl.PROPERTY_MANAGEMENT}`,
          method: 'GET',
          params: queryParamConverter(search, pagination),
        };
      },
      transformResponse: (data: TPageableDataWithContent<TLandlordGetDB[]>) => {
        const { content, ...other } = data;
        return {
          content: data.content.map((item) => landlordConverter.fromDb(item)),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
      providesTags: ['landlordsTable'],
    }),
    changeLandlordStatus: build.mutation<void, TChangeLandlordStatusUI>({
      query(body) {
        return {
          url: `${URL}/${ELandlordsUrl.PROPERTY_MANAGEMENT}/${ELandlordsUrl.STATUS}`,
          method: 'PATCH',
          body: changeLandlordStatusConverter.toDb(body),
        };
      },
      invalidatesTags: ['maintainer', 'maintainers'],
    }),
  }),
});

export const { useLazyGeneratePetChargeStatusReportQuery, useGetLandlordsQuery, useChangeLandlordStatusMutation } =
  landlordApi;
