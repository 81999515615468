import {
  TPageableDataParamUI,
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'antd/data/types/pagableData.type';
import { authSplitApi } from 'antd/redux/helpers/slice.helpers';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { EComplaintUrl } from 'antd/data/urls/complaint.url';
import { pageableDataConverter } from 'antd/data/converters/pagableData.converters';
import { queryParamConverter } from 'antd/data/converters/general.converters';
import {
  TAddAttachmentUI,
  TAddCommentUI,
  TNoticeDB,
  TNoticeDetailsDB,
  TNoticeDetailsUI,
  TNoticeUI,
  TReporterUI,
  TReporterDB,
} from 'antd/data/types/notice.types';
import {
  addAttachmentNoticeConvertor,
  addCommentNoticeConvertor,
  noticeDetailsConvertor,
  noticeGetConverter,
  reporterConvertor,
} from 'antd/data/converters/notice.converters';

const NOTICES = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EComplaintUrl.NOTICES}`;

export const noticeApi = authSplitApi('noticeApi', [
  'notices', // caching data get data tag names
  'notice', // caching data get data tag names
  'residentsSearch',
  'reporters',
]).injectEndpoints({
  endpoints: (build) => ({
    getNoticesList: build.query<TPageableDataWithContentUi<TNoticeUI[]>, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: NOTICES,
          method: 'GET',
          params: queryParamConverter(search, pagination),
        };
      },
      transformResponse: (data: TPageableDataWithContent<TNoticeDB[]>) => {
        const { content, ...other } = data;
        return {
          content: data.content.map((item) => noticeGetConverter.fromDb(item)),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
      providesTags: ['notices'], // for caching and update cache
    }),
    getNoticeDetails: build.query<TNoticeDetailsUI, string>({
      query(id) {
        return {
          url: `${NOTICES}/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['notices', 'notice'],
      keepUnusedDataFor: 0.0001,
      transformResponse: (data: TNoticeDetailsDB) => noticeDetailsConvertor.fromDb(data),
    }),
    addAttachment: build.mutation<void, TAddAttachmentUI>({
      query(data) {
        return {
          url: `${NOTICES}/${EComplaintUrl.ATTACHMENT}`,
          method: 'PATCH',
          body: addAttachmentNoticeConvertor.toDb(data),
        };
      },
      invalidatesTags: ['notice'],
    }),
    addComment: build.mutation<void, TAddCommentUI>({
      query(data) {
        return {
          url: `${NOTICES}/${EComplaintUrl.COMMENT}`,
          method: 'PATCH',
          body: addCommentNoticeConvertor.toDb(data),
        };
      },
      invalidatesTags: ['notice'],
    }),
    getNoticeReporters: build.query<TReporterUI[], void>({
      query() {
        return {
          url: `${NOTICES}/${EComplaintUrl.REPORTERS}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TReporterDB[]) => data.map((item) => reporterConvertor.fromDb(item)),
      providesTags: ['reporters'],
    }),
    updateViewedStatus: build.mutation<void, { noticeId: string }>({
      query(data) {
        return {
          url: `${NOTICES}/${EComplaintUrl.VIEW}`,
          method: 'PATCH',
          body: data,
        };
      },
      invalidatesTags: ['notices'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetNoticeDetailsQuery,
  useGetNoticesListQuery,
  useAddAttachmentMutation,
  useAddCommentMutation,
  useGetNoticeReportersQuery,
  useUpdateViewedStatusMutation,
} = noticeApi;
