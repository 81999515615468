import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

export const useStyles = makeStyles((theme) => ({
  location: {
    flexGrow: '1',
  },
  navDropdown: {
    '& .MuiList-root': {
      padding: theme.spacing(3),
    },
    '&.MuiList-padding': {
      padding: 0,
    },
  },
  dropdownItem: {
    '&.MuiButtonBase-root': {
      padding: '6px 24px',
      '&:first-child': {
        padding: '12px 24px 6px',
      },
      '&:last-child': {
        padding: '6px 24px 12px',
      },
    },
  },
  popover: {
    '& .MuiPaper-root': {
      borderRadius: '24px',
      width: 219,
    },
  },
  buttonsWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  unImpersonate: {
    '@media(max-width:1024px)': {
      padding: '4px 16px',
    },
  },
  redirect: {
    marginLeft: 10,
    borderColor: theme.palette.secondary.main,
    border: '1px solid',
    '&:hover': {
      borderColor: `${theme.palette.secondary.main} !important`,
      border: '1px dashed !important',
    },
  },
  notificationIcon: {
    margin: '0 8px',
  },
  notificationPopover: {
    '& .MuiPaper-root': {
      borderRadius: 8,
      boxShadow: '0px 0px 12px 1px rgb(0 0 0 / 27%)',
      position: 'absolute',
      right: '10%!important',
      left: 'auto!important',
      maxWidth: '453px',
      top: '70px!important',
      width: '100%',
      maxHeight: '425px',
      overflow: 'auto',

      '@media(max-width:453px)': {
        left: '0 !important',
        right: '0 !important',
        top: '60px !important',
      },
    },
    '@media(max-width:453px)': {
      '&:after': {
        content: '""',
        backgroundColor: '#00000033 !important',
        top: '65px',
        height: '100%',
        width: '100%',
        position: 'absolute',
        zIndex: -6,
      },
    },
  },
  photo: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: '50%',
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    objectFit: 'cover',

    '@media(max-width:1024px)': {
      margin: `0 0 0 20px`,
    },
  },
  headerIcons: {
    color: theme.primarycolor,
    display: 'flex',
  },
  headerIconsNotification: {
    color: theme.primarycolor,
    height: theme.spacing(4),
  },
  iconsContainer: {
    display: 'flex',
  },
  notificationBadge: {
    '& .MuiBadge-colorSecondary': {
      color: theme.palette.primary.main,
      backgroundColor: theme.backgroundColor,
    },
  },
  badge: {
    right: 0,
    top: 20,
    backgroundColor: '#ff8282',
    color: 'white',
    fontFamily: 'aesthet-nova',
  },
  badgeImage: {
    right: 0,
    top: 30,
    backgroundColor: '#ff8282',
    color: 'white',
    fontFamily: 'aesthet-nova',
  },
  customHover: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '@media(max-width:960px)': {
      padding: 0,
    },
  },
  stickyMainHeader: {
    position: 'sticky',
    top: 0,
    left: 0,
    zIndex: 100,

    '@media(max-width:1024px)': {
      height: 72,
      display: 'flex',
      justifyContent: 'center',
    },
  },
  exitIcon: {
    marginRight: '8px',
  },
  topBar: {
    justifyContent: 'end',
    '@media(max-width:1024px)': {
      justifyContent: 'space-between',
    },
  },
  menuIcon: {
    padding: 0,
    '& svg': {
      color: '#0D0D0D',
    },
  },
}));

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: `${theme.palette.secondary.main}`,
    color: `${theme.typography.color}`,
    fontSize: 14,
    borderRadius: 8,
    boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.12)',
    padding: '8px 16px',
  },
}))(Tooltip);
