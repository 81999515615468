import { useAppDispatch } from '../../contexts/PropertyEditContext';

const useShowToaster = () => {
  const dispatch = useAppDispatch();
  const show = ({ title, type }) => {
    dispatch({
      type: 'OPEN_ALERT',
      payload: {
        message: title,
        severity: type,
      },
    });
  };
  return show;
};

export default useShowToaster;
