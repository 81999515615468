import {
  applicationAnimalScreeningConvertor,
  applicationDetailsConvertor,
  applicationVerificationMessageConvertor,
  approveApplicationConverter,
  approvePassedMoveInApplicationConvertor,
  getApplicantAnimalDetailsConverter,
} from 'antd/data/converters/application.converters';
import {
  TAnimalApplicantDetail,
  TAnimalApplicantDetailUi,
  TApplicantAnimalScreeningUI,
  TApplicationDetailsDB,
  TApplicationDetailsUI,
  TApproveApplicationDB,
  TApproveApplicationUI,
  TApprovePassedMoveInApplicationDB,
  TApprovePassedMoveInApplicationUI,
  TVerificationMessageDB,
  TVerificationMessageUi,
} from 'antd/data/types/application.types';
import { EApplicationUrls } from 'antd/data/urls/application.url';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { authSplitApi } from '../helpers/slice.helpers';

const baseUrl = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EApplicationUrls.APPLICATION}`;

export const applicationApi = authSplitApi('applicationApi', [
  'applicationDetails',
  'applicationVerification',
  'applicantAnimal',
  'applications',
]).injectEndpoints({
  endpoints: (build) => ({
    approveApplication: build.mutation<TApprovePassedMoveInApplicationUI, TApproveApplicationUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EApplicationUrls.APPROVE}`,
          method: 'PATCH',
          body: approveApplicationConverter.toDb(body),
        };
      },
      transformResponse: (data: TApprovePassedMoveInApplicationDB) =>
        approvePassedMoveInApplicationConvertor.fromDb(data),
    }),
    dontApproveAnimalApplicant: build.mutation<void, { applicationId: string; rejectReason: string }>({
      query({ applicationId, rejectReason }) {
        return {
          url: `${baseUrl}/${EApplicationUrls.REJECT}`,
          method: 'PATCH',
          body: { applicationId, rejectReason },
        };
      },
      invalidatesTags: ['applicantAnimal'],
    }),
    getApplicationDetails: build.query<TApplicationDetailsUI, { applicationId: string }>({
      query({ applicationId }) {
        return {
          url: `${baseUrl}/${EApplicationUrls.MOVE_IN}/${EApplicationUrls.DETAILS}`,
          method: 'GET',
          params: { applicationId },
        };
      },
      providesTags: ['applicationDetails'],
      transformResponse: (data: TApplicationDetailsDB) => applicationDetailsConvertor.fromDb(data),
    }),
    getApplicantAnimal: build.query<TAnimalApplicantDetailUi, string>({
      query(id) {
        return {
          url: `${baseUrl}/${EApplicationUrls.ANIMALS}/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['applicantAnimal'],
      transformResponse: (data: TAnimalApplicantDetail) => getApplicantAnimalDetailsConverter.fromDb(data),
    }),
    getApplicationVerification: build.query<TVerificationMessageUi, { applicationId: string; propertyId: string }>({
      query(params) {
        return {
          url: `${baseUrl}/${EApplicationUrls.MOVE_IN}/${EApplicationUrls.VERIFY}`,
          method: 'GET',
          params: { ...params },
        };
      },
      providesTags: ['applicationVerification'],
      transformResponse: (data: TVerificationMessageDB) => applicationVerificationMessageConvertor.fromDb(data),
    }),
    moveIn: build.mutation<TApproveApplicationDB, TApproveApplicationUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EApplicationUrls.MOVE_IN}`,
          method: 'PATCH',
          body: approveApplicationConverter.toDb(body),
        };
      },
    }),
    retractAnimal: build.mutation<string, { applicationId: string; reason?: string }>({
      query(body) {
        return {
          url: `${baseUrl}/${EApplicationUrls.RETRACT}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['applicantAnimal'],
    }),
    reApplyAnimal: build.mutation<string, { applicationId: string; reason?: string }>({
      query(body) {
        return {
          url: `${baseUrl}/${EApplicationUrls.REAPPLY}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['applicantAnimal'],
    }),
    applicantAnimalScreening: build.mutation<string, TApplicantAnimalScreeningUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EApplicationUrls.SCREENING}`,
          method: 'PATCH',
          body: applicationAnimalScreeningConvertor.toDb(body),
        };
      },
      invalidatesTags: ['applicantAnimal'],
    }),
    conditionalApplicantApprove: build.mutation<string, string>({
      query(applicationId) {
        return {
          url: `${baseUrl}/${EApplicationUrls.CONDITIONAL_APPROVED}/${EApplicationUrls.APPROVE}`,
          method: 'PATCH',
          body: { applicationId },
        };
      },
      invalidatesTags: ['applicantAnimal', 'applicationDetails', 'applications'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useApproveApplicationMutation,
  useGetApplicantAnimalQuery,
  useDontApproveAnimalApplicantMutation,
  useLazyGetApplicationDetailsQuery,
  useGetApplicationDetailsQuery,
  useGetApplicationVerificationQuery,
  useMoveInMutation,
  useRetractAnimalMutation,
  useReApplyAnimalMutation,
  useApplicantAnimalScreeningMutation,
  useConditionalApplicantApproveMutation,
} = applicationApi;
