import { TSelectOptionTypeUi } from 'antd/components/Select/Select.types';
import { TFetchConverter } from '../types/converter.types';

export const supportedStatesConverter: TFetchConverter<TSelectOptionTypeUi, string> = {
  fromDb: (data) => {
    return {
      value: data.toUpperCase(),
      label: data,
    };
  },
};
