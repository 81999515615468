import { EAuthUrls } from 'antd/data/urls/auth.url';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { TSetPasswordFormDB, TSetPasswordFormUI } from 'antd/data/types/auth.types';
import { setNewPasswordConverter } from 'antd/data/converters/auth.converters';
import { BaseQuery, QueryReturnValue } from '../store';

export const setNewPasswordService = async (baseQuery: BaseQuery, arg: TSetPasswordFormUI) => {
  const data = (await baseQuery({
    url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EAuthUrls.AUTH}/${EAuthUrls.TEMPORARY_PASSWORD}`,
    method: 'PATCH',
    body: setNewPasswordConverter.toDb(arg),
  })) as QueryReturnValue<TSetPasswordFormDB>;
  return { data };
};
