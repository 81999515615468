export const baseUrlsV2 = {
  PROPERTY_MANAGER: 'property_manager/api/v2',
  TENANT: 'tenant/api/v2',
  ADMIN: 'admin/api/v2',
};

export const applicationEndpoints = {
  APPLICATION: 'application',
  TEMPLATE: `template`,
  WITH_DOCUMENTS: `withDocuments`,
  DOCUMENTS: 'documents',
  CHANGELOG: 'changelog',
  STATUS: 'status',
  COMMENT: 'comment',
  DOWNLOAD: 'download',
  STATISTICS: 'statistics',
  ACTION: 'action',
  RECORDS: 'records',
  VIEW: 'view',
  DASHBOARD: 'dashboard',
  APPROVE: 'approve',
  REJECT: 'reject',
};

export const contractEndpoints = {
  CONTRACT: 'contract',
  CONTENT: 'content',
  POLICY: 'policy',
  MOVE: 'move',
  TENANTS: 'tenants',
  TERMINATE: 'terminate',
  CONTENT_SUGGESTION: 'contentSuggestion',
  METADATA: 'metadata',
  STATUS: 'status',
};

export const unitEndpoints = {
  RENTALS: 'rentals',
  UNITS: 'units',
  PAGE: 'page',
};

export const curriculumEndpoints = {
  COURSE: 'course',
  EXTENDED: 'extended',
  TRAINING: 'training',
};

export const subscribersEndpoints = {
  SUBSCRIBERS: 'subscribers',
};

export const maintainerEndpoints = {
  MAINTAINER: 'maintainer',
  INVITE: 'invite',
  RENTALS: 'rentals',
  TENANT_COMMUNICATION: 'tenant-communication',
};

export const paymentEndpoints = {
  PAYMENT_METHOD: 'paymentMethod',
  SUBSCRIPTION: 'subscription',
  TENANT_PAYS: 'tenantPays',
};

export const userEndpoints = {
  STATUS: 'status',
  PROFILE_PICTURE: 'profile-picture',
  NAME: 'name',
  PROFILE: 'profile',
};

export const tenantEndpoints = {
  TENANT: 'tenant',
  ALL: 'all',
  NAME: 'name',
  ATTRIBUTES: 'attributes',
  INVITE: 'invite',
};

export const rentalEndpoints = {
  RENTAL: 'rentals',
  METADATA: 'metadata',
  POLICY: 'policy',
  PROPERTIES: 'properties',
  SEARCH: 'search',
  NAME: 'name',
  PHOTO: 'photo',
};

export const complaintEndpoints = {
  COMPLAINT: 'complaint',
  COMMENT: 'comment',
  COMMENTS: 'comments',
};

export const notificationEndpoints = {
  NOTIFICATION: 'notification',
};

export const landlordEndpoints = {
  LANDLORD: 'landlord',
  PAYMENT: 'payment',
  HISTORY: 'history',
  UPCOMING: 'upcoming',
  COURSE: 'course',
  PROFILE: 'profile',
};

export const supportedStates = {
  STATES: 'statePolicy',
  SUPPORTEDSTATES: 'supportedStates',
};

export const authEndpoints = {
  AUTH: 'auth',
  TEMPORARY_PASSWORD_CHANGE: 'temporaryPasswordChange',
  FORGOT_PASSWORD: 'forgotPassword',
  SIGN_UP: 'signup',
};

export const policyEndpoints = {
  POLICY: 'policy',
  DEFAULT_POLICIES: 'state-policies',
  CONTENT: 'content',
  HAS_POLICY: 'has-state-default-policy',
  STATE_POLICY: 'statePolicy',
  STATE_MAP: 'stateMap',
};

export const propertyManagerEndpoints = {
  PROPERTY_MANAGERS: 'property_managers',
  PROPERTY_MANAGER: 'property_manager',
  PROPERTY_MANAGERS_V2: 'property-managers',
  INVITATION: 'invitation',
  PROPERTIES: 'properties',
  ASSIGNED: 'assigned',
  UNASSIGNED: 'unassigned',
  UNASSIGN: 'unassign',
  ASSIGN: 'assign',
  RESEND: 'resend',
};

export const leasePacketEndpoints = {
  LEASE_PACKET: 'lease-packet',
  ADDENDUM: 'addendum',
};

export const residentsEndpoints = {
  RESIDENCE: 'residence',
  ANIMAL: 'animal',
  ANIMALS: 'animals',
  METADATA: 'metadata',
  TERMINATE: 'terminate',
  DASHBOARD: 'dashboard',
  DNA: 'dna',
  COLLECT: 'collect',
  UN_COLLECT: 'uncollect',
};

export const impersonationEndpoints = {
  PROFILE: 'profile',
  IMPERSONATION: 'impersonation',
};

export const resManEndpoints = {
  RESMAN: 'resman',
  UNLINK: 'unlink',
  LINK: 'link',
  MISSINSTATES: 'missing-states',
  PROPERTIES: 'properties',
  RESYNC: 'resync',
  INTEGRATION: 'integration',
  SYNC: 'sync',
};

export const visitationEndpoints = {
  VISITATIONS: 'visitations',
  DOCUMENTS: 'documents',
  DOWNLOAD: 'download',
  APPROVE: 'approve',
  REJECT: 'reject',
};

export const animalEndpoints = {
  ANIMALS: 'animals',
  SPECIES: 'species',
  BREEDS: 'breeds',
  COLORS: 'colors',
};
