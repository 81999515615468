import styled from 'styled-components';
import { Countdown } from '@landlord-tech/ui-kit-opp/dist/components/Countdown';
import React from 'react';

const SCountdown = styled((props) => <Countdown {...props} />)`
  && {
  }
`;

export default SCountdown;
