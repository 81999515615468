import { FC } from 'react';
import styled from 'styled-components';
import { Checkbox } from '@landlord-tech/ui-kit-opp/dist/components/Checkbox';

import { TCheckboxProps } from './Checbox.types';

const SCheckbox = styled<FC<TCheckboxProps>>(Checkbox)`
  &&& {
  }
`;

export default SCheckbox;
