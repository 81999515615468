import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import List from '@material-ui/core/List';
import MUIDrawer from '@material-ui/core/Drawer';
import Tooltip from '@material-ui/core/Tooltip';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import theme from 'components/theme';

const tabSpanMixin = {
  flexDirection: 'row',
  justifyContent: 'start',
  width: '219px',
  borderRadius: '4px',
  padding: '16px 30px 16px 15px',
  marginLeft: 'auto',
  alignItems: 'center',

  '@media(max-width:1024px)': {
    width: '100%',
  },
};

const tabMixin = {
  paddingTop: 0,
  paddingBottom: 0,
  minHeight: 0,

  '@media(max-width:1024px)': {
    maxWidth: 'unset',
    marginLeft: '24px',
    marginBottom: '14px',
    width: 'calc(100% - 48px)',
    height: '56px',
    borderRadius: '8px',
    padding: 0,
  },
};

const tabsActive = {
  marginLeft: '16px',
  paddingTop: 0,
  paddingBottom: 0,
  minHeight: 0,
  marginBottom: '20px',
  '@media(max-width:1024px)': {
    marginBottom: '16px',
    marginLeft: '24px',
    width: 'calc(100% - 48px)',
    maxWidth: 'unset',
    padding: 0,
    borderRadius: 8,
  },
};

export const useStyles = makeStyles({
  indicator: {
    left: '0px',
    width: '4px',
    height: '60px !important',

    '@media(max-width:1024px)': {
      display: 'none',
    },
  },
  root: {
    '&.dashboard-tabs_active': {
      ...tabsActive,
      '&.simple-tab': {
        '@media(max-width:1024px)': {
          margin: '0 63px',

          width: 'calc(100% - 126px)',
        },
      },
    },
    '&.dashboard-tabs_active span': {
      ...tabSpanMixin,
      backgroundColor: 'black',
      color: 'white',
      width: '200px',

      '@media(max-width:1024px)': {
        width: '100%',
        maxWidth: 'unset',
        borderRadius: 8,
        padding: '14px 30px 14px 15px',
      },
    },
    '&.dashboard-tabs_active svg path': {
      fill: 'white',
    },
    '& .MuiTab-wrapper': {
      textTransform: 'initial',
      position: 'relative',
      '& svg, img': {
        marginRight: '16px',
      },
    },
    '&.dashboard-tabs_active svg': {
      marginRight: '10px',
    },
    '&.dashboard-tabs': {
      ...tabMixin,

      '&.simple-tab': {
        '@media(max-width:1024px)': {
          width: 'calc(100% - 126px)',
          height: 'auto',
          margin: '16px 63px',
          padding: 0,

          '& span': {
            padding: '0 24px 0 16px',
            fontSize: 16,
          },
        },
      },
    },
    '&.dashboard-tabs span': {
      ...tabSpanMixin,
    },
    '&.dashboard-tabs svg': {
      color: '#fcc425',
    },
    '&.MuiTab-labelIcon': {
      minHeight: 0,
      paddingTop: 0,
    },
    '&.MuiTab-textColorInherit': {
      opacity: 1,
    },
  },
  hide: {
    display: 'none',
  },
  tabText: {
    fontFamily: 'aesthet-nova, serif',
    fontWeight: 500,
    position: 'absolute',
    left: '54px',
  },
  badge: {
    right: 84,
    top: 28,
    backgroundColor: '#ff8282',
    color: 'white',
    fontFamily: 'aesthet-nova',
  },
  headerIcons: {
    color: theme.primarycolor,
    display: 'flex',
    width: 48,
    height: 48,
  },
});

export const LogoWrapper = styled.div`
  margin: 16px 0 28px 32px;
  @media (max-width: 1024px) {
    margin: 0;
    height: 84px;
    padding: 12px 24px;
    max-width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background: #ffffff;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px 8px 0px 0px;
  }
`;

export const TabsWrapper = styled.div`
  height: auto;
  overflow-y: visible;
  @media (max-width: 1024px) {
    height: calc(100% - 152px - 16px);
    overflow-y: auto;
    padding-top: 16px;
  }
`;

export const BottomSection = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-family: ${theme.typography.secondaryFontFamily};

  border-top: 1px solid #fcc425;
  margin-top: 16px;
  padding-top: 16px;
`;

export const Drawer = styled(MUIDrawer)`
  width: 260px;
  flex-shrink: 0;
  overflow: visible;
  & .MuiPaper-root {
    overflow-y: visible;
    height: auto;
    position: sticky;
    z-index: 99;
  }
  @media (max-width: 1024px) {
    width: 100%;
    overflow: hidden;
    & .MuiPaper-root {
      overflow-y: visible;
      height: 100%;
      position: fixed;
      max-width: 100%;
    }
  }
`;

export const SList = styled(List)`
  &.MuiList-root {
    width: 200px;
    margin: auto;
    padding-left: 36px;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }
`;

export const SListItem = styled.li`
  padding-left: 12px;
  padding-right: 12px;
  &:not(:last-of-type) {
    margin-bottom: 24px;
  }
`;

export const StyledLink = styled(NavLink)`
  font-family: ${theme.typography.secondaryFontFamily};
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  &.${(props) => props.activeClassName} {
    border-bottom: 1px solid ${theme.palette.primary.main};
    padding-bottom: 4px;
  }
`;

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: `${theme.palette.secondary.main}`,
    color: `${theme.typography.color}`,
    fontSize: 14,
    borderRadius: 8,
    boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.12)',
    padding: '8px 16px',
  },
}))(Tooltip);

export const Avatar = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 16px;
`;

export const CloseIcon = styled.img`
  cursor: pointer;
  width: 24px;
  height: 24px;
`;

export const AccountWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  & h4 {
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1;
  }
  & div {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: calc(100% - 24px);
  }
`;
