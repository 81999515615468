import React, { forwardRef } from 'react';

import { SGroup, SInput, SPassword, STextArea } from './TextField.styles';
import { InputRef, TextAreaRef, TGroupProps, TInputProps, TPasswordProps, TTextAreaProps } from './TextField.types';

export const TextField = forwardRef<InputRef, TInputProps>((props: TInputProps, ref) => {
  return <SInput ref={ref} {...props} />;
});

export const Password = (props: TPasswordProps) => {
  return <SPassword {...props} />;
};

export const TextArea = forwardRef<TextAreaRef, TTextAreaProps>((props, ref) => {
  return <STextArea ref={ref} {...props} />;
});

export const Group = (props: TGroupProps) => {
  return <SGroup {...props} />;
};
