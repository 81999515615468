import type { TOptionTypeUi } from 'antd/components/EditableFields/EditableFields.types';
import {
  docHtmlConvertor,
  generatedPolicyDataConverter,
  getPolicyListConvertor,
} from 'antd/data/converters/policy.converters';
import { TPageableDataWithContentUi, TPageableDataUi } from 'antd/data/types/pagableData.type';
import { TDocHtmlUI, TGeneratedPolicyDataDB, TGeneratedPolicyDataUI, TPolicyGet } from 'antd/data/types/policy.types';
import { EPolicyUrls } from 'antd/data/urls/policy.url';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { authSplitApi } from '../helpers/slice.helpers';
import { EPropertyUrl } from '../../data/urls/property.url';

const baseUrl = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPolicyUrls.POLICY}`;

export const policyApi = authSplitApi('policy', ['policyOptions', 'genericPolicy', 'singlePolicy']).injectEndpoints({
  endpoints: (build) => ({
    getPolicyOptions: build.query<TOptionTypeUi[], TPageableDataUi>({
      query(params) {
        return {
          url: `${import.meta.env.REACT_APP_HOSTNAME}/api/v1/${EPolicyUrls.POLICY}`,
          method: 'GET',
          params: { ...params },
        };
      },
      transformResponse: (data: TPageableDataWithContentUi<TPolicyGet[]>) => {
        return data?.content?.length ? getPolicyListConvertor.fromDb(data.content) : [];
      },
      providesTags: ['policyOptions'],
    }),
    getGenericPolicy: build.query<TGeneratedPolicyDataUI, void>({
      query() {
        return {
          url: `${baseUrl}/${EPolicyUrls.GENERIC}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TGeneratedPolicyDataDB) => generatedPolicyDataConverter.fromDb(data),
      providesTags: ['genericPolicy'],
    }),
    generateGenericPolicy: build.mutation<TGeneratedPolicyDataUI, string>({
      query(policyId) {
        return {
          url: `${baseUrl}/${EPolicyUrls.GENERIC}`,
          method: 'PATCH',
          body: {
            policyId,
          },
        };
      },
      invalidatesTags: ['genericPolicy'],
    }),
    assignPolicy: build.mutation<TGeneratedPolicyDataUI, { propertyId: string; policyId: string }>({
      query({ policyId, propertyId }) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EPropertyUrl.PROPERTIES}/${EPolicyUrls.POLICY}`,
          method: 'PATCH',
          body: {
            policyId,
            propertyId,
          },
        };
      },
      invalidatesTags: ['singlePolicy'],
    }),
    getSinglePolicyAccessCodes: build.query<TGeneratedPolicyDataUI, string>({
      query(policyId) {
        return {
          url: `${baseUrl}/${policyId}/${EPolicyUrls.ACCESS_CODE}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TGeneratedPolicyDataDB) => generatedPolicyDataConverter.fromDb(data),
      providesTags: ['singlePolicy'],
    }),
    getDocHtml: build.query<TDocHtmlUI, { animalType: string; policyId: string }>({
      query({ animalType, policyId }) {
        return {
          url: `${baseUrl}/${EPolicyUrls.DOC}/${animalType}`,
          method: 'GET',
          responseHandler: 'text',
          params: { policyId },
        };
      },
      transformResponse: (data: string) => docHtmlConvertor.fromDb(data),
    }),
    getPolicyMetadata: build.query<any, void>({
      query() {
        return {
          url: `${import.meta.env.REACT_APP_HOSTNAME}/api/v1/${EPolicyUrls.RENTALS}/${EPolicyUrls.METADATA}`,
          method: 'GET',
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPolicyOptionsQuery,
  useGetDocHtmlQuery,
  useGenerateGenericPolicyMutation,
  useLazyGetGenericPolicyQuery,
  useGetPolicyMetadataQuery,
  useAssignPolicyMutation,
  useLazyGetSinglePolicyAccessCodesQuery,
} = policyApi;
