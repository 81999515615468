import cloneDeep from 'lodash/cloneDeep';
import { TFetchConverter } from 'antd/data/types/converter.types';
import { ICurrentAuthUser, TCurrentAuthUserUI } from 'antd/data/types/amplify.types';

export const currentAuthUserConvertor: TFetchConverter<TCurrentAuthUserUI, ICurrentAuthUser> = {
  fromDb: (data) => {
    const cognitoUser = cloneDeep(data);
    cognitoUser.username = data.username;
    cognitoUser.name = data.attributes?.name;
    if (data.attributes && 'email' in data.attributes) {
      cognitoUser.email = data.attributes?.email;
    }
    return {
      name: cognitoUser.name,
      challengeName: cognitoUser.challengeName,
      email: cognitoUser.email,
      username: cognitoUser.username,
    };
  },
};
