export enum EAnimalUrl {
  ANIMALS = 'animals',
  ANIMAL = 'animal',
  PET_CHARGING_INFO = 'pet-charging-info',
  EXPORT = 'export',
  FOLLOW_UP = 'follow-up',
  ITEMS = 'items',
  UPDATE = 'update',
  NAME = 'name',
  SPECIES = 'species',
  WEIGHT = 'weight',
  BIRTHDATE = 'birthdate',
  MICROCHIP = 'microchip',
  REPRODUCTIVE = 'reproductive',
  HOUSE_TRAINED = 'house-trained',
  GEDNER = 'gender',
  BREED = 'breed',
  AGGRESSIVE_BEHAVIOR = 'aggressive-behavior',
  TRAINED_FOR = 'trained-for',
  COLORS = 'colors',
  PHOTO = 'photo',
  ATTACH_VET_RECORD = 'attach-vet-record',
  VET_RECORD = 'vet-record',
  STATISTICS = 'statistics',
  DASHBOARD = 'dashboard',
  NOT_CHARGED_PETS = 'not-charged-pets',
  SCREENING = 'screening',
  CONDITIONAL_MOVED_IN = 'conditional-moved-in',
  APPROVE = 'approve',
}
