import { EUserUrls } from 'antd/data/urls/user.url';
import { TUserAdminDB, TUserPropertyManagerDB, TUserTypeDataDB } from 'antd/data/types/user.types';
import {
  userAdminConverter,
  userPropertyManagerConverter,
  userTypeDataConverter,
} from 'antd/data/converters/user.converters';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { EAdminUrl } from 'antd/data/urls/admin.url';
import { EAuthUrls } from 'antd/data/urls/auth.url';
import { BaseQuery, QueryReturnValue } from '../store';

export const getUserTypeDataService = async (baseQuery: BaseQuery) => {
  const userTypeData = (await baseQuery(
    `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EAuthUrls.AUTH}/${EAuthUrls.LOGIN}`
  )) as QueryReturnValue<TUserTypeDataDB>;
  return userTypeData.data ? userTypeDataConverter.fromDb(userTypeData.data) : null;
};

export const getAdminUserService = async (baseQuery: BaseQuery) => {
  const responseData = (await baseQuery(`${EAdminUrl.ADMIN}/${EUserUrls.PROFILE}`)) as QueryReturnValue<TUserAdminDB>;
  return responseData.data ? userAdminConverter.fromDb(responseData.data) : null;
};

export const getPropertyManagerUserService = async (baseQuery: BaseQuery) => {
  const responseData = (await baseQuery(
    `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EUserUrls.PROFILE}`
  )) as QueryReturnValue<TUserPropertyManagerDB>;
  return responseData.data ? userPropertyManagerConverter.fromDb(responseData.data) : null;
};

export const impersonateUser = async (baseQuery: BaseQuery, id?: string | null) => {
  const responseData = (await baseQuery({
    url: `${EAdminUrl.ADMIN}/${EUserUrls.PROFILE}/${EUserUrls.IMPERSONATION}`,
    method: 'POST',
    body: { userId: id },
  })) as QueryReturnValue<{ name: string; userId: string }>;
  return responseData.data ? responseData.data : null;
};
