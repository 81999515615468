import { formattedDate } from 'antd/helpers/utils';
import { TConvertor, TFetchConverter } from '../types/converter.types';
import {
  TVisitationAnimalDB,
  TVisitationAnimalUI,
  EVisitationStatusDB,
  EVisitationStatusUI,
  TDocsDB,
  TDocsUI,
  TAnimalVisitationDocsDB,
  TAnimalVisitationDocsUI,
  TTenantInfoDB,
  TTenantInfoUI,
  TVisistationAnimalsDB,
  TVisistationAnimalsUI,
} from '../types/visitation.types';
import { getDateDuration, getDateRange, statusChangeInfoConverter } from './general.converters';

export const visitiationStatusConverter: TConvertor<EVisitationStatusUI, EVisitationStatusDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EVisitationStatusDB]: EVisitationStatusUI } = {
      [EVisitationStatusDB.PENDING]: EVisitationStatusUI.PENDING,
      [EVisitationStatusDB.APPROVED]: EVisitationStatusUI.APPROVED,
      [EVisitationStatusDB.REJECTED]: EVisitationStatusUI.REJECTED,
      [EVisitationStatusDB.ARCHIVED]: EVisitationStatusUI.ARCHIVED,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EVisitationStatusUI]: EVisitationStatusDB } = {
      [EVisitationStatusUI.PENDING]: EVisitationStatusDB.PENDING,
      [EVisitationStatusUI.APPROVED]: EVisitationStatusDB.APPROVED,
      [EVisitationStatusUI.REJECTED]: EVisitationStatusDB.REJECTED,
      [EVisitationStatusUI.ARCHIVED]: EVisitationStatusDB.ARCHIVED,
    };
    return dataMapper[data];
  },
};
export const docsAnimalVisitationConverter: TFetchConverter<TAnimalVisitationDocsUI, TAnimalVisitationDocsDB> = {
  fromDb: (data) => {
    return {
      animalBreed: data.animalBreed,
      animalColors: data.animalColors,
      animalType: data.animalType,
      didUserConfirmLearningEfforts: data.didUserConfirmLearningEfforts,
      explanation: data.explanation,
      isServiceAnimal: data.isServiceAnimal,
      name: data.name,
      precautions: data.precautions,
      requiredBecauseOfDisability: data.requiredBecauseOfDisability,
      serviceAnimal: data.serviceAnimal,
      trainedFor: data.trainedFor,
      visitEndDate: data.visitEndDate,
      visitStartDate: data.visitStartDate,
      willBeLeftWithoutSupervision: data.willBeLeftWithoutSupervision,
      dateRange: getDateDuration(data.visitStartDate, data.visitEndDate),
    };
  },
};

export const visitationDocsConverter: TFetchConverter<TDocsUI, TDocsDB> = {
  fromDb: (data) => {
    return {
      animalVisitation: docsAnimalVisitationConverter.fromDb(data.ANIMAL_VISITATION),
    };
  },
};
export const visitationTenantConverter: TFetchConverter<TTenantInfoUI, TTenantInfoDB> = {
  fromDb: (data) => {
    return {
      tenantId: data.residenceId,
      tenantName: data.name,
      email: data.email,
      phone: data.phone,
      profilePicture: data.profilePicture,
    };
  },
};

export const getVisitationAnimalConverter: TFetchConverter<TVisitationAnimalUI, TVisitationAnimalDB> = {
  fromDb: (data) => {
    return {
      customProperty: data.customProperty,
      canApprove: data.canApprove,
      canReject: data.canReject,
      docs: visitationDocsConverter.fromDb(data.docs),
      petPhotoUrl: data.petPhotoUrl,
      status: visitiationStatusConverter.fromDb(data.status),
      tenantInfo: visitationTenantConverter.fromDb(data.tenantInfo),
      policyName: data.policyName,
      policyId: data.policyId,
      policyUrl: data.policyUrl,
      unitNumber: data.unitNumber,
      propertyName: data.propertyName,
      statusChangeInfo: data.statusChangeInfo,
      documentsTemplate: data.documentsTemplate,
      id: data.id,
      name: data.name,
      type: data.type,
      date: getDateRange(data.moveInDate, data.leaseToDate),
    };
  },
};

export const visitationAnimalsConverter: TFetchConverter<TVisistationAnimalsUI, TVisistationAnimalsDB> = {
  fromDb: (data) => {
    return {
      customProperty: data.isPropertyCustom,
      animalName: data.animalName,
      endDate: formattedDate(data.endDate),
      id: data.id,
      propertyName: data.propertyName,
      rejectReason: data.rejectReason,
      residentName: data.residentName,
      startDate: formattedDate(data.startDate),
      status: visitiationStatusConverter.fromDb(data.status),
      submissionDate: formattedDate(data.submissionDate),
      unitNumber: data.unitNumber,
      statusChangeInfo: data.rejectReason
        ? statusChangeInfoConverter.fromDb({
            by: data.statusChangerRole === 'ADMIN' ? 'OPP team' : data.statusChangerName,
            reason: data.rejectReason,
          })
        : undefined,
    };
  },
};
