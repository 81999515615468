import styled from 'styled-components';
import { Row } from 'antd/components/Grid';
import { Colors } from 'core/CssVariables';

const SAuthFormsWrapper = styled(Row)`
  max-width: 450px;
  width: 100%;
  .ant-statistic-content-value {
    color: ${Colors.ErrorColor};
  }
`;
export default SAuthFormsWrapper;
