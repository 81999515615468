import { getS3SignedUrl } from 'api/storage';
import { downloadFile } from 'utils';
import { userRoles } from './enums';

export const downloadGeneratedDoc = async (file) => {
  if (!file) return;

  const url = await getS3SignedUrl(file);
  const response = await fetch(url);
  const blob = await response.blob();
  downloadFile(blob, file.name);
};

export const convertPolicySampleUrls = (sampleUrls) => {
  return sampleUrls?.reduce((acc, item) => ({ ...acc, [item.docType]: item.preSignedUrl }), {});
};

export const isMoreThanTwoDecimalPlaces = (value) => {
  const priceDecimalPart = value.split('.')[1];
  return priceDecimalPart?.length > 2;
};

export const getHasLandlordPermissions = (profileType) => {
  return [userRoles.LANDLORD, userRoles.PROPERTY_MANAGER].includes(profileType);
};

export const handleFilterWithOther = (query, filter) => {
  const filterValues = query.get(filter.filterBy);

  if (filterValues?.includes('other')) {
    const newFilterValues = filterValues.split(',').filter((item) => item !== 'other');
    if (newFilterValues.length) {
      query.set(filter.filterBy, newFilterValues.join(','));
    } else {
      query.delete(filter.filterBy);
    }
    query.set(filter.filterByOther, 'true');
  }
};
