import { TExpirationDateType, TFileDataType, TFileDB, TFileUI } from './general.types';

export enum EVetRecordStatus {
  NONE = 'NONE',
  VERIFIED = 'VERIFIED',
}

export enum EVetRecordStatusUi {
  NONE = 'Not verified',
  VERIFIED = 'Verified',
}

export type TAnimalVetRecord = {
  documentFiles: TFileDataType[];
  id: string;
  records: TVetRecordDB[];
  locked: boolean;
  status: EVetRecordStatus;
};

export type TAnimalVetRecordUi = {
  documentFiles: TFileDataType[];
  id: string;
  records: TVetRecordUI[];
  status: EVetRecordStatusUi;
  locked: boolean;
};

export type TVetRecordDB = {
  id: string;
  type: TVetRecordTypeDB;
  expiration: TExpirationDateType | null;
  isExpired: boolean;
};
export type TVetRecordUI = {
  id: string;
  type: TVetRecordTypeUI;
  expiration: TExpirationDateType | null;
  isExpired: boolean;
};

export type TVetRecordTypeDB = {
  predefined?: string;
  custom?: string;
  value: string;
};
export type TVetRecordTypeUI = {
  predefined?: string;
  custom?: string;
  value: string;
};
