import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TAppSliceState = {
  isUploadLoading: boolean;
  cardholder: string;
  currentNotificationUrl: string;
};

const initialState: TAppSliceState = {
  isUploadLoading: false,
  cardholder: '',
  currentNotificationUrl: '',
};

const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    setIsUploadLoading(state, action: PayloadAction<boolean>) {
      // eslint-disable-next-line no-param-reassign
      state.isUploadLoading = action.payload;
    },
    setCardholder(state, action: PayloadAction<string>) {
      // eslint-disable-next-line no-param-reassign
      state.cardholder = action.payload;
    },
    setCurrentNotificationUrl(state, action: PayloadAction<string>) {
      // eslint-disable-next-line no-param-reassign
      state.currentNotificationUrl = action.payload;
    },
  },
});

export default appSlice;
