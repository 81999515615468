import { TConvertor } from 'antd/data/types/converter.types';
import { ENotificationDB, ENotificationUI } from 'antd/data/types/notifications.types';

export const notificationConverter: TConvertor<ENotificationUI, ENotificationDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in ENotificationDB]: ENotificationUI } = {
      [ENotificationDB.VERIFIED_ANIMAL_PENDING_APPLICATIONS_REPORT]:
        ENotificationUI.VERIFIED_ANIMAL_PENDING_APPLICATIONS_REPORT,
      [ENotificationDB.RECENTLY_MOVED_ANIMALS]: ENotificationUI.RECENTLY_MOVED_ANIMALS,
      [ENotificationDB.DNA_NOT_COLLECTED_ANIMALS]: ENotificationUI.DNA_NOT_COLLECTED_ANIMALS,
      [ENotificationDB.TENANT_RESIDENCY_APPLICATION_CREATED]: ENotificationUI.TENANT_RESIDENCY_APPLICATION_CREATED,
      [ENotificationDB.ANIMAL_APPLICATION_CREATED]: ENotificationUI.ANIMAL_APPLICATION_CREATED,
      [ENotificationDB.ANIMAL_APPLICATION_APPROVED]: ENotificationUI.ANIMAL_APPLICATION_APPROVED,
      [ENotificationDB.ANIMAL_APPLICATION_REJECTED]: ENotificationUI.ANIMAL_APPLICATION_REJECTED,
      [ENotificationDB.ANIMAL_APPLICATION_MISSING_DATA_PROVIDED]:
        ENotificationUI.ANIMAL_APPLICATION_MISSING_DATA_PROVIDED,
      [ENotificationDB.PENDING_ANIMAL_APPLICATION_WITH_MISSING_DATA]:
        ENotificationUI.PENDING_ANIMAL_APPLICATION_WITH_MISSING_DATA,
      [ENotificationDB.MOVE_IN_NOT_APPROVED_ANIMALS]: ENotificationUI.MOVE_IN_NOT_APPROVED_ANIMALS,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in ENotificationUI]: ENotificationDB } = {
      [ENotificationUI.VERIFIED_ANIMAL_PENDING_APPLICATIONS_REPORT]:
        ENotificationDB.VERIFIED_ANIMAL_PENDING_APPLICATIONS_REPORT,
      [ENotificationUI.RECENTLY_MOVED_ANIMALS]: ENotificationDB.RECENTLY_MOVED_ANIMALS,
      [ENotificationUI.DNA_NOT_COLLECTED_ANIMALS]: ENotificationDB.DNA_NOT_COLLECTED_ANIMALS,
      [ENotificationUI.TENANT_RESIDENCY_APPLICATION_CREATED]: ENotificationDB.TENANT_RESIDENCY_APPLICATION_CREATED,
      [ENotificationUI.ANIMAL_APPLICATION_CREATED]: ENotificationDB.ANIMAL_APPLICATION_CREATED,
      [ENotificationUI.ANIMAL_APPLICATION_APPROVED]: ENotificationDB.ANIMAL_APPLICATION_APPROVED,
      [ENotificationUI.ANIMAL_APPLICATION_REJECTED]: ENotificationDB.ANIMAL_APPLICATION_REJECTED,
      [ENotificationUI.ANIMAL_APPLICATION_MISSING_DATA_PROVIDED]:
        ENotificationDB.ANIMAL_APPLICATION_MISSING_DATA_PROVIDED,
      [ENotificationUI.PENDING_ANIMAL_APPLICATION_WITH_MISSING_DATA]:
        ENotificationDB.PENDING_ANIMAL_APPLICATION_WITH_MISSING_DATA,
      [ENotificationUI.MOVE_IN_NOT_APPROVED_ANIMALS]: ENotificationDB.MOVE_IN_NOT_APPROVED_ANIMALS,
    };
    return dataMapper[data];
  },
};
