import { TMultiOptionTypeMaterialUI } from './general.types';

export enum EMaintainerStatusUI {
  INVITED = 'Pending',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export enum EMaintainerStatusDB {
  INVITED = 'Invited',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}

export type TMaintainerUI = {
  id: string;
  fullName: string;
  phone: string;
  photoUrl: string | null;
  propertyCount: number;
  status: EMaintainerStatusUI;
  communicateWithResidents: boolean;
};
export type TMaintainerDB = {
  maintainerId: string;
  fullName: string;
  phone: string;
  photoUrl: string | null;
  status: EMaintainerStatusDB;
  propertyCount: number;
  communicateWithResidents: boolean;
};

export type TMaintainerDetailsUI = {
  id: string;
  fullName: string;
  phone: string;
  photoUrl: string | null;
  status: EMaintainerStatusUI;
  communicateWithResidents: boolean;
};
export type TMaintainerDetailsDB = {
  maintainerId: string;
  fullName: string;
  phone: string;
  photoUrl: string | null;
  status: EMaintainerStatusDB;
  communicateWithResidents: boolean;
};

export type TAssignedPropertyUI = {
  id: string;
  name: string;
  address1: string;
  type: string;
  unitCount: number;
  photoUrl?: string;
};
export type TAssignedPropertyDB = {
  id: string;
  name: string;
  address1: string;
  type: string;
  unitCount: number;
  photoUrl?: string;
};

export type TUpdateCommunicationUI = {
  maintainerId: string;
  communicateWithResidents: boolean;
};
export type TUpdateCommunicationDB = TUpdateCommunicationUI;

type TProperty = {
  resourceId: string;
};

export type TInviteMaintainerUI = {
  fullName: string;
  phoneNumber: string;
  reachTenants: boolean;
  properties: TMultiOptionTypeMaterialUI[];
};
export type TInviteMaintainerDB = {
  maintainerName: string;
  phone: string;
  communicateWithResidents: boolean;
  properties: string[];
};

export type TAssignPropertiesUI = {
  maintainerId: string;
  properties: string[];
};
export type TAssignPropertiesDB = {
  maintainerId: string;
  properties: string[];
};
