export enum EComplaintUrl {
  COMPLAINTS = 'complaints',
  NOTICES = 'notices',
  RESIDENTS = 'residents',
  ATTACHMENT = 'attachment',
  COMMENT = 'comment',
  ASSIGN = 'assign',
  REPORTERS = 'reporters',
  VIEW = 'view',
}
