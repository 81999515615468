import React, { FC } from 'react';
import { Form as UiKitForm, FormItem, FormList } from '@landlord-tech/ui-kit-opp/dist/components/Form';
import { validationRules, regexPatterns } from './validationRules';
import { TFormFieldOptions, TFormProps } from './Form.types';
import type { TFormInstance } from './Form.types';

const Form: FC<TFormProps> = ({ scrollToFirstError, ...props }) => {
  return <UiKitForm scrollToFirstError={scrollToFirstError && { behavior: 'smooth' }} {...props} />;
};

export default Form;
export { FormItem, FormList, validationRules, regexPatterns };
export type { TFormProps, TFormInstance, TFormFieldOptions };
