import { TConvertor, TCreateConverter, TFetchConverter } from 'antd/data/types/converter.types';
import {
  EUserRolesDB,
  EUserRolesUI,
  THubspotIntegrationDB,
  THubspotIntegrationUI,
  TImpersonatedOnDB,
  TImpersonatedOnUI,
  TUserAdminDB,
  TUserAdminUI,
  TUserPropertyManagerDB,
  TUserPropertyManagerUI,
  TUserTypeDataDB,
  TUserTypeDataUI,
} from 'antd/data/types/user.types';
import { yesNoOptionConverter } from './general.converters';

export const userRolesConverter: TConvertor<EUserRolesUI, EUserRolesDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EUserRolesDB]: EUserRolesUI } = {
      [EUserRolesDB.ADMIN]: EUserRolesUI.ADMIN,
      [EUserRolesDB.LANDLORD]: EUserRolesUI.LANDLORD,
      [EUserRolesDB.PROPERTY_MANAGER]: EUserRolesUI.PROPERTY_MANAGER,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EUserRolesUI]: EUserRolesDB } = {
      [EUserRolesUI.ADMIN]: EUserRolesDB.ADMIN,
      [EUserRolesUI.LANDLORD]: EUserRolesDB.LANDLORD,
      [EUserRolesUI.PROPERTY_MANAGER]: EUserRolesDB.PROPERTY_MANAGER,
    };
    return dataMapper[data];
  },
};
export const impersonationOnConverter: TFetchConverter<TImpersonatedOnUI, TImpersonatedOnDB> = {
  fromDb: ({ userId, name }) => {
    return {
      userId,
      name,
    };
  },
};
export const userAdminConverter: TFetchConverter<TUserAdminUI, TUserAdminDB> = {
  fromDb: ({ userId, username, email, name, phone, impersonateOn }) => {
    return {
      id: userId,
      name,
      username,
      email,
      phone,
      impersonateOn: impersonateOn ? impersonationOnConverter.fromDb(impersonateOn) : null,
    };
  },
};
export const userPropertyManagerConverter: TFetchConverter<TUserPropertyManagerUI, TUserPropertyManagerDB> = {
  fromDb: (data) => {
    return {
      type: userRolesConverter.fromDb(data.type),
      id: data.id,
      landlordId: data.landlordId,
      name: data.name,
      username: data.username,
      email: data.email,
      phone: data.phone,
      profilePicture: data.profilePicture,
      position: data.position,
      propertyManagementId: data.propertyManagementId,
      propertyManagementName: data.propertyManagementName,
      propertyManagementLogo: data.propertyManagementLogo,
      canCreateContract: data.canCreateContract,
      hasAccessToCourses: data.hasAccessToCourses,
    };
  },
};

export const userTypeDataConverter: TFetchConverter<TUserTypeDataUI, TUserTypeDataDB> = {
  fromDb: ({ id, name, photo, type }) => {
    return {
      id,
      name,
      photo,
      type: userRolesConverter.fromDb(type),
    };
  },
};

export const hubspotEnableConverter: TCreateConverter<THubspotIntegrationUI, THubspotIntegrationDB> = {
  toDb: (data) => {
    return {
      propertyManagerId: data.propertyManagerId,
      needToPushToHubSpot: yesNoOptionConverter.toDb(data.needToPushToHubSpot) as boolean,
    };
  },
};
