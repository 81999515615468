import { TFetchConverter } from '../types/converter.types';
import { TRentalMetadataDB, TRentalMetadataUI } from '../types/rentals.types';

export const rentalMetadataConverter: TFetchConverter<TRentalMetadataUI, TRentalMetadataDB> = {
  fromDb: (data) => {
    return {
      defaultPolicyId: data.defaultPolicyId,
      defaultPolicyName: data.defaultPolicyName,
      id: data.id,
      name: data.name,
      resourceId: data.resourceId,
      state: data.state,
      units: data.units,
    };
  },
};
