import { RoutesNames } from 'routes/RoutesNames';
import { EVerificationStatus } from 'antd/data/types/generalTypes';
import { EUserRolesUI } from 'antd/data/types/user.types';
import { ENotificationUI } from 'antd/data/types/notifications.types';

export type TNotification = { private: boolean; roles: EUserRolesUI[]; url: string };
export const notificationsMapper: {
  [key in ENotificationUI]: TNotification;
} = {
  [ENotificationUI.VERIFIED_ANIMAL_PENDING_APPLICATIONS_REPORT]: {
    private: true,
    roles: [EUserRolesUI.LANDLORD, EUserRolesUI.PROPERTY_MANAGER],
    url: `${RoutesNames.ApplicationsPage}/?animalVerificationStatus=${EVerificationStatus.VERIFIED}&statuses=${EVerificationStatus.PENDING}`,
  },
  [ENotificationUI.RECENTLY_MOVED_ANIMALS]: {
    private: true,
    roles: [EUserRolesUI.LANDLORD, EUserRolesUI.PROPERTY_MANAGER],
    url: `${RoutesNames.AnimalsMovedOut}`,
  },
  [ENotificationUI.DNA_NOT_COLLECTED_ANIMALS]: {
    private: true,
    roles: [EUserRolesUI.LANDLORD, EUserRolesUI.PROPERTY_MANAGER],
    url: `${RoutesNames.AnimalsCurrent}?dnaEnabled=true&dnaCollectionStatuses=NOT_COLLECTED`,
  },
  [ENotificationUI.TENANT_RESIDENCY_APPLICATION_CREATED]: {
    private: true,
    roles: [EUserRolesUI.LANDLORD, EUserRolesUI.PROPERTY_MANAGER],
    url: `${RoutesNames.Applicants}`,
  },
  [ENotificationUI.ANIMAL_APPLICATION_CREATED]: {
    private: true,
    roles: [EUserRolesUI.LANDLORD, EUserRolesUI.PROPERTY_MANAGER],
    url: `${RoutesNames.AnimalsApplicant}`,
  },
  [ENotificationUI.ANIMAL_APPLICATION_APPROVED]: {
    private: true,
    roles: [EUserRolesUI.LANDLORD, EUserRolesUI.PROPERTY_MANAGER],
    url: `${RoutesNames.AnimalsApplicant}`,
  },
  [ENotificationUI.ANIMAL_APPLICATION_REJECTED]: {
    private: true,
    roles: [EUserRolesUI.LANDLORD, EUserRolesUI.PROPERTY_MANAGER],
    url: `${RoutesNames.AnimalsApplicant}`,
  },
  [ENotificationUI.ANIMAL_APPLICATION_MISSING_DATA_PROVIDED]: {
    private: true,
    roles: [EUserRolesUI.LANDLORD, EUserRolesUI.PROPERTY_MANAGER],
    url: `${RoutesNames.AnimalsApplicant}`,
  },
  [ENotificationUI.PENDING_ANIMAL_APPLICATION_WITH_MISSING_DATA]: {
    private: true,
    roles: [EUserRolesUI.LANDLORD, EUserRolesUI.PROPERTY_MANAGER],
    url: `${RoutesNames.AnimalsApplicant}?statuses=PENDING`,
  },
  [ENotificationUI.MOVE_IN_NOT_APPROVED_ANIMALS]: {
    private: true,
    roles: [EUserRolesUI.LANDLORD, EUserRolesUI.PROPERTY_MANAGER],
    url: `${RoutesNames.AnimalsCurrent}?nonApprovedAnimalApplication=true`,
  },
};
