import { EDocPacketUrls } from 'antd/data/urls/docPacket.url';
import {
  ETemplateTypeUI,
  TDocPacketInfoUI,
  TDocPacketUI,
  TEmailUpdateUI,
  TFaxUpdateUI,
  THPLetterUpdateUI,
  THPVisitationUpdateUI,
  TNameUpdateUI,
  TPhoneUpdateUI,
} from 'antd/data/types/document.types';
import { TFileUI } from 'antd/data/types/general.types';
import {
  createHPDocConvertor,
  createHPDocInfoConvertor,
  hpEmailUpdateConvertor,
  hpFaxUpdateConvertor,
  hpLetterUpdateConvertor,
  hpNameUpdateConvertor,
  hpPhoneUpdateConvertor,
  hpVisitationUpdateConvertor,
  templateTypeConvertor,
} from 'antd/data/converters/docPacket.convertors';
import { EResidenceUrl } from 'antd/data/urls/residence.url';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { authSplitApi } from '../helpers/slice.helpers';

const baseUrl = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.RESIDENCE}/${EDocPacketUrls.HP_DOCUMENT_PACKET}`;

export const docPacketApi = authSplitApi('docPacket', ['docPacket', 'docPackets']).injectEndpoints({
  endpoints: (build) => ({
    createHPDocPacket: build.mutation<string, TDocPacketUI>({
      query(body) {
        return {
          url: baseUrl,
          method: 'POST',
          body: createHPDocConvertor.toDb(body),
        };
      },
      invalidatesTags: ['docPackets'],
    }),

    updateHPName: build.mutation<void, TNameUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EDocPacketUrls.HP_CONTACT}/${EDocPacketUrls.NAME}`,
          method: 'PATCH',
          body: hpNameUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['docPackets'],
    }),
    updateHPPhone: build.mutation<void, TPhoneUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EDocPacketUrls.HP_CONTACT}/${EDocPacketUrls.PHONE}`,
          method: 'PATCH',
          body: hpPhoneUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['docPackets'],
    }),
    updateHPEmail: build.mutation<void, TEmailUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EDocPacketUrls.HP_CONTACT}/${EDocPacketUrls.EMAIL}`,
          method: 'PATCH',
          body: hpEmailUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['docPackets'],
    }),
    updateHPFax: build.mutation<void, TFaxUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EDocPacketUrls.HP_CONTACT}/${EDocPacketUrls.FAX_NUMBER}`,
          method: 'PATCH',
          body: hpFaxUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['docPackets'],
    }),
    updateHPVisitation: build.mutation<void, THPVisitationUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EDocPacketUrls.HP_VISITATION}`,
          method: 'PATCH',
          body: hpVisitationUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['docPackets'],
    }),
    updateHPLetter: build.mutation<void, THPLetterUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EDocPacketUrls.HP_LETTER}`,
          method: 'PATCH',
          body: hpLetterUpdateConvertor.toDb(data),
        };
      },
      invalidatesTags: ['docPackets'],
    }),
    deleteHPDocPacket: build.mutation<void, { documentPacketId: string; tenantResidencyId: string }>({
      query(body) {
        return {
          url: `${baseUrl}`,
          method: 'DELETE',
          body,
        };
      },
      invalidatesTags: ['docPackets'],
    }),
    getTemplate: build.mutation<TFileUI, ETemplateTypeUI>({
      query(templateType) {
        return {
          url: `${EDocPacketUrls.TEMPLATES}`,
          method: 'GET',
          params: { docType: templateTypeConvertor.toDb(templateType) },
        };
      },
    }),
    addHPDocPacketInfo: build.mutation<void, TDocPacketInfoUI>({
      query(body) {
        return {
          url: baseUrl,
          method: 'PUT',
          body: createHPDocInfoConvertor.toDb(body),
        };
      },
      invalidatesTags: ['docPackets'],
    }),
  }),
});

export const {
  useCreateHPDocPacketMutation,
  useUpdateHPEmailMutation,
  useUpdateHPFaxMutation,
  useUpdateHPNameMutation,
  useUpdateHPPhoneMutation,
  useUpdateHPVisitationMutation,
  useDeleteHPDocPacketMutation,
  useUpdateHPLetterMutation,
  useGetTemplateMutation,
  useAddHPDocPacketInfoMutation,
} = docPacketApi;
