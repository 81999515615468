import moment, { Moment } from 'moment';
import { formattedDate, formattedDateTimezone } from 'antd/helpers/utils';
import { TConvertor, TFetchConverter } from 'antd/data/types/converter.types';
import {
  EApplicationStatus,
  EApplicationStatusUi,
  EFileTypeDB,
  EFileTypeUI,
  EGender,
  EGenderUi,
  EReproductive,
  TFileDB,
  TFileUI,
  TMultiOptionTypeMaterialUI,
  TOptionType,
  TOptionTypePredefinedDB,
  TOptionTypeUi,
  TPolicyDB,
  TPolicyUI,
  TPropertyDB,
  TPropertyUI,
  TStatusChangeInfoDB,
  TStatusChangeInfoUI,
  TStatusInfoWithSourceUI,
  TUnitDB,
  TUnitUI,
} from 'antd/data/types/general.types';
import { TQueryParams, TTablePageableDataUi } from 'antd/data/types/pagableData.type';
import { EVerificationStatus, EVerificationStatusUi, TYesNoOptionType } from 'antd/data/types/generalTypes';
import { hudGuidelinesLink } from '../datas/genericData';
import { TStatusInfoWithSourceDB } from '../types/animals.types';

export const getFormattedDateUI = (date: string, separator = '/') => {
  if (!date) return '-';

  return moment(new Date(date)).format(`MM${separator}DD${separator}yyyy`);
};

export const fileTypeConverter: TConvertor<EFileTypeUI, EFileTypeDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EFileTypeDB]: EFileTypeUI } = {
      [EFileTypeDB.PHOTO]: EFileTypeUI.PHOTO,
      [EFileTypeDB.VIDEO]: EFileTypeUI.VIDEO,
      [EFileTypeDB.OTHER]: EFileTypeUI.OTHER,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EFileTypeUI]: EFileTypeDB } = {
      [EFileTypeUI.PHOTO]: EFileTypeDB.PHOTO,
      [EFileTypeUI.VIDEO]: EFileTypeDB.VIDEO,
      [EFileTypeUI.OTHER]: EFileTypeDB.OTHER,
    };
    return dataMapper[data];
  },
};

export const fileConverter: TConvertor<TFileUI, TFileDB> = {
  fromDb: (data) => {
    return {
      name: data.name,
      url: data.url,
      type: data.type ? fileTypeConverter.fromDb(data.type) : EFileTypeUI.OTHER,
    };
  },
  toDb: (data) => {
    return {
      name: data.name,
      url: data.url,
      ...(data.type && { type: fileTypeConverter.toDb(data.type) }),
    };
  },
};

export const selectOptionConverter: TConvertor<TOptionTypeUi, TOptionType> = {
  fromDb: (data) => {
    return {
      id: data.id || '',
      value: data.id || '',
      label: data.value || '',
      __isNew__: !data.id,
    };
  },
  toDb: (data) => {
    return {
      // eslint-disable-next-line no-underscore-dangle
      ...(data.__isNew__ ? { value: data.label } : { id: data.value }),
    };
  },
};

export const genderEnumConvertor: TConvertor<EGenderUi, EGender> = {
  fromDb: (data) => {
    return EGenderUi[EGender[data] as keyof typeof EGenderUi];
  },
  toDb: (data) => {
    const convertedData = data.toUpperCase() as keyof typeof EGenderUi;
    return EGender[EGenderUi[convertedData].toUpperCase() as keyof typeof EGender];
  },
};

export const statusChangeInfoConverter: TFetchConverter<TStatusChangeInfoUI, TStatusChangeInfoDB> = {
  fromDb: (data) => {
    return {
      by: data?.by || 'N/A',
      reason: data?.reason?.replace(hudGuidelinesLink, 'document'),
      date: data?.date ? formattedDateTimezone(data.date, 'MM/DD/YYYY hh:mm A') : 'N/A',
      dueDate: data?.dueDate && formattedDateTimezone(data.dueDate, 'MM/DD/YYYY hh:mm A'),
    };
  },
};

export const applicationStatusConvertor: TConvertor<EApplicationStatusUi, EApplicationStatus> = {
  fromDb: (data) => {
    return EApplicationStatusUi[EApplicationStatus[data] as keyof typeof EApplicationStatusUi];
  },
  toDb: (data) => {
    const statuses: {
      [key in EApplicationStatusUi]: EApplicationStatus;
    } = {
      [EApplicationStatusUi.APPROVED]: EApplicationStatus.APPROVED,
      [EApplicationStatusUi.REJECTED]: EApplicationStatus.REJECTED,
      [EApplicationStatusUi.ARCHIVED]: EApplicationStatus.ARCHIVED,
      [EApplicationStatusUi.PENDING]: EApplicationStatus.PENDING,
    };
    return statuses[data];
  },
};

export const reproductiveConvertor: TFetchConverter<EReproductive, EGender> = {
  fromDb: (data) => {
    const reproduction: {
      [key in EGender]: EReproductive;
    } = {
      [EGender.FEMALE]: EReproductive.SPAYED,
      [EGender.MALE]: EReproductive.NEUTERED,
    };
    return reproduction[data];
  },
};

export const getFirstLetters = (data: string) => {
  const matches = data.match(/\b(\w)/g);
  const acronym = matches?.join('').toUpperCase() || '';
  return acronym;
};

export const queryParamConverter = (
  query: string,
  pagination?: TTablePageableDataUi,
  queryParams?: TQueryParams,
  oldVersion?: boolean
) => {
  const queryParam = new URLSearchParams(query);
  if (pagination?.page !== undefined) {
    queryParam.set('page', `${pagination.page}`);
  }
  if (pagination?.itemAmount !== undefined) {
    queryParam.set('size', `${pagination.itemAmount}`);
  }

  if (queryParams) {
    Object.keys(queryParams).forEach((key) => {
      if (!queryParam.get(key)) {
        queryParam.set(key, queryParams[key] as string);
      }
    });
  }
  const sort = queryParam.get('sort');
  if (!sort) return queryParam;
  if (!oldVersion) {
    const arr = sort.split(',');
    queryParam.delete('sort');
    queryParam.set('sortField', `${arr[0]}`);
    queryParam.set('sortDirection', `${arr[1].toUpperCase()}`);
  }
  return queryParam;
};

export const verificationStatusConverter: TFetchConverter<EVerificationStatusUi, EVerificationStatus> = {
  fromDb: (data) => {
    const statuses: {
      [key in EVerificationStatus]: EVerificationStatusUi;
    } = {
      [EVerificationStatus.NONE]: EVerificationStatusUi.NONE,
      [EVerificationStatus.VERIFIED]: EVerificationStatusUi.VERIFIED,
      [EVerificationStatus.PENDING]: EVerificationStatusUi.PENDING,
    };
    return statuses[data];
  },
};

export const policyDataConverter: TFetchConverter<TPolicyUI, TPolicyDB> = {
  fromDb: (data) => {
    return {
      policyId: data.policyId,
      policyName: data.policyName,
      policyState: data.policyState,
      signedBy: data.signedBy || '',
      dateSigned: data.dateSigned || '',
      isSigned: data.isSigned,
    };
  },
};

export const unitDataConverter: TFetchConverter<TUnitUI, TUnitDB> = {
  fromDb: (data) => {
    return {
      unitId: data.unitId,
      unitName: data.unitName,
    };
  },
};

export const propertyDataConverter: TFetchConverter<TPropertyUI, TPropertyDB> = {
  fromDb: (data) => {
    return {
      propertyId: data.propertyId,
      propertyName: data.propertyName,
    };
  },
};

export const predefinedOptionConverter: TFetchConverter<TOptionTypeUi, TOptionTypePredefinedDB> = {
  fromDb: (data) => {
    return {
      id: data.predefinedId,
      value: data.value,
      __isNew__: data.predefined,
      label: data.value,
    };
  },
};

export const materialOptionConverter: TFetchConverter<TMultiOptionTypeMaterialUI, TOptionType> = {
  fromDb: (data) => ({
    name: data?.value || data?.id || '',
    value: data?.id || '',
  }),
};
export const getDateInString = (days: number, hours: number) => {
  let durationInDays = '';
  let durationInHours = '';

  if (days > 1) {
    durationInDays = `${days} days`;
  } else if (days === 1) {
    durationInDays = `${days} day`;
  }
  if (hours > 1) {
    durationInHours = `${hours} hours`;
  } else if (hours === 1) {
    durationInHours = `${hours} hour`;
  }
  return { durationInDays, durationInHours };
};
export const getDateDuration = (startDate: number | string | Moment, endDate: number | string | Moment) => {
  const start = moment(startDate).format('MM/DD/YYYY HH:mm');
  const end = moment(endDate).format('MM/DD/YYYY HH:mm');
  const duration = moment(end).diff(moment(start));
  const hourCount = Math.floor(duration / (1000 * 60 * 60));
  const days = Math.floor(hourCount / 24);
  const leftHours = hourCount % 24;
  const { durationInDays, durationInHours } = getDateInString(days, leftHours);
  return { date: `${start} - ${end}`, durationInDays, durationInHours };
};
export const getDateRange = (startDate: number | string | Moment, endDate: number | string | Moment) => {
  const start = moment(startDate).format('L');
  const end = moment(endDate).format('L');
  return `${start}-${end}`;
};

export const predefinedStatusConverter: TFetchConverter<TStatusChangeInfoUI, TStatusChangeInfoDB> = {
  fromDb: (data) => {
    return {
      by: data.by,
      data: data.date,
      reason: data.reason?.replace(hudGuidelinesLink, 'document'),
    };
  },
};

export const yesNoOptionConverter: TConvertor<TYesNoOptionType, boolean | null | undefined> = {
  fromDb: (data) => {
    if (data) {
      return 'Yes';
    }
    if (data === false) {
      return 'No';
    }
    return null;
  },
  toDb: (data) => {
    if (!data) {
      return null;
    }
    return data === 'Yes';
  },
};
export const statusInfoWithResourceConverter: TFetchConverter<TStatusInfoWithSourceUI, TStatusInfoWithSourceDB> = {
  fromDb: (data) => {
    return {
      by: data.by,
      name: data.name,
      reason: data.reason,
      url: data.url,
      exportDate: data.exportDate ? formattedDate(data.exportDate, 'YYYY-MM-DD HH:mm') : undefined,
      date: data.date ? formattedDate(data.date, 'YYYY-MM-DD HH:mm') : undefined,
      moveOutDate: data.moveOutDate ? formattedDate(data.moveOutDate, 'YYYY-MM-DD') : undefined,
    };
  },
};

export const phoneNumberConverter: TConvertor<string | null | undefined, string | null> = {
  toDb: (phoneNumber) => (phoneNumber ? `${phoneNumber[0] === '+' ? '' : '+'}${phoneNumber}` : null),
  fromDb: (phoneNumber) => phoneNumber,
};
