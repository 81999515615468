import format from 'date-fns/format';
import getYear from 'date-fns/getYear';
import getMonth from 'date-fns/getMonth';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';

function convertUTCDateToLocalDate(date) {
  const newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  const offset = date.getTimezoneOffset() / 60;
  const hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function getFormattedTime(toUpdateDate) {
  const now = new Date().getTime();
  const backendDate = convertUTCDateToLocalDate(new Date(toUpdateDate));
  const diff = now - backendDate;

  const minutes = Math.floor(diff / (60 * 1000));
  const hours = Math.floor(diff / (60 * 60 * 1000));
  const days = Math.floor(diff / (24 * 60 * 60 * 1000));

  const date = new Date(toUpdateDate);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (dt < 10) {
    dt = `0${dt}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  const dateNotfy = `${month}/${dt}/${year}`;

  if (days === 1) {
    return 'yesterday';
  }
  if (days > 1) {
    return dateNotfy;
  }
  if (hours > 0) {
    return `${hours} hours ago`;
  }
  if (minutes > 0) {
    return `${minutes} mins ago`;
  }
  return 'just now';
}

export function getTomorrowDate() {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };

  return tomorrow.toLocaleDateString('fr-CA', options);
}

export function getTodayDate() {
  const today = new Date();
  today.setDate(today.getDate());

  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };

  return today.toLocaleDateString('fr-CA', options);
}

export function getInitials(string) {
  const names = string.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }
  return initials;
}

export function getNextDay(dateString) {
  const day = new Date(dateString);

  const nextDay = new Date(dateString);
  nextDay.setDate(day.getDate() + 1);

  return nextDay.toLocaleDateString('fr-CA');
}

export const getDateDiff = (startDate, startTime, endDate, endTime) => {
  const start = Date.parse(`${startDate}T${startTime}`);
  const end = Date.parse(`${endDate}T${endTime}`);

  // get total seconds between the times
  let delta = Math.abs(end - start) / 1000;

  // calculate (and subtract) whole days
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24;

  return [days, hours];
};

export const getAutocompleteOptions = (options, key) => {
  return options.map((option) => {
    return { [key]: option };
  });
};

export const getListsOfStrings = (objOfLists, innerKey) => {
  const newObj = {};

  Object.keys(objOfLists).forEach((key) => {
    newObj[key] = objOfLists[key].map((item) => item[innerKey]);
  });

  return newObj;
};

export const constructRequestParams = (filterParams) => {
  const { filters, sortBy } = filterParams;
  const params = {};

  if (filters) {
    Object.keys(filters).forEach((key) => {
      if (!filters[key] && typeof filters[key] !== 'boolean') {
        return;
      }
      params[key] = filters[key];
    });
  }
  if (sortBy) {
    params.sort = `${sortBy.name},${sortBy.order}`;
  }

  return params;
};

export const getFormattedDate = (date, separator = '/') => {
  if (!date) return null;

  return format(new Date(date), `MM${separator}dd${separator}yyyy`);
};

export const getFirstDayOfMonthWithoutTimezone = (date) => {
  const currentDate = new Date();

  return `${format(date || new Date(getYear(currentDate), getMonth(currentDate), 1), 'yyyy-MM-dd')}T00:00:00`;
};

export const getLastDayOfMonthWithoutTimezone = (date) => {
  return `${format(lastDayOfMonth(date || new Date()), 'yyyy-MM-dd')}T23:59:59`;
};

export const getDateWithoutTimezone = (date) => {
  return format(date, 'yyyy-MM-dd');
};
