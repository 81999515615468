import { TCreateConverter, TFetchConverter } from 'antd/data/types/converter.types';
import { formattedDate } from 'antd/helpers/utils';
import {
  TAddAttachmentDB,
  TAddAttachmentUI,
  TAddCommentDB,
  TAddCommentUI,
  TAssignComplaintDB,
  TAssignComplaintUI,
  TComplaintDB,
  TComplaintDetailsDB,
  TComplaintDetailsUI,
  TComplaintUI,
  TCreateComplaintDB,
  TCreateComplaintFromResidenceDB,
  TCreateComplaintFromResidenceUI,
  TCreateComplaintUI,
  TMainComplaintDB,
  TMainComplaintUI,
  TReporterDB,
  TReporterUI,
} from 'antd/data/types/complaint.types';
import { TCreatorDB, TCreatorUI } from 'antd/data/types/complaintGeneral.types';
import {
  complaintCommentsConvertor,
  complaintResidentConverter,
  complaintTitleConverter,
  complaintTypeConverter,
  complaintViolatorConvertor,
  creatorTypeConverter,
} from 'antd/data/converters/complaintGeneral.converters';
import { fileConverter, getFirstLetters } from './general.converters';

export const complaintCreatorConvertor: TFetchConverter<TCreatorUI, TCreatorDB> = {
  fromDb: (data) => {
    return {
      creatorId: data.reporterId,
      creatorName: data.reporterName,
      creatorPhoto: data.reporterPhoto,
      creatorType: creatorTypeConverter.fromDb(data.reporterProfileType),
      firstLetters: getFirstLetters(data.reporterName),
    };
  },
};

export const complaintGetConverter: TFetchConverter<TComplaintUI, TComplaintDB> = {
  fromDb: (data) => {
    return {
      id: data.id,
      creator: complaintCreatorConvertor.fromDb({
        reporterId: data.reporterId,
        reporterName: data.reporterName,
        reporterPhoto: data.reporterPhoto,
        reporterProfileType: data.reporterProfileType,
      }),
      resident: complaintResidentConverter.fromDb({ residenceId: data.residenceId, residentName: data.residentName }),
      propertyName: data.propertyName,
      unitName: data.unitName,
      type: complaintTypeConverter.fromDb(data.type),
      title: complaintTitleConverter.fromDb(data.title),
      commentsCount: data.commentsCount,
      createdAt: formattedDate(`${data.createdAt}Z`, 'MM/DD/YYYY hh:mm A'),
      lastUpdateDate: formattedDate(`${data.lastUpdateDate}Z`, 'MM/DD/YYYY hh:mm A'),
      isViewed: data.isViewed,
      viewed: data.viewed,
      hasUnreadComment: data.hasUnreadComment,
    };
  },
};

export const mainComplaintConvertor: TFetchConverter<TMainComplaintUI, TMainComplaintDB> = {
  fromDb: (data) => {
    if (!data) return null;
    return {
      id: data.noticeId,
      createdAt: formattedDate(`${data.createdAt}Z`, 'MM/DD/YYYY hh:mm A'),
      type: complaintTypeConverter.fromDb(data.type),
      title: complaintTitleConverter.fromDb(data.title),
      description: data.description,
      commentsCount: data.commentsCount,
      hasUnreadComment: data.hasUnreadComment,
    };
  },
};

export const complaintDetailsConvertor: TFetchConverter<TComplaintDetailsUI, TComplaintDetailsDB> = {
  fromDb: (complaintDetail) => ({
    id: complaintDetail.id,
    propertyId: complaintDetail.propertyId,
    conversationWith: complaintDetail.conversationWith,
    creator: complaintCreatorConvertor.fromDb({
      reporterId: complaintDetail.reporterId,
      reporterName: complaintDetail.reporterName,
      reporterPhoto: complaintDetail.reporterPhoto,
      reporterProfileType: complaintDetail.reporterProfileType,
    }),
    violator: complaintViolatorConvertor.fromDb({
      violatorPropertyId: complaintDetail.propertyId,
      violatorName: complaintDetail.violatorName,
      violatorPropertyName: complaintDetail.violatorPropertyName,
      violatorUnitName: complaintDetail.violatorUnitName,
    }),
    createdAt: formattedDate(`${complaintDetail.createdAt}Z`, 'MM/DD/YYYY hh:mm A'),
    type: complaintTypeConverter.fromDb(complaintDetail.type),
    title: complaintTitleConverter.fromDb(complaintDetail.title),
    description: complaintDetail.description,
    hasUnreadComment: complaintDetail.hasUnreadComment,
    attachments: complaintDetail.attachments.map((attachment) => fileConverter.fromDb(attachment)),
    comments: complaintCommentsConvertor.fromDb({
      commentsCount: complaintDetail.commentsCount,
      commentsList: complaintDetail.comments,
    }),
    viewed: complaintDetail.viewed,
    isDraft: complaintDetail.isDraft,
    mainNotice: mainComplaintConvertor.fromDb(complaintDetail.mainNotice),
  }),
};

export const complaintCreateConverter: TCreateConverter<TCreateComplaintUI, TCreateComplaintDB> = {
  toDb: (data) => {
    return {
      propertyId: data.property.value,
      residenceId: data.residence?.value,
      unitName: data.unit?.label,
      ...(data.noticeId && { noticeId: data.noticeId }),
      type: complaintTypeConverter.toDb(data.type),
      title: complaintTitleConverter.toDb(data.title),
      description: data.description || '',
      attachments: data?.attachments.map((item) => fileConverter.toDb(item)) || [],
    };
  },
};

export const complaintFromResidenceCreateConverter: TCreateConverter<
  TCreateComplaintFromResidenceUI,
  TCreateComplaintFromResidenceDB
> = {
  toDb: (data) => {
    return {
      residenceId: data.residenceId,
      type: complaintTypeConverter.toDb(data.type),
      title: complaintTitleConverter.toDb(data.title),
      description: data.description || '',
      attachments: data?.attachments.map((item) => fileConverter.toDb(item)) || [],
    };
  },
};

export const complaintAssingConverter: TCreateConverter<TAssignComplaintUI, TAssignComplaintDB> = {
  toDb: (data) => {
    return {
      residenceId: data.residence?.value,
      complaintId: data.complaintId,
      type: complaintTypeConverter.toDb(data.type),
      title: complaintTitleConverter.toDb(data.title),
      description: data.description,
    };
  },
};

export const addAttachmentConvertor: TCreateConverter<TAddAttachmentUI, TAddAttachmentDB> = {
  toDb: (data) => ({
    complaintId: data.complaintId,
    attachments: data.attachments.map((item) => fileConverter.toDb(item)) || [],
  }),
};
export const addCommentConvertor: TCreateConverter<TAddCommentUI, TAddCommentDB> = {
  toDb: (data) => ({
    complaintId: data.complaintId,
    message: data.comment,
  }),
};

export const reporterConvertor: TFetchConverter<TReporterUI, TReporterDB> = {
  fromDb: (data) => ({
    name: data.name,
    value: data.id,
  }),
};
