import { useCallback, useRef, useState } from 'react';
import get from 'lodash/get';
import useDidUpdate from '../../components/Hooks/useDidUpdate';
import { constructRequestParams } from '../../components/utils';
import { useStateSafely } from '../../components/Hooks/useStateSafely';

export const useApiResult = (requestHandler, onSuccess = () => {}, onError = () => {}) => {
  const [data, setData] = useStateSafely(null);
  const [error, setError] = useStateSafely(null);
  const [loading, setLoading] = useStateSafely(false);
  const [dependency, setDependency] = useStateSafely(null);
  const mountedRef = useRef(true);

  const refetch = useCallback((dep) => {
    let newDependency = { ...dep };

    if (get(dep, 'filters')) {
      newDependency = {
        params: {
          ...(dep.params || {}),
          ...constructRequestParams(dep.filters),
        },
      };
    }

    setDependency(newDependency);
  }, []);

  useDidUpdate(() => {
    (async () => {
      try {
        if (!mountedRef.current) return null;
        setLoading(true);
        const result = await requestHandler(dependency);
        setData(result);
        setError(null);
        onSuccess(result);
      } catch (err) {
        setError(err.message);
        onError(err);
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      mountedRef.current = false;
    };
  }, [dependency]);

  return { data, error, loading, refetch, setData };
};
