import { EAnimalTrainingUrls } from 'antd/data/urls/animalTraining.url';
import {
  createSADocPacketsConvertor,
  saLetterUpdateConvertor,
  trainerNameUpdateConvertor,
  trainerEmailUpdateConvertor,
  trainerPhoneUpdateConvertor,
  trainerFaxUpdateConvertor,
  trainedForUpdateConvertor,
  trainingStartDateUpdateConvertor,
  trainingEndDateUpdateConvertor,
} from 'antd/data/converters/animalTraining.convertors';
import {
  TSADocPacketCreateUI,
  TSAPacketDeleteParams,
  TSALetterUpdateUI,
  TTrainedForUpdateUI,
  TTrainingStartDateUpdateUI,
  TTrainingEndDateUpdateUI,
  TNameUpdateUI,
  TEmailUpdateUI,
  TPhoneUpdateUI,
  TFaxUpdateUI,
} from 'antd/data/types/animalTraining.types';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { EAnimalUrl } from 'antd/data/urls/animal.url';
import { authSplitApi } from '../helpers/slice.helpers';

const baseUrl = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EAnimalUrl.ANIMALS}/${EAnimalTrainingUrls.SA_DOCUMENTS}`;

export const animalTrainingApi = authSplitApi('animalTraining', ['saDocPacket', 'saDocPackets']).injectEndpoints({
  endpoints: (build) => ({
    createSADocPacket: build.mutation<void, TSADocPacketCreateUI>({
      query(body) {
        return {
          url: baseUrl,
          method: 'POST',
          body: createSADocPacketsConvertor.toDb(body),
        };
      },
    }),
    deleteSADocPacket: build.mutation<void, TSAPacketDeleteParams>({
      query(body) {
        return {
          url: `${baseUrl}`,
          method: 'DELETE',
          body,
        };
      },
    }),
    updateSALetters: build.mutation<void, TSALetterUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EAnimalTrainingUrls.SA_LETTERS}`,
          method: 'PATCH',
          body: saLetterUpdateConvertor.toDb(data),
        };
      },
    }),
    updateTrainerName: build.mutation<void, TNameUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EAnimalTrainingUrls.TRAINER_INFO}/${EAnimalTrainingUrls.NAME}`,
          method: 'PATCH',
          body: trainerNameUpdateConvertor.toDb(data),
        };
      },
    }),
    updateTrainerEmail: build.mutation<void, TEmailUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EAnimalTrainingUrls.TRAINER_INFO}/${EAnimalTrainingUrls.EMAIL}`,
          method: 'PATCH',
          body: trainerEmailUpdateConvertor.toDb(data),
        };
      },
    }),
    updateTrainerPhone: build.mutation<void, TPhoneUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EAnimalTrainingUrls.TRAINER_INFO}/${EAnimalTrainingUrls.PHONE}`,
          method: 'PATCH',
          body: trainerPhoneUpdateConvertor.toDb(data),
        };
      },
    }),
    updateTrainerFax: build.mutation<void, TFaxUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EAnimalTrainingUrls.TRAINER_INFO}/${EAnimalTrainingUrls.FAX}`,
          method: 'PATCH',
          body: trainerFaxUpdateConvertor.toDb(data),
        };
      },
    }),
    updateTrainedFor: build.mutation<void, TTrainedForUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EAnimalTrainingUrls.TRAINED_FOR}`,
          method: 'PATCH',
          body: trainedForUpdateConvertor.toDb(data),
        };
      },
    }),
    updateTrainingStartDate: build.mutation<void, TTrainingStartDateUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EAnimalTrainingUrls.TRAINING_START}`,
          method: 'PATCH',
          body: trainingStartDateUpdateConvertor.toDb(data),
        };
      },
    }),
    updateTrainingEndDate: build.mutation<void, TTrainingEndDateUpdateUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EAnimalTrainingUrls.TRAINING_END}`,
          method: 'PATCH',
          body: trainingEndDateUpdateConvertor.toDb(data),
        };
      },
    }),
  }),
});

export const {
  useCreateSADocPacketMutation,
  useDeleteSADocPacketMutation,
  useUpdateSALettersMutation,
  useUpdateTrainerNameMutation,
  useUpdateTrainerEmailMutation,
  useUpdateTrainerPhoneMutation,
  useUpdateTrainerFaxMutation,
  useUpdateTrainedForMutation,
  useUpdateTrainingStartDateMutation,
  useUpdateTrainingEndDateMutation,
} = animalTrainingApi;
