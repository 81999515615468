import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul {
  list-style-position: inside;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button {
  border: none;
  cursor: pointer;
}


::-webkit-scrollbar {
  width: 6px;
  height: 10px
}

::-webkit-scrollbar-track {
  margin-top: 18px;
  margin-bottom: 8px;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: #9E9E9E;
  border-radius: 8px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type="date"]::-webkit-calendar-picker-indicator {
  margin-left: -16px;
}

* {
  box-sizing: border-box;
  outline: none;
}
  a {
      text-decoration: none;
      color: inherit;
      cursor: pointer
  }

  .pointer {
    cursor: pointer;
  }

  .MuiInput-underline:before {
    border-color: #FCC425!important;
  }

  .MuiInput-underline.Mui-focused:after,
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid #FCC425!important;
  }

  .MuiSelect-select:focus {
    background-color: transparent!important;
  }

  
  .MuiInputLabel-root {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  .MuiToggleButton-label {
    svg {
      margin-right: 8px;
    }
  }
  
  .MuiToggleButtonGroup-vertical {
    width: 100%;
  }
  
  .MuiToggleButtonGroup-root {
    margin-bottom: 16px;
  }
  
  .MuiSvgIcon-root {
    margin-bottom: 0 !important;
  }

  

form.only-horizontal-spacing {
  & .MuiGrid-container {
    margin-top: 0;
    margin-bottom: 0;
  }
  & .MuiGrid-item {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.fit-content {
  width: fit-content;
}
`;
