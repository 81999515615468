import Amplify, { Auth } from 'aws-amplify';
import axios from '../axios';

import awsExports from '../aws-exports';
import { getNewUserExistsService, getOldUserExistsService } from './services/userExistServices';

Amplify.configure(awsExports);

const disallowedUserTypes = ['maintainer'];

function setUserInfo(user, userType, userData) {
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('userId', JSON.stringify(userData.id));
}

export async function signIn({ username, password }, handleOldUserExist) {
  const awsUser = await Auth.signIn(username, password);
  if (awsUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
    return { awsUser };
  }
  const type = awsUser.attributes['custom:userType'];

  if (disallowedUserTypes.includes(type)) {
    throw new Error('User does not exists.');
  }

  // Logic of new and old user check can be removed after all users are migrated
  const newUserExists = await getNewUserExistsService();

  if (newUserExists) {
    const requestedUser = await axios.get(`/user/signIn`);
    if (type !== 'tenant') {
      setUserInfo(awsUser, type, requestedUser.data);
    }
    return { awsUser, user: requestedUser.data };
  }

  const oldUserExists = await getOldUserExistsService();
  if (!oldUserExists) {
    return false;
  }

  handleOldUserExist();
}

export async function signOut() {
  await axios.get(`/user/signOut`);
  await Auth.signOut();
  // eslint-disable-next-line
  clearUserInfo();
}

export async function impersonate(user) {
  const { id, type } = user;
  const impersonateUser = await axios.post('/user/impersonate', {
    id,
    userType: type,
  });
  localStorage.setItem('impersonate', JSON.stringify(impersonateUser?.data || user));

  return impersonateUser?.data || user;
}

export async function unImpersonate() {
  await axios.delete('/user/impersonate');
  localStorage.removeItem('impersonate');
}

function clearUserInfo() {
  localStorage.removeItem('user');
  localStorage.removeItem('userId');
  localStorage.removeItem('impersonate');
  localStorage.removeItem('admin');
}
