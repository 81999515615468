export enum EApplicationUrls {
  APPLICATION = 'application',
  ANIMALS = 'animals',
  APPROVE = 'approve',
  REJECT = 'reject',
  DETAILS = 'details',
  VERIFY = 'verify',
  EXPORT = 'export',
  RETRACT_REJECTION = 'retract-rejection',
  MOVE_IN = 'move-in',
  RETRACT = 'retract',
  REAPPLY = 'reapply',
  SCREENING = 'screening',
  CONDITIONAL_APPROVED = 'conditional-approved',
  PENDING_APPROVAL = 'pending-approval',
}
