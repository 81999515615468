import { validationRules} from "antd/components/Form";
import { TSignUpFields } from "./Signup.types";

export const errorMessages = {
    emailExists: 'An account with the given email already exists.',
};

export const signUpFields:TSignUpFields = {
    email: {
        name: 'email',
        label: 'Email address',
        rules: [
            { required: true, message: 'Please enter your email address!' },
            { type: 'email', message: 'Incorrect Email Address!' },
        ],
        placeholder: 'Enter email address',
    },
    name: {
        name: 'fullName',
        label: 'Full name',
        rules: [
            { required: true, message: 'Please enter your full name!' },
        ],
        placeholder: 'e.g. John Doe',
    },
    businessName: {
        name: 'businessName',
        label: 'Business Name',
        rules: [
            { required: true, message: 'Please enter your business name!' },
        ],
        placeholder: 'e.g. John Doe',
    },
    phone: {
        name: 'phone',
        label: 'Phone number (optional)',
            rules: [
                validationRules.phoneNumber(),
            ],
            placeholder: 'Enter phone number',
    },
    password: {
        name: 'password',
        label: 'New password (Min. of 6 characters)',
        rules: [
            ...validationRules.password,
            validationRules.required('password'),
            validationRules.withoutSpace('Password'),
        ],
        placeholder: 'Enter password',
    },
    passwordConfirm: {
        name: 'passwordConfirm',
        label: 'Repeat the password',
        rules: [
            ...validationRules.passwordConfirm,
            validationRules.required('confirm password'),
        ],
        placeholder: 'Repeat the password',
    },
    terms: {
        name: 'terms',
        rules: [validationRules.required()],
    },
};