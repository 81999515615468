import { TResetPasswordEmailForm } from "./ResetPassword.types";

export const resetPasswordEmailForm:TResetPasswordEmailForm = {
    email: {
        name: 'email',
        label: 'Email address',
        rules: [
            { required: true, message: 'Please enter your email address!' },
            { type: 'email', message: 'Incorrect Email Address!' },
        ],
        placeholder: 'Enter email address',
    },
};
