import React from 'react';
import { Route } from 'react-router-dom';

import SidebarRoutes from './SidebarRoutes';

const GlobalRoutes = ({ component: Component, usersData, ...rest }) => {
  const { userType } = usersData || {};

  return (
    <Route
      {...rest}
      render={(props) => (
        <SidebarRoutes userType='landlord' isLoggedIn={!!userType}>
          <Component {...props} />
        </SidebarRoutes>
      )}
    />
  );
};

export default GlobalRoutes;
