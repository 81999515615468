import { authSplitApi } from 'antd/redux/helpers/slice.helpers';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import {
  EResidencyFilterStatusUI,
  EResidencyMoveInUI,
  TAddNewResidentUI,
  TAnimalsScreeningUI,
  TApplicantDetailsDB,
  TApplicantDetailsUI,
  TBulkScreeningUI,
  TCheckTenantDuplicationParamUI,
  TCollectDNAUi,
  TMoveOutToNoticeUi,
  TMoveOutToResidentUi,
  TResidenceDB,
  TResidenceFilterDB,
  TResidenceFilterUI,
  TResidenceMoveInfoUI,
  TResidenceUI,
  TResidencyApplicationApplyUI,
  TResidencyApplicationRejectUI,
  TResidentAnimalsDB,
  TResidentAnimalsUI,
  TResidentDetailsDB,
  TResidentDetailsUI,
  TResidentDuplicateDB,
  TResidentDuplicateUI,
  TResidentMoveUi,
  TResidentNoticeUi,
  TResidentsTableDB,
  TResidentsTableUI,
  TRetractAnimalDB,
  TRetractAnimalUI,
  TRevokeAnimalDB,
  TRevokeAnimalUI,
  TUnCollectDNAUi,
} from 'antd/data/types/residence.types';
import {
  collectDnaConverter,
  ResidentMoveConverter,
  residenceFilterConverter,
  residenceGetConverter,
  residenceMoveConverter,
  residentDetailsConverter,
  unCollectDnaConverter,
  applicantDetailsConverter,
  residencyApplicationApproveConverter,
  residencyApplicationRejectConverter,
  residenceFilterStatusesConverter,
  residentAnimalsConverter,
  residencyMoveInConverter,
  reApplyAnimalsConverter,
  revokeAnimalsConverter,
  residentsTableConverter,
  addNewResidentConverter,
  duplicateResidentConverter,
  animalsScreeningConverter,
  ResidentNoticeConverter,
  moveOutToResidentConverter,
  moveOutToNoticeConverter,
  bulkScreeningConvertor,
} from 'antd/data/converters/residence.converters';
import { EResidenceUrl } from 'antd/data/urls/residence.url';
import { queryParamConverter } from 'antd/data/converters/general.converters';
import {
  TPageableDataParamUI,
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'antd/data/types/pagableData.type';
import { downloadFile } from 'antd/api/sevices/fileServices';
import { pageableDataConverter } from 'antd/data/converters/pagableData.converters';
import { TRoommatesDB, TRoommatesUI } from 'antd/data/types/roommates.types';
import { roommatesConverter } from 'antd/data/converters/roommates.converters';

const URL = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.RESIDENCE}`;
const APPLICANT_URL = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EResidenceUrl.RESIDENCY}`;
export const residenceApi = authSplitApi('residenceApi', [
  'residence', // caching data get data tag names,
  'residentDetails',
  'applicantDetails',
  'csv',
  'residentsAnimals',
  'revokeDetails',
  'reapplyDetails',
  'residents',
]).injectEndpoints({
  endpoints: (build) => ({
    getResidencies: build.query<TResidenceUI[], { statuses: string[]; propertyIds?: string[]; unitIds?: string[] }>({
      query(params) {
        return {
          url: `${URL}/${EResidenceUrl.SEARCH}`,
          method: 'GET',
          params,
        };
      },
      transformResponse: (data: TResidenceDB[]) => {
        return data?.map((item) => residenceGetConverter.fromDb(item)) || [];
      },
      providesTags: ['residence'], // for caching and update cache
    }),
    getResidentDetails: build.query<TResidentDetailsUI, string>({
      query(id) {
        return {
          url: `${URL}/${EResidenceUrl.PROFILE}`,
          method: 'GET',
          params: { residenceId: id },
        };
      },
      transformResponse: (data: TResidentDetailsDB) => residentDetailsConverter.fromDb(data),
      providesTags: ['residentDetails'], // for caching and update cache
    }),
    getApplicantDetails: build.query<TApplicantDetailsUI, string>({
      query(id) {
        return {
          url: `${URL}/${EResidenceUrl.PROFILE}`,
          method: 'GET',
          params: { residenceId: id },
        };
      },
      transformResponse: (data: TApplicantDetailsDB) => applicantDetailsConverter.fromDb(data),
      providesTags: ['applicantDetails'], // for caching and update cache
    }),
    getResidenciesFilter: build.query<TResidenceFilterUI[], { statuses?: EResidencyFilterStatusUI[] } | void>({
      query(params) {
        return {
          url: `${URL}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.FILTER}/${EResidenceUrl.RESIDENCE}`,
          method: 'GET',
          ...(params && {
            params: {
              statuses: params?.statuses?.map((item) => residenceFilterStatusesConverter.toDb(item)),
            },
          }),
        };
      },
      transformResponse: (data: TResidenceFilterDB[]) => {
        return data?.map((item) => residenceFilterConverter.fromDb(item)) || [];
      },
    }),
    getResidenceMoveInfo: build.query<TResidenceMoveInfoUI, { residencyId?: string; propertyId?: string }>({
      query({ residencyId, propertyId }) {
        return {
          url: `${URL}/${residencyId}/${EResidenceUrl.MOVE}/${propertyId}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TResidenceMoveInfoUI) => residenceMoveConverter.fromDb(data),
    }),
    getApproveResidencyVerify: build.query<TResidenceMoveInfoUI, { residencyId?: string; propertyId?: string }>({
      query({ residencyId, propertyId }) {
        return {
          url: `${URL}/${EResidenceUrl.MOVE_IN}/${EResidenceUrl.VERIFY}`,
          method: 'GET',
          params: { tenantResidencyId: residencyId, propertyId },
        };
      },
      transformResponse: (data: TResidenceMoveInfoUI) => residenceMoveConverter.fromDb(data),
    }),
    residentMove: build.mutation<void, TResidentMoveUi>({
      query(body) {
        return {
          url: `${URL}/${EResidenceUrl.MOVE}`,
          method: 'PATCH',
          body: ResidentMoveConverter.toDb(body),
        };
      },
      invalidatesTags: ['residentDetails'],
    }),
    moveOutFromNotice: build.mutation<void, string>({
      query(id) {
        return {
          url: `${URL}/${EResidenceUrl.TERMINATE}`,
          method: 'PATCH',
          body: { residenceId: id },
        };
      },
      invalidatesTags: ['residentDetails', 'revokeDetails'],
    }),

    moveOutFromResident: build.mutation<void, string>({
      query(id) {
        return {
          url: `${URL}/${EResidenceUrl.TERMINATE_MOVED_IN_RESIDENCY}`,
          method: 'PATCH',
          body: { tenantResidencyId: id },
        };
      },
      invalidatesTags: ['residentDetails', 'revokeDetails'],
    }),

    residentApplicationApprove: build.mutation<string, TResidencyApplicationApplyUI>({
      query(body) {
        return {
          url: `${URL}/${EResidenceUrl.APPROVE}`,
          method: 'PATCH',
          body: residencyApplicationApproveConverter.toDb(body),
        };
      },
      invalidatesTags: ['residentDetails', 'applicantDetails', 'reapplyDetails', 'residentsAnimals'],
    }),
    residentApplicationReject: build.mutation<void, TResidencyApplicationRejectUI>({
      query(body) {
        return {
          url: `${APPLICANT_URL}/${EResidenceUrl.APPLICATION}/${EResidenceUrl.REJECT}`,
          method: 'PATCH',
          body: residencyApplicationRejectConverter.toDb(body),
        };
      },
      invalidatesTags: ['residentDetails', 'revokeDetails'],
    }),
    collectDNA: build.mutation<void, TCollectDNAUi>({
      query(body) {
        return {
          url: `${URL}/${EResidenceUrl.DNA}/${EResidenceUrl.COLLECT}`,
          method: 'PATCH',
          body: collectDnaConverter.toDb(body),
        };
      },
    }),
    unCollectDNA: build.mutation<void, TUnCollectDNAUi>({
      query(body) {
        return {
          url: `${URL}/${EResidenceUrl.DNA}/${EResidenceUrl.UN_COLLECT}`,
          method: 'PATCH',
          body: unCollectDnaConverter.toDb(body),
        };
      },
    }),
    exportCSV: build.query<string, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: `${URL}/${EResidenceUrl.EXPORT}`,
          method: 'GET',
          params: queryParamConverter(search, pagination),
          responseHandler: async (response) => {
            const file = await response.blob();
            downloadFile(file, 'residents.csv');
            return null;
          },
        };
      },
    }),
    checkTenantDuplication: build.query<TResidentDuplicateUI[], TCheckTenantDuplicationParamUI>({
      query(params) {
        return {
          url: `${URL}/${EResidenceUrl.DUPLICATIONS}`,
          method: 'GET',
          params: { ...params },
        };
      },
      transformResponse: (data: TResidentDuplicateDB[]) =>
        (data || []).map((duplicate) => duplicateResidentConverter.fromDb(duplicate)),
    }),
    getResidentAnimalDetails: build.query<TResidentAnimalsUI[], { id: string }>({
      query(params) {
        return {
          url: `${URL}/${EResidenceUrl.MOVE_IN}/${EResidenceUrl.DETAILS}`,
          method: 'GET',
          params: { tenantResidencyId: params.id },
        };
      },
      providesTags: ['residentsAnimals'],
      transformResponse: (data: { animals: TResidentAnimalsDB[] }) =>
        (data.animals || []).map((animal) => residentAnimalsConverter.fromDb(animal)),
    }),
    residentMoveIn: build.mutation<string, EResidencyMoveInUI>({
      query(body) {
        return {
          url: `${URL}/${EResidenceUrl.MOVE_IN}`,
          method: 'PATCH',
          body: residencyMoveInConverter.toDb(body),
        };
      },
      invalidatesTags: ['residentDetails'],
    }),
    cancelApplication: build.mutation<string, { residencyId: string; reason?: string }>({
      query(body) {
        return {
          url: `${URL}/${EResidenceUrl.RETRACT}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['residentDetails', 'reapplyDetails', 'residentsAnimals'],
    }),
    getReapplyDetails: build.query<TRetractAnimalUI[], { tenantResidencyId: string }>({
      query({ tenantResidencyId }) {
        return {
          url: `${URL}/${EResidenceUrl.REAPPLY}/${EResidenceUrl.DETAILS}`,
          method: 'GET',
          params: { tenantResidencyId },
        };
      },
      providesTags: ['reapplyDetails'],
      transformResponse: (data: { animals: TRetractAnimalDB[] }) =>
        (data.animals || []).map((animal) => reApplyAnimalsConverter.fromDb(animal)),
    }),
    reapplyTenant: build.mutation<string, { residencyId: string; reason?: string; animalIds: string[] }>({
      query(body) {
        return {
          url: `${URL}/${EResidenceUrl.REAPPLY}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['residentDetails', 'revokeDetails'],
    }),
    getRevokeDetails: build.query<TRevokeAnimalUI[], { tenantResidencyId: string }>({
      query({ tenantResidencyId }) {
        return {
          url: `${URL}/${EResidenceUrl.MOVED_OUT}/${EResidenceUrl.REVOKE}/${EResidenceUrl.DETAILS}`,
          method: 'GET',
          params: { tenantResidencyId },
        };
      },
      providesTags: ['revokeDetails'],
      transformResponse: (data: { animals: TRevokeAnimalDB[] }) =>
        (data.animals || []).map((animal) => revokeAnimalsConverter.fromDb(animal)),
    }),
    revokeMoveOutToNotice: build.mutation<string, TMoveOutToNoticeUi>({
      query(body) {
        return {
          url: `${URL}/${EResidenceUrl.MOVED_OUT}/${EResidenceUrl.REVOKE}`,
          method: 'PATCH',
          body: moveOutToNoticeConverter.toDb(body),
        };
      },
      invalidatesTags: ['residentDetails', 'residents'],
    }),
    cancelAnimalMoveOut: build.mutation<
      string,
      { tenantResidencyId: string; reason?: string; animalResidencyId: string }
    >({
      query(body) {
        return {
          url: `${URL}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.MOVED_OUT}/${EResidenceUrl.REVOKE}`,
          method: 'PATCH',
          body,
        };
      },
    }),
    cancelResidentMoveIn: build.mutation<string, { residencyId: string }>({
      query(body) {
        return {
          url: `${URL}/${EResidenceUrl.MOVE_IN}/${EResidenceUrl.REVOKE}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['residentDetails'],
    }),
    cancelAnimalMoveIn: build.mutation<string, { tenantResidencyId: string; animalResidencyId: string }>({
      query(body) {
        return {
          url: `${URL}/${EResidenceUrl.ANIMALS}/${EResidenceUrl.MOVE_IN}/${EResidenceUrl.REVOKE}`,
          method: 'PATCH',
          body,
        };
      },
    }),
    getResidents: build.query<
      TPageableDataWithContentUi<TResidentsTableUI[]>,
      TPageableDataParamUI & { properties?: string }
    >({
      query({ search, pagination }) {
        return {
          url: `${URL}`,
          method: 'GET',
          params: queryParamConverter(search, pagination, {}, true),
        };
      },
      transformResponse: (data: TPageableDataWithContent<TResidentsTableDB[]>) => {
        const { content, ...other } = data;
        return {
          content: data.content.map((item) => residentsTableConverter.fromDb(item)),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
      providesTags: ['residents'],
    }),
    addNewResident: build.mutation<void, TAddNewResidentUI>({
      query(body) {
        return {
          url: `${URL}`,
          method: 'POST',
          body: addNewResidentConverter.toDb(body),
        };
      },
      invalidatesTags: ['residents'],
    }),
    animalsScreening: build.mutation<void, TAnimalsScreeningUI>({
      query(body) {
        return {
          url: `${URL}/${EResidenceUrl.SCREENING}`,
          method: 'PATCH',
          body: animalsScreeningConverter.toDb(body),
        };
      },
      invalidatesTags: ['residents', 'residentDetails'],
    }),
    addNotice: build.mutation<void, TResidentNoticeUi>({
      query(body) {
        return {
          url: `${URL}/${EResidenceUrl.NOTICE}`,
          method: 'PATCH',
          body: ResidentNoticeConverter.toDb(body),
        };
      },
      invalidatesTags: ['residents', 'residentDetails'],
    }),
    revokeNotice: build.mutation<string, { tenantResidencyId: string }>({
      query(body) {
        return {
          url: `${URL}/${EResidenceUrl.NOTICE}/${EResidenceUrl.REVOKE}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: ['residents', 'residentDetails'],
    }),

    revokeMoveOutToResident: build.mutation<string, TMoveOutToResidentUi>({
      query(body) {
        return {
          url: `${URL}/${EResidenceUrl.MOVED_OUT}/${EResidenceUrl.REVOKE_TO_MOVE_IN}`,
          method: 'PATCH',
          body: moveOutToResidentConverter.toDb(body),
        };
      },
      invalidatesTags: ['residentDetails', 'residents'],
    }),
    bulkScreening: build.mutation<void, TBulkScreeningUI>({
      query(body) {
        return {
          url: `${URL}/${EResidenceUrl.SCREENING}/${EResidenceUrl.BULK}${body.search}`,
          method: 'PATCH',
          body: bulkScreeningConvertor.toDb(body),
        };
      },
      invalidatesTags: ['residents', 'residentDetails'],
    }),
    getLeaseDetails: build.query<TRoommatesUI, { id: string }>({
      query(params) {
        return {
          url: `${URL}/${EResidenceUrl.LEASE_DETAILS}`,
          method: 'GET',
          params: { tenantResidencyId: params.id },
        };
      },
      transformResponse: (data: TRoommatesDB) => roommatesConverter.fromDb(data),
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetResidenciesQuery,
  useCollectDNAMutation,
  useUnCollectDNAMutation,
  useGetResidenciesFilterQuery,
  useGetResidenceMoveInfoQuery,
  useResidentMoveMutation,
  useGetResidentDetailsQuery,
  useMoveOutFromNoticeMutation,
  useMoveOutFromResidentMutation,
  useGetApplicantDetailsQuery,
  useResidentApplicationApproveMutation,
  useResidentApplicationRejectMutation,
  useGetApproveResidencyVerifyQuery,
  useLazyExportCSVQuery,
  useLazyCheckTenantDuplicationQuery,
  useGetResidentAnimalDetailsQuery,
  useResidentMoveInMutation,
  useCancelApplicationMutation,
  useGetReapplyDetailsQuery,
  useReapplyTenantMutation,
  useGetRevokeDetailsQuery,
  useRevokeMoveOutToNoticeMutation,
  useCancelAnimalMoveOutMutation,
  useCancelResidentMoveInMutation,
  useCancelAnimalMoveInMutation,
  useGetResidentsQuery,
  useAddNewResidentMutation,
  useAnimalsScreeningMutation,
  useAddNoticeMutation,
  useRevokeNoticeMutation,
  useRevokeMoveOutToResidentMutation,
  useBulkScreeningMutation,
  useGetLeaseDetailsQuery,
} = residenceApi;
