import React, { FC, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row } from 'antd/components/Grid';
import AuthFormsWrapper from 'antd/componentsShared/AuthFormsWrapper';
import Alert from 'antd/components/Alert';
import Form, { FormItem } from 'antd/components/Form';
import { TextField } from 'antd/components/TextField';
import useForm from 'antd/hooks/useForm';
import { EResetPasswordCasesUI, TSignInAmplifyUI } from 'antd/data/types/auth.types';
import { useResetPasswordCasesMutation, useSignInMutation } from 'antd/redux/apiSlices/auth.slice';
import { RoutesNames } from 'routes/RoutesNames';
import get from 'lodash/get';
import Button from 'antd/components/Button';
import { Paragraph, Text, Title } from 'antd/components/Typography';
import { Link, useLocation } from 'react-router-dom';
import { Colors } from 'core/CssVariables';
import { useStateSafely } from 'antd/hooks/useStateSafely';
import { EErrorCode, TAmplifyErrorPayload } from 'antd/data/types/amplify.types';
import toast from 'antd/components/Notification';
import { loginFields } from './Login.data';
import SetPasswordForm from '../SetPasswordForm';

const { email, password } = loginFields;

const Login: FC = () => {
    const intl = useIntl();
    const { form } = useForm<TSignInAmplifyUI>();
    const [error, setError] = useStateSafely<string>('');
    const alertRef = useRef<HTMLDivElement | null>(null);
    const [signIn, { isLoading, data }] = useSignInMutation();
    const [resetPassword] = useResetPasswordCasesMutation();
    const { state } = useLocation<{ data: EResetPasswordCasesUI.RESEND_INVITATION }>();

    const sendInvitationHandler = async (email: string) => {
        try {
            await resetPassword({ email });
        } catch (err) {
            const error = err as TAmplifyErrorPayload;
            const message = get(error, 'data.message');
            setError(message);
        }
    };

    const onSubmit = async (values: TSignInAmplifyUI) => {
        try {
            await signIn(values).unwrap();
        } catch (err) {
            const error = err as TAmplifyErrorPayload;
            const code = get(error, 'data.code');
            const message = get(error, 'data.message');
            const name = get(error, 'data.name');
            if (name === 'QuotaExceededError' || message.includes('The quota has been exceeded')) {
                setError(intl.formatMessage({ id: "login.quotaExceededError" }));
                window.localStorage.clear();
            }
            if (
                code === EErrorCode.NOT_AUTHORIZED_EXCEPTION &&
                message?.includes('expired')
            ) {
                setError(intl.formatMessage({ id: "login.temporaryPasswordExpired" }, { email: values.email }));
                await sendInvitationHandler(values.email);
            } else {
                setError(message);
            }
        }
    };

    useEffect(() => {
        if (error) {
            alertRef.current?.scrollIntoView({ block: 'end' });
        }
    }, [error]);

    useEffect(() => {
        if (localStorage.getItem('showToast')) {
            toast({
                description: intl.formatMessage({ id: "login.sessionExpired" }),
                type: 'error',
            });
            localStorage.removeItem('showToast');
        }
    }, []);

    if (data?.challengeName === 'NEW_PASSWORD_REQUIRED') {
        return <SetPasswordForm email={form.getFieldValue('email')} temporaryPassword={form.getFieldValue('password')} />;
    }
    return (
        <Row justify='center'>
            <AuthFormsWrapper title={<FormattedMessage id="login.welcomeBackTitle" />}>
                <Title fontLevel={5} className='mb-6'><FormattedMessage id="login.enterCredentials" /></Title>
                {state?.data === EResetPasswordCasesUI.RESEND_INVITATION && <Col span={24} ref={alertRef}>
                    <Alert className='mb-4' type='info' message={<FormattedMessage id="login.emailResent" />} />
                </Col>}
                <Form form={form} onFinish={onSubmit} requiredMark={false} scrollToFirstError>
                    <Row>
                        <Col span={24} ref={alertRef}>
                            <Alert type='error' message={error}  />
                        </Col>
                        <Col span={24}>
                            <FormItem name={email.name} rules={email.rules} label={<FormattedMessage id="login.form.emailLabel" />} >
                                <TextField placeholder={intl.formatMessage({ id: "login.form.emailPlaceholder" })} />
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <FormItem name={password.name} rules={password.rules} label={<FormattedMessage id="login.form.passwordLabel" />}>
                                <TextField placeholder={ intl.formatMessage({ id: "login.form.passwordPlaceholder" })} type='password' />
                            </FormItem>
                        </Col>
                        <Col span={24}>
                            <Button htmlType='submit' loading={isLoading}><FormattedMessage id="login.signIn" /></Button>
                            <Paragraph className='mt-2' fontLevel={5}>
                                <FormattedMessage id="login.forgotPassword" />{' '}
                                <Text color={Colors.DarkBlue} fontLevel={5} underline><Link
                                    to={RoutesNames.ResetPassword}>
                                    <FormattedMessage id="login.resetItHere" />{' '}
                                </Link>
                                </Text>
                            </Paragraph>
                            <Paragraph className='mt-2' fontLevel={5}>
                                <FormattedMessage id="login.dontHaveAnAccount" />{' '}
                                <Text color={Colors.DarkBlue} fontLevel={5} underline>
                                    <a target='_blank' href='https://info.ourpetpolicy.com/demo/?_gl=1*ptednl*_ga*MTQ0Nzc4MDgyOS4xNjkyOTUxNjQ0*_ga_PMK8EKK1SC*MTcxMDc2NzE5OC42LjAuMTcxMDc2NzE5OC4wLjAuMA' rel="noreferrer">
                                        <FormattedMessage id="login.requestDemo" /> {' '}
                                    </a>
                                </Text>
                                <FormattedMessage id="login.setupAnAccount" />
                            </Paragraph>

                        </Col>
                    </Row>
                </Form>
            </AuthFormsWrapper>
        </Row>
    );
};

export default Login;
