import axios, { axiosUnauth, axiosOld, axiosV2 } from '../axios';

const requester = (options) => {
  const { unAuth = false, newBackend, oldBackend, ...opt } = options;
  if (unAuth) {
    return axiosUnauth(opt);
  }
  if (newBackend) {
    return axiosV2(opt);
  }
  if (oldBackend) {
    return axiosOld(opt);
  }

  return axios(opt);
};

export const singleRequestHandler = async (request, convertor) => {
  const response = await requester(request);
  if (convertor) {
    return convertor(response.data);
  }
  return response.data;
};
