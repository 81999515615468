import React, { forwardRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Tab from '@material-ui/core/Tab';

import useCanCreateContract from 'components/Hooks/useCanCreateContract';

import SubMenu from './SubMenu';
import { CustomTooltip } from './CustomTooltip';

const ButtonTab = ({ label, isSelected, route, subRoutes, dataCy, badge, ...restProps }) => {
  const location = useLocation();
  const isTabSelected = isSelected || subRoutes?.some((item) => location.pathname.includes(item.route));
  const { root } = restProps;

  const ButtonWrapper = forwardRef((props, ref) => {
    const { children, ...tabProps } = props;
    return (
      <div>
        <CustomTooltip route={route} badge={badge}>
          <div {...tabProps}>{children}</div>
        </CustomTooltip>
        {isTabSelected && <SubMenu routes={subRoutes} />}
      </div>
    );
  });

  return (
    <Tab
      data-cy={dataCy}
      label={label}
      className={isTabSelected ? 'dashboard-tabs_active' : 'dashboard-tabs'}
      classes={{ root }}
      component={ButtonWrapper}
      {...restProps}
    />
  );
};

export default ButtonTab;
