import {
  EMethodDB,
  EMethodUI,
  TAppPolicyDB,
  TAppPolicyUI,
  TAppPropertyDB,
  TAppPropertyUI,
  TAppResidentDB,
  TAppResidentUI,
  TAppUnitDB,
  TAppUnitUI,
} from '../types/application.types';
import { TFetchConverter } from '../types/converter.types';

export const applicationPropertyConvertor: TFetchConverter<TAppPropertyDB, TAppPropertyUI> = {
  fromDb: (data) => {
    return {
      propertyName: data.propertyName,
      propertyId: data.propertyId,
    };
  },
};

export const applicationPolicyConvertor: TFetchConverter<TAppPolicyDB, TAppPolicyUI> = {
  fromDb: (data) => {
    return {
      policyId: data.policyId,
      policyName: data.policyName,
    };
  },
};

export const applicationUnitConvertor: TFetchConverter<TAppUnitDB, TAppUnitUI> = {
  fromDb: (data) => {
    return {
      unitId: data.unitId,
      unitNumber: data.unitNumber,
    };
  },
};

export const applicationResidentConvertor: TFetchConverter<TAppResidentDB, TAppResidentUI> = {
  fromDb: (data) => {
    return {
      residentPhone: data.residentPhone,
      residentName: data.residentName,
      residentEmail: data.residentEmail,
      residentProfilePicture: data.residentProfilePicture,
    };
  },
};

export const methodsConvertor: TFetchConverter<EMethodUI, EMethodDB> = {
  fromDb: (data) => {
    return EMethodUI[EMethodDB[data] as keyof typeof EMethodUI];
  },
};
