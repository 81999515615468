import { TOptionTypeUi } from './general.types';
import { TPageableDataParamUI, TQueryParams } from './pagableData.type';

export type TUpdateBusinessNameUI = {
  name: string;
  propertyManagementId: string;
};

export type TUpdateBusinessName = {
  name: string;
  propertyManagementId: string;
};

export type TPetChargeReportDB = {
  landlordId: string;
  propertyIds?: string[];
};

export type TPetChargeReportUI = {
  landlordId: string;
  propertyIds: TOptionTypeUi[] | null;
};

export enum ELandlordStatusDB {
  ACTIVE = 'ACTIVE',
  TEST = 'TEST',
  PROSPECT = 'PROSPECT',
  NON_LANDLORD = 'NON_LANDLORD',
  INACTIVE = 'INACTIVE',
}

export enum ELandlordStatusUI {
  ACTIVE = 'Active',
  TEST = 'Test',
  PROSPECT = 'Prospect',
  NON_LANDLORD = 'Non-landlord',
  INACTIVE = 'Inactive',
}

export enum ELandlordPaymentStatusUI {
  PAYMENT_FAILED = 'Payment failed',
  PAID = 'Successful',
}
export enum ELandlordPaymentStatusDB {
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAID = 'PAID',
}

export type TLandlordGetUI = {
  id: string;
  name: string;
  username: string;
  status: ELandlordStatusUI;
  propertyManagementId: string;
  resourceId: string;
  // paymentStatus: ELandlordPaymentStatusUI;
};

export type TLandlordGetDB = {
  id: string;
  businessName: string;
  landlordUsername: string;
  status: ELandlordStatusDB;
  propertyManagementId: string;
  landlordId: string;
  // paymentStatus: ELandlordPaymentStatusDB;
};

export type TChangeLandlordStatusDB = {
  propertyManagementId: string;
  status: ELandlordStatusDB;
};

export type TChangeLandlordStatusUI = {
  propertyManagementId: string;
  propertyManagementStatus: ELandlordStatusUI;
};

export type TPMEmailsExportUI = {
  propertyManagementIds?: string[];
  search?: TPageableDataParamUI['search'];
};
export type TPMEmailsExportDB = TQueryParams | URLSearchParams;
