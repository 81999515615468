export enum EIntegrationUrls {
  CSV = 'csv',
  INTEGRATION = 'integration',
  PREVIEW = 'preview',
  EXECUTE = 'execute',
  TEMPLATE = 'template',
  YARDI = 'yardi',
  PROPERTIES = 'properties',
  REFRESH = 'refresh',
  FREQUENCY = 'frequency',
  LINK = 'link',
  MISSING_STATES = 'missing-states',
  SYNC_STATE = 'sync-state',
  RESMAN = 'resman',
  SYNC = 'sync',
  CHARGE_SYNC_ENABLE = 'charge-sync-enable',
  VALIDATE = 'validate',
  TENANTS_PET_CHARGES = 'tenants-pet-charges',
  IMPORT = 'import',
  EXPORT = 'export',
  REAL_PAGE = 'real_page',
  SETTINGS = 'settings',
  TP = 'tp',
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  UPLOAD = 'upload',
}
