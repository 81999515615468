import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { defaultPagePath } from '../utils/enums';
import Portal from './Portal';

const PublicRoute = ({ component: Component, usersData, ...rest }) => {
  // const showIntro = user?.attributes['custom:showIntro'];
  const { userType } = usersData || {};
  const history = useHistory();
  useEffect(() => {
    if (usersData?.userType) {
      history.push(defaultPagePath[userType].false);
    }
  }, [usersData]);
  return (
    <Route
      {...rest}
      render={(props) => (
        <Portal>
          <Component {...props} />
        </Portal>
      )}
    />
  );
};

export default PublicRoute;
