import { Auth } from 'aws-amplify';
import { RoutesNames } from 'routes/RoutesNames';

export const useCheckIfSessionExists = () => {
  return async () => {
    try {
      await Auth.currentSession(); // returns the session if there are any
    } catch (error) {
      localStorage.setItem('showToast', 'true');
      window.location.replace(RoutesNames.SignIn);
    }
  };
};
