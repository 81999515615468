import React, { FC, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ENotificationDB } from 'antd/data/types/notifications.types';
import { notificationsMapper } from 'antd/data/datas/notificationsData';
import { useAppDispatch } from 'antd/redux/store';
import appSlice from 'antd/redux/slice/app.slice';
import { notificationConverter } from 'antd/data/converters/notifications.converters';

// Todo: add user profile types and converters after change their apis
export const NotificationsRedirectLayer: FC<{ children: ChildNode; profile: { type: ENotificationDB } }> = ({
  children,
  profile,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  useEffect(() => {
    const actionType = notificationConverter.fromDb(searchParams.get('actionType') as ENotificationDB);
    const resourceId = searchParams.get('resourceId');
    if (searchParams.get('actionType')) {
      const notificationObj = notificationsMapper[actionType];
      let { url } = notificationObj;
      if (resourceId) {
        url += `/${resourceId}`;
      }
      if (!notificationObj.private) {
        history.push(url);
        return;
      }
      // this link will be set in private routes
      dispatch(appSlice.actions.setCurrentNotificationUrl(url));
    }
  }, []);

  return <>{children}</>;
};
