import React, { forwardRef, memo, useImperativeHandle } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Tabs from '@material-ui/core/Tabs';
import { RoutesNames, TabValues } from 'routes/RoutesNames';
import theme from 'components/theme';
import { useAppSelector } from 'antd/redux/store';
import ButtonTab from './components/ButtonTab';
import LinkTab from './components/LinkTab';
import { Footer } from './components/Footer';
import { HeaderLoggedOut } from './components/HeaderLoggedOut';
import { useTabList } from './components/TabList';

import { Drawer, TabsWrapper, useStyles } from './sidbar.style';

const VerticalTabsLoggedOut = forwardRef(({ sideBarOpen, onClose, onLogout }, ref) => {
  const classes = useStyles();
  const { users, userType, impersonateOn } = useAppSelector((state) => state.usersSlice);
  const user = 'landlord';
  const { pathname } = useLocation();
  const matches = useMediaQuery('(max-width:768px)');

  const tabValue = TabValues[`/${pathname.split('/')[1]}`];
  const tabListData = useTabList({ impersonate: impersonateOn, profile: user });
  const history = useHistory();

  useImperativeHandle(ref, () => ({
    isSideBarExist: () => !(tabValue === 'intro' || matches),
  }));

  if (tabValue === 'intro') {
    return;
  }
  return (
    <Drawer
      anchor='left'
      open={matches ? sideBarOpen : true}
      onClose={onClose}
      variant={matches ? 'temporary' : 'permanent'}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <HeaderLoggedOut onClose={onClose} onLogout={onLogout} />
      <TabsWrapper>
        <Tabs
          orientation='vertical'
          classes={{
            indicator: classes.indicator,
          }}
          value={tabListData.landlord.some((routeData) => routeData.route === tabValue) ? tabValue : false}
          TabIndicatorProps={{
            style: {
              backgroundColor: theme.palette.primary.main,
            },
          }}
        >
          {tabListData.landlord.map(
            ({ label, icon, route, isButton = false, onClick = () => {}, subRoutes, dataCy }) => {
              if (isButton) {
                return (
                  <ButtonTab
                    key={label}
                    label={
                      <Typography className={classes.tabText} component='p' variant='body1'>
                        {label}
                      </Typography>
                    }
                    onClick={onClick}
                    value={route}
                    route={route}
                    subRoutes={subRoutes || []}
                    icon={icon}
                    isSelected={pathname.includes(route)}
                    dataCy={dataCy}
                    root={classes.root}
                    badge={classes.badge}
                  />
                );
              }

              return (
                <LinkTab
                  key={label}
                  label={
                    <Typography className={classes.tabText} component='p' variant='body1'>
                      {label}
                    </Typography>
                  }
                  value={route}
                  route={route}
                  subRoutes={subRoutes || []}
                  icon={icon}
                  isSelected={pathname.includes(route)}
                  dataCy={dataCy}
                  root={classes.root}
                  badge={classes.badge}
                />
              );
            }
          )}
        </Tabs>
      </TabsWrapper>
      {matches && <Footer onClose={onClose} onLogout={onLogout} />}
    </Drawer>
  );
});

export default memo(VerticalTabsLoggedOut);
