import React, { useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { SPhoneInput } from './PhoneInput.styles';
import { TPhoneInputProps } from './PhoneInput.types';

const PhoneInput = ({
  name,
  onChange = () => {
    /* do nothing */
  },
  onTabKey,
  disabled,
  invalid,
  value: initialValue,
  autoFocus = false,
  ...rest
}: TPhoneInputProps) => {
  const [value, setValue] = useState<string>(initialValue || '');

  return (
    <SPhoneInput
      {...rest}
      country='us'
      inputProps={{ name, autoFocus }}
      value={value}
      onChange={(val: string) => {
        setValue(val);
        onChange(val);
      }}
      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
        if (onTabKey && event.key === 'Tab') {
          event.preventDefault();
          onTabKey();
        }
      }}
      disabled={disabled}
      invalid={invalid}
    />
  );
};

export default PhoneInput;
