import React, { FC, ReactNode } from 'react';
import { Title } from 'antd/components/Typography';
import { Col } from 'antd/components/Grid';
import SAuthFormsWrapper from './AuthFormsWrapper.styles';
import { TAuthFormsWrapperProps } from './AuthFormsWrapper.types';

const AuthFormsWrapper: FC<TAuthFormsWrapperProps> = ({ children, title }) => {
  return (
    <SAuthFormsWrapper>
      {title && (
        <Col className='mb-4'>
          <Title fontLevel={2}>{title}</Title>
        </Col>
      )}
      <Col>{children}</Col>
    </SAuthFormsWrapper>
  );
};

export default AuthFormsWrapper;
