import { TFileDB, TFileUI } from 'antd/data/types/general.types';
import { EStorageUrls } from 'antd/data/urls/storage.url';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { fileConverter } from 'antd/data/converters/general.converters';
import { authSplitApi } from '../helpers/slice.helpers';

const URL = EPropertyMangerUrl.PROPERTY_MANAGER;
export const storageApi = authSplitApi('storage', ['files']).injectEndpoints({
  endpoints: (build) => ({
    uploadFile: build.mutation<TFileUI, FormData>({
      query(body) {
        return {
          url: `${URL}/${EStorageUrls.STORAGE}/${EStorageUrls.FILES}`,
          method: 'POST',
          body,
        };
      },
      transformResponse: (data: TFileDB) => {
        return fileConverter.fromDb(data);
      },
    }),
  }),
  overrideExisting: false,
});

export const { useUploadFileMutation } = storageApi;
