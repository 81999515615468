import { TConvertor, TFetchConverter } from 'antd/data/types/converter.types';
import { formattedDate } from 'antd/helpers/utils';
import {
  EComplaintTitleDB,
  EComplaintTitleUI,
  EComplaintTypeDB,
  EComplaintTypeUI,
  ECreatorTypeDB,
  ECreatorTypeUI,
  TCommentDataDB,
  TCommentDataUI,
  TResidentDB,
  TResidentUI,
  TViolatorDB,
  TViolatorUI,
} from 'antd/data/types/complaintGeneral.types';

export const complaintTypeConverter: TConvertor<EComplaintTypeUI, EComplaintTypeDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EComplaintTypeDB]: EComplaintTypeUI } = {
      [EComplaintTypeDB.COMPLAINT]: EComplaintTypeUI.COMPLAINT,
      [EComplaintTypeDB.VIOLATION]: EComplaintTypeUI.VIOLATION,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EComplaintTypeUI]: EComplaintTypeDB } = {
      [EComplaintTypeUI.COMPLAINT]: EComplaintTypeDB.COMPLAINT,
      [EComplaintTypeUI.VIOLATION]: EComplaintTypeDB.VIOLATION,
    };
    return dataMapper[data];
  },
};

export const complaintTitleConverter: TConvertor<EComplaintTitleUI, EComplaintTitleDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EComplaintTitleDB]: EComplaintTitleUI } = {
      [EComplaintTitleDB.UNAUTHORIZED_ANIMAL]: EComplaintTitleUI.UNAUTHORIZED_ANIMAL,
      [EComplaintTitleDB.AGGRESSIVE_BEHAVIOR]: EComplaintTitleUI.AGGRESSIVE_BEHAVIOR,
      [EComplaintTitleDB.DAMAGE]: EComplaintTitleUI.DAMAGE,
      [EComplaintTitleDB.NOISE_DISTURBANCE]: EComplaintTitleUI.NOISE_DISTURBANCE,
      [EComplaintTitleDB.PET_RULE_VIOLATION]: EComplaintTitleUI.PET_RULE_VIOLATION,
      [EComplaintTitleDB.WASTE_MANAGEMENT]: EComplaintTitleUI.WASTE_MANAGEMENT,
      [EComplaintTitleDB.OTHER]: EComplaintTitleUI.OTHER,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in EComplaintTitleUI]: EComplaintTitleDB } = {
      [EComplaintTitleUI.UNAUTHORIZED_ANIMAL]: EComplaintTitleDB.UNAUTHORIZED_ANIMAL,
      [EComplaintTitleUI.AGGRESSIVE_BEHAVIOR]: EComplaintTitleDB.AGGRESSIVE_BEHAVIOR,
      [EComplaintTitleUI.DAMAGE]: EComplaintTitleDB.DAMAGE,
      [EComplaintTitleUI.NOISE_DISTURBANCE]: EComplaintTitleDB.NOISE_DISTURBANCE,
      [EComplaintTitleUI.PET_RULE_VIOLATION]: EComplaintTitleDB.PET_RULE_VIOLATION,
      [EComplaintTitleUI.WASTE_MANAGEMENT]: EComplaintTitleDB.WASTE_MANAGEMENT,
      [EComplaintTitleUI.OTHER]: EComplaintTitleDB.OTHER,
    };
    return dataMapper[data];
  },
};

export const creatorTypeConverter: TConvertor<ECreatorTypeUI, ECreatorTypeDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in ECreatorTypeDB]: ECreatorTypeUI } = {
      [ECreatorTypeDB.MAINTAINER]: ECreatorTypeUI.MAINTAINER,
      [ECreatorTypeDB.PROPERTY_MANAGER]: ECreatorTypeUI.PROPERTY_MANAGER,
      [ECreatorTypeDB.TENANT]: ECreatorTypeUI.TENANT,
    };
    return dataMapper[data];
  },
  toDb: (data) => {
    const dataMapper: { [key in ECreatorTypeUI]: ECreatorTypeDB } = {
      [ECreatorTypeUI.MAINTAINER]: ECreatorTypeDB.MAINTAINER,
      [ECreatorTypeUI.PROPERTY_MANAGER]: ECreatorTypeDB.PROPERTY_MANAGER,
      [ECreatorTypeUI.TENANT]: ECreatorTypeDB.TENANT,
    };
    return dataMapper[data];
  },
};

export const complaintCommentsConvertor: TFetchConverter<TCommentDataUI, TCommentDataDB> = {
  fromDb: (data) => {
    return {
      commentsCount: data.commentsCount,
      commentsList: data.commentsList.map((comment) => ({
        creatorId: comment.creatorId,
        creatorName: comment.creatorName,
        createdAt: formattedDate(`${comment.createdAt}Z`, 'MM/DD/YYYY hh:mm A'),
        owenComment: comment.owenComment,
        message: comment.massage,
      })),
    };
  },
};

export const complaintViolatorConvertor: TFetchConverter<TViolatorDB, TViolatorUI> = {
  fromDb: (data) => {
    return {
      violatorName: data.violatorName,
      violatorPropertyName: data.violatorPropertyName,
      violatorPropertyId: data.violatorPropertyId,
      violatorUnitName: data.violatorUnitName,
    };
  },
};

export const complaintResidentConverter: TFetchConverter<TResidentUI, TResidentDB> = {
  fromDb: (data) => {
    return {
      residenceId: data.residenceId,
      residentName: data.residentName,
    };
  },
};
