import moment from 'moment';
import type { RuleObject } from './Form.types';
// import { verificationCodeLength } from 'antd/helpers/utils';
import { TRegexPatters, TValidationRules } from './Form.types';

export const regexPatterns: TRegexPatters = {
  oneUpperCase: /[A-Z]+/,
  oneLowerCase: /[a-z]+/,
  oneNumber: /\d/,
  oneSymbol: /[*@!#%&$_+=?.<>;:'"/()^~{}]+/,
  letters6: /.{6,}/,
  letters8: /.{8,}/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phoneNumber: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  domainName: /^((?!-)[A-Za-z0-9-]{1,63}(?!-)\.)+[A-Za-z]{2,6}$/,
  url: /[(http(s)?):(www)?a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/,
  fax: /^\+?[0-9]{7,}$/,
  onlyWhiteSpace: /.*[^ ].*/,
  startEndWhiteSpace: /^\s|\s$/,
  notNumAndLetter: /^[^A-Za-z0-9]+$/,
  onlyEnglishLetters: /^[a-zA-Z\s]*$/,
  zipCode: /^\d{5}(?:-\d{4})?$/,
};

export const passwordValidationRegex: RuleObject[] = [{ type: 'regexp', pattern: regexPatterns.letters6 }];

export const passwordValidator = (value: string, rulesCount: number): boolean => {
  let count = 0;
  passwordValidationRegex.forEach((rule: RuleObject) => {
    if (rule.pattern?.test(value)) {
      count += 1;
    }
  });
  if (count >= rulesCount) {
    return true;
  }
  return false;
};

export const validationRules: TValidationRules = {
  required: (fieldName) => ({
    validator(_: RuleObject, value) {
      if ((typeof value === 'string' && value.trim()) || (typeof value !== 'string' && !!value)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(fieldName ? `The ${fieldName} is required!` : 'This field is required!'));
    },
  }),
  rentalTypeApartment: () => ({
    validator(_: RuleObject, value) {
      if (value >= 4) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(`Number of units should be at least 4!`));
    },
  }),
  email: () => ({
    type: 'email',
    message: 'Incorrect Email Address!',
  }),
  onlyEnglishLetters: () => ({
    pattern: regexPatterns.onlyEnglishLetters,
    message: 'Only english letters are allowed',
  }),
  phoneNumber: () => ({
    pattern: regexPatterns.phoneNumber,
    message: 'Invalid phone number format',
  }),
  url: () => ({
    pattern: regexPatterns.url,
    message: 'Invalid url format',
  }),
  fax: () => ({
    pattern: regexPatterns.fax,
    message: 'Invalid fax number format',
  }),
  zip: () => ({
    pattern: regexPatterns.zipCode,
    message: 'Invalid zip code',
  }),
  oneUpperCase: () => ({
    pattern: regexPatterns.oneUpperCase,
    message: 'At least one uppercase',
  }),
  oneNumber: () => ({
    pattern: regexPatterns.oneNumber,
    message: 'At least one number',
  }),
  oneSymbol: () => ({
    pattern: regexPatterns.oneSymbol,
    message: 'At least one symbol',
  }),
  letters8: () => ({
    pattern: regexPatterns.letters8,
    message: 'At least 8 characters',
  }),
  oneLowerCase: () => ({
    pattern: regexPatterns.oneLowerCase,
    message: 'At least one lowercase',
  }),

  password: [
    {
      validator(_: RuleObject, value) {
        if (passwordValidator(value, 1)) {
          return Promise.resolve();
        }
        return Promise.reject(new Error(''));
      },
    },
  ],
  acceptTerms: [
    {
      validator(_: RuleObject, value) {
        if (value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('Please accept Terms and Conditions!'));
      },
    },
  ],
  passwordConfirm: [
    ({ getFieldValue }) => ({
      validator(_: RuleObject, value) {
        if (getFieldValue('password') === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('The passwords do not match!'));
      },
    }),
  ],
  withoutSpace: (fieldName) => ({
    validator(_: RuleObject, value) {
      if (!regexPatterns.startEndWhiteSpace.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(
          fieldName
            ? `${fieldName} can't start or end with white space!`
            : "This field can't start or end with white space!"
        )
      );
    },
  }),
  startTime: [
    ({ getFieldValue, setFields }) => ({
      validator(_: RuleObject, value: string) {
        if (
          !getFieldValue('startTime') ||
          !getFieldValue('endTime') ||
          moment(getFieldValue('endTime').startOf('day')) > moment(value).startOf('day')
        ) {
          setFields([
            {
              name: 'endTime',
              errors: [],
            },
          ]);
          return Promise.resolve();
        }
        return Promise.reject(new Error('The start time must be earlier than end'));
      },
    }),
  ],
  moveInDate: [
    ({ getFieldValue, setFields }) => ({
      validator(_: RuleObject, value: string) {
        if (
          !getFieldValue('moveInDate') ||
          !getFieldValue('leaseToDate') ||
          moment(getFieldValue('leaseToDate').startOf('day')) > moment(value).startOf('day')
        ) {
          setFields([
            {
              name: 'leaseToDate',
              errors: [],
            },
          ]);
          return Promise.resolve();
        }
        return Promise.reject(new Error('Move in date must be before the lease to date'));
      },
    }),
  ],
  leaseToDate: [
    ({ getFieldValue, setFields }) => ({
      validator(_: RuleObject, value: string) {
        if (
          !getFieldValue('moveInDate') ||
          !getFieldValue('leaseToDate') ||
          moment(getFieldValue('moveInDate').startOf('day')) < moment(value).startOf('day')
        ) {
          setFields([
            {
              name: 'moveInDate',
              errors: [],
            },
          ]);
          return Promise.resolve();
        }
        return Promise.reject(new Error('Lease to date must be after the move in date.'));
      },
    }),
  ],
  endTime: [
    ({ getFieldValue, setFields }) => ({
      validator(_: RuleObject, value: string) {
        if (
          !getFieldValue('startTime') ||
          !getFieldValue('endTime') ||
          moment(getFieldValue('startTime').startOf('day')) < moment(value).startOf('day')
        ) {
          setFields([
            {
              name: 'startTime',
              errors: [],
            },
          ]);
          return Promise.resolve();
        }
        return Promise.reject(new Error('The end time must be later than start'));
      },
    }),
  ],
  movedInResident: [
    ({ getFieldValue, setFields }) => ({
      validator(_: RuleObject, value: string) {
        if (getFieldValue('status') === 'MOVED_IN' && moment(getFieldValue('startTime')).isAfter(moment())) {
          setFields([
            {
              name: 'startTime',
              errors: [],
            },
          ]);
          return Promise.reject(new Error('Cannot move in with a future move in date'));
        }
        return Promise.resolve();
      },
    }),
  ],
  integerOptional: () => ({
    validator(_: RuleObject, value: string) {
      if (!value.match(/[^\d]/g)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('The value should be positive number'));
    },
  }),
  // verificationCode: () => ({
  //   validator: (_, value) =>
  //     value?.length === verificationCodeLength
  //       ? Promise.resolve()
  //       : Promise.reject(),
  //   message: 'This field is required',
  // }),

  microchipNumber: () => ({
    validator: (_: RuleObject, value: string | string[]) =>
      value?.length >= 9 && value?.length <= 15 ? Promise.resolve() : Promise.reject(),
    message: 'This is an uncommon microchip length! Please double check the microchip.',
  }),
};
