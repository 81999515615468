import {
  connectYardiFormConverter,
  integrationDataExportConverter,
  petChargesCodesUpdateConverter,
  petChargeStatusSyncEnableValidationConvertor,
  realPageConnectionSettingsConverter,
  resmanIntegrationConnectConverter,
  resmanIntegrationConverter,
  resmanPropertyConvertor,
  resmanPropertyMatchConverter,
  resmanPropertySycnConverter,
  tpUploadSyncConverter,
  uploadCSVExecuteConverter,
  uploadCSVPreviewConverter,
  yardiIntegrationInfoConvertor,
  yardiIntegrationSyncPeriodicityConvertor,
  yardiNotMatchedProperties,
  yardiPropertyConvertor,
  yardiPropertyMatchConverter,
  yardiPropertySyncStatus,
} from 'antd/data/converters/integration.converter';
import {
  TConnectYardiIntegrationUI,
  TIntegrationDataExportUI,
  TIntegrationDataUI,
  TIntegrationExecutionResultProps,
  TIntegrationResultProps,
  TIntegrationResultPropsUI,
  TIntegrationType,
  TPetChargeSyncEnableValidateDB,
  TPetChargeSyncEnableValidateUI,
  TPropertyForMatchDB,
  TPropertyForMatchUI,
  TPropertySyncStateUI,
  TPUploadSyncUI,
  TRealPageIntegrationSettingsDB,
  TRealPageIntegrationSettingsUI,
  TResmanConnectUI,
  TResmanIntegrationDB,
  TResmanIntegrationUI,
  TResmanPropertiesDB,
  TResmanPropertiesUI,
  TSycnScheduleUI,
  TTPConnectDB,
  TTPDisconnectDB,
  TYardiChargeCodesUI,
  TYardiIntegrationDB,
  TYardiIntegrationUI,
  TYardiPropertyDB,
  TYardiPropertyMatchUI,
  TYardiPropertyUI,
} from 'antd/data/types/integration.types';
import { EIntegrationUrls } from 'antd/data/urls/integration.url';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import {
  TPageableDataParamUI,
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'antd/data/types/pagableData.type';
import { queryParamConverter } from 'antd/data/converters/general.converters';
import { pageableDataConverter } from 'antd/data/converters/pagableData.converters';
import { b64toBlob, downloadFile } from 'antd/api/sevices/fileServices';
import { authSplitApi } from 'antd/redux/helpers/slice.helpers';

const baseUrl = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EIntegrationUrls.CSV}/${EIntegrationUrls.INTEGRATION}`;
const baseYardiUrl = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EIntegrationUrls.INTEGRATION}/${EIntegrationUrls.YARDI}`;
const baseResmanUrl = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EIntegrationUrls.INTEGRATION}/${EIntegrationUrls.RESMAN}`;
const baseRealPageUrl = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EIntegrationUrls.INTEGRATION}/${EIntegrationUrls.REAL_PAGE}`;
const baseTPUrl = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EIntegrationUrls.INTEGRATION}/${EIntegrationUrls.TP}`;

export const integrationApi = authSplitApi('integration', [
  'yardiConnection',
  'yardiProperties',
  'resmanConnection',
  'resmanProperties',
  'petChargeStatusEnableValidation',
  'realPageConnection',
]).injectEndpoints({
  endpoints: (build) => ({
    integrationPreview: build.mutation<TIntegrationResultPropsUI, TIntegrationDataUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EIntegrationUrls.PREVIEW}`,
          method: 'POST',
          body: uploadCSVPreviewConverter.toDb(body),
        };
      },
      transformResponse: (data: TIntegrationResultProps) => uploadCSVPreviewConverter.fromDb(data),
    }),
    integrationExecute: build.mutation<TIntegrationExecutionResultProps, TIntegrationDataUI>({
      query(body) {
        return {
          url: `${baseUrl}/${EIntegrationUrls.EXECUTE}`,
          method: 'POST',
          body: uploadCSVExecuteConverter.toDb(body),
        };
      },
      transformResponse: (data: TIntegrationExecutionResultProps) => uploadCSVExecuteConverter.fromDb(data),
    }),
    getIntegrationCSVTemplate: build.query<{ templateUrl: string }, { type: TIntegrationType }>({
      query(params) {
        return {
          url: `${baseUrl}/${EIntegrationUrls.TEMPLATE}`,
          method: 'GET',
          params: { ...params },
        };
      },
    }),
    getYardiIntegration: build.query<TYardiIntegrationUI, void>({
      query() {
        return {
          url: baseYardiUrl,
          method: 'GET',
        };
      },
      transformResponse: (data: TYardiIntegrationDB) => yardiIntegrationInfoConvertor.fromDb(data),
      providesTags: ['yardiConnection'],
    }),
    getSyncPetChargeEnableValidation: build.query<
      TPetChargeSyncEnableValidateUI,
      { propertyId?: string; policyId?: string }
    >({
      query({ propertyId, policyId }) {
        return {
          url: `${baseYardiUrl}/${EIntegrationUrls.CHARGE_SYNC_ENABLE}/${EIntegrationUrls.VALIDATE}`,
          method: 'GET',
          params: { propertyId, policyId },
        };
      },
      transformResponse: (data: TPetChargeSyncEnableValidateDB) =>
        petChargeStatusSyncEnableValidationConvertor.fromDb(data),
      providesTags: ['petChargeStatusEnableValidation'],
    }),
    connectYardiInetgration: build.mutation<void, TConnectYardiIntegrationUI>({
      query(body) {
        return {
          url: baseYardiUrl,
          method: 'POST',
          body: connectYardiFormConverter.toDb(body),
        };
      },
    }),
    getYardiIntegrationProperties: build.query<TPageableDataWithContentUi<TYardiPropertyUI[]>, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: `${baseYardiUrl}/${EIntegrationUrls.PROPERTIES}`,
          method: 'GET',
          params: queryParamConverter(search, pagination),
        };
      },
      transformResponse: (data: TPageableDataWithContent<TYardiPropertyDB[]>) => {
        const { content, ...other } = data;
        return {
          content: data.content.map((item) => yardiPropertyConvertor.fromDb(item)),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
      providesTags: ['yardiProperties'],
    }),
    yardiIntegrationRefreshProperties: build.mutation<void, void>({
      query() {
        return {
          url: `${baseYardiUrl}/${EIntegrationUrls.PROPERTIES}/${EIntegrationUrls.REFRESH}`,
          method: 'POST',
        };
      },
    }),
    yardiIntegrationDisconnect: build.mutation<void, void>({
      query() {
        return {
          url: `${baseYardiUrl}`,
          method: 'DELETE',
        };
      },
    }),
    yardiIntegrationSetFrequency: build.mutation<void, TSycnScheduleUI>({
      query(body) {
        return {
          url: `${baseYardiUrl}/${EIntegrationUrls.FREQUENCY}`,
          method: 'POST',
          body: yardiIntegrationSyncPeriodicityConvertor.toDb(body),
        };
      },
    }),
    yardiChargingCodesUpdate: build.mutation<void, TYardiChargeCodesUI>({
      query(body) {
        return {
          url: `${baseYardiUrl}`,
          method: 'PUT',
          body: petChargesCodesUpdateConverter.toDb(body),
        };
      },
    }),
    yardiPropertyMatch: build.mutation<void, TYardiPropertyMatchUI>({
      query(body) {
        return {
          url: `${baseYardiUrl}/${EIntegrationUrls.PROPERTIES}/${EIntegrationUrls.LINK}`,
          method: 'POST',
          body: yardiPropertyMatchConverter.toDb(body),
        };
      },
    }),
    getNotMatchedProperties: build.query<TPropertyForMatchUI[], { stateIs: string }>({
      query(params) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EIntegrationUrls.INTEGRATION}/${EIntegrationUrls.LINK}/${EIntegrationUrls.PROPERTIES}`,
          method: 'GET',
          params: { ...params },
        };
      },
      transformResponse: (data: TPropertyForMatchDB[]) => {
        return data.map((item) => yardiNotMatchedProperties.fromDb(item));
      },
    }),
    yardiGetMissingStates: build.query<string[], void>({
      query() {
        return {
          url: `${baseYardiUrl}/${EIntegrationUrls.MISSING_STATES}`,
          method: 'GET',
        };
      },
      transformResponse: (data: string[]) => {
        return data.map((item) => item.charAt(0) + item.slice(1).toLowerCase());
      },
    }),
    yardiPropertiesSycnState: build.mutation<void, TPropertySyncStateUI>({
      query(body) {
        return {
          url: `${baseYardiUrl}/${EIntegrationUrls.PROPERTIES}/${EIntegrationUrls.SYNC_STATE}`,
          method: 'POST',
          body: yardiPropertySyncStatus.toDb(body),
        };
      },
    }),
    yardiReSync: build.mutation<void, { propertyIds: string[] }>({
      query(body) {
        return {
          url: `${baseYardiUrl}/${EIntegrationUrls.SYNC}`,
          method: 'POST',
          body,
        };
      },
    }),
    getResmanIntegration: build.query<TResmanIntegrationUI, void>({
      query() {
        return {
          url: `${baseResmanUrl}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TResmanIntegrationDB) => resmanIntegrationConverter.fromDb(data),
      providesTags: ['resmanConnection'],
    }),
    resmanConnectIntegration: build.mutation<void, TResmanConnectUI>({
      query(body) {
        return {
          url: `${baseResmanUrl}`,
          method: 'POST',
          body: resmanIntegrationConnectConverter.toDb(body),
        };
      },
    }),
    resmanUpdateIntegration: build.mutation<void, TResmanConnectUI>({
      query(body) {
        return {
          url: `${baseResmanUrl}`,
          method: 'PUT',
          body: resmanIntegrationConnectConverter.toDb(body),
        };
      },
    }),
    resmanIntegrationUnlink: build.mutation<void, void>({
      query() {
        return {
          url: `${baseResmanUrl}`,
          method: 'DELETE',
        };
      },
    }),
    getResmanIntegrationProperties: build.query<
      TPageableDataWithContentUi<TResmanPropertiesUI[]>,
      TPageableDataParamUI
    >({
      query({ search, pagination }) {
        return {
          url: `${baseResmanUrl}/${EIntegrationUrls.PROPERTIES}`,
          method: 'GET',
          params: queryParamConverter(search, pagination),
        };
      },
      transformResponse: (data: TPageableDataWithContent<TResmanPropertiesDB[]>) => {
        const { content, ...other } = data;
        return {
          content: data.content.map((item) => resmanPropertyConvertor.fromDb(item)),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
      providesTags: ['resmanProperties'],
    }),
    resmanGetMissingStates: build.query<string[], void>({
      query() {
        return {
          url: `${baseResmanUrl}/${EIntegrationUrls.MISSING_STATES}`,
          method: 'GET',
        };
      },
      transformResponse: (data: string[]) => {
        return data.map((item) => item.charAt(0) + item.slice(1).toLowerCase());
      },
    }),
    resmanRefresh: build.mutation<void, void>({
      query(body) {
        return {
          url: `${baseResmanUrl}/${EIntegrationUrls.PROPERTIES}/${EIntegrationUrls.REFRESH}`,
          method: 'POST',
          body,
        };
      },
    }),
    resmanReSync: build.mutation<void, { propertyIds: string[] }>({
      query(body) {
        return {
          url: `${baseResmanUrl}/${EIntegrationUrls.SYNC}`,
          method: 'POST',
          body,
        };
      },
    }),
    resmanPropertyMatch: build.mutation<void, TYardiPropertyMatchUI>({
      query(body) {
        return {
          url: `${baseResmanUrl}/${EIntegrationUrls.PROPERTIES}/${EIntegrationUrls.LINK}`,
          method: 'POST',
          body: resmanPropertyMatchConverter.toDb(body),
        };
      },
    }),
    resmanPropertySyncState: build.mutation<void, TPropertySyncStateUI>({
      query(body) {
        return {
          url: `${baseResmanUrl}/${EIntegrationUrls.PROPERTIES}/${EIntegrationUrls.SYNC_STATE}`,
          method: 'POST',
          body: resmanPropertySycnConverter.toDb(body),
        };
      },
    }),
    resmanSyncPreview: build.query<TIntegrationResultPropsUI, string>({
      query(propertyId) {
        return {
          url: `${baseResmanUrl}/${EIntegrationUrls.PROPERTIES}/${EIntegrationUrls.SYNC}/${EIntegrationUrls.PREVIEW}`,
          method: 'GET',
          params: { propertyId },
        };
      },
      transformResponse: (data: TIntegrationResultProps) => uploadCSVPreviewConverter.fromDb(data),
    }),
    yardiSyncPreview: build.query<TIntegrationResultPropsUI, string>({
      query(propertyId) {
        return {
          url: `${baseYardiUrl}/${EIntegrationUrls.PROPERTIES}/${EIntegrationUrls.SYNC}/${EIntegrationUrls.PREVIEW}`,
          method: 'GET',
          params: { propertyId },
        };
      },
      transformResponse: (data: TIntegrationResultProps) => uploadCSVPreviewConverter.fromDb(data),
    }),
    integrationDataExport: build.query<TIntegrationResultPropsUI, TIntegrationDataExportUI>({
      query(params) {
        return {
          url: `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EIntegrationUrls.INTEGRATION}/${
            EIntegrationUrls.EXPORT
          }/${params.integrationType.toLowerCase()}/${EIntegrationUrls.CSV}`,
          method: 'GET',
          params: integrationDataExportConverter.toDb(params),
          responseHandler: async (response) => {
            const fileArray: { data: string } = await response.json();
            const file = b64toBlob(fileArray.data, 'application/csv');
            downloadFile(file, `${params.integrationType.toLowerCase()}_${params.propertyName}.csv`);
            return null;
          },
        };
      },
    }),
    getRealPageIntegrationSettings: build.query<TRealPageIntegrationSettingsUI, void>({
      query() {
        return {
          url: `${baseRealPageUrl}/${EIntegrationUrls.SETTINGS}`,
          method: 'GET',
        };
      },
      transformResponse: (data: TRealPageIntegrationSettingsDB) => realPageConnectionSettingsConverter.fromDb(data),
      providesTags: ['realPageConnection'],
    }),
    tpConnect: build.mutation<void, TTPConnectDB>({
      query(body) {
        return {
          url: `${baseTPUrl}/${EIntegrationUrls.CONNECT}`,
          method: 'POST',
          body,
        };
      },
    }),
    tpDisonnect: build.mutation<void, TTPDisconnectDB>({
      query(body) {
        return {
          url: `${baseTPUrl}/${EIntegrationUrls.DISCONNECT}`,
          method: 'DELETE',
          body,
        };
      },
    }),
    tpSyncUpload: build.mutation<void, TPUploadSyncUI>({
      query(body) {
        return {
          url: `${baseTPUrl}/${EIntegrationUrls.SYNC}/${EIntegrationUrls.UPLOAD}`,
          method: 'POST',
          body: tpUploadSyncConverter.toDb(body),
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useIntegrationPreviewMutation,
  useIntegrationExecuteMutation,
  useGetIntegrationCSVTemplateQuery,
  useGetYardiIntegrationQuery,
  useConnectYardiInetgrationMutation,
  useGetYardiIntegrationPropertiesQuery,
  useYardiIntegrationRefreshPropertiesMutation,
  useYardiIntegrationDisconnectMutation,
  useYardiIntegrationSetFrequencyMutation,
  useYardiChargingCodesUpdateMutation,
  useYardiPropertyMatchMutation,
  useLazyGetNotMatchedPropertiesQuery,
  useYardiGetMissingStatesQuery,
  useYardiPropertiesSycnStateMutation,
  useYardiReSyncMutation,
  useResmanConnectIntegrationMutation,
  useGetResmanIntegrationQuery,
  useResmanUpdateIntegrationMutation,
  useResmanIntegrationUnlinkMutation,
  useGetResmanIntegrationPropertiesQuery,
  useResmanGetMissingStatesQuery,
  useResmanRefreshMutation,
  useResmanReSyncMutation,
  useResmanPropertyMatchMutation,
  useResmanPropertySyncStateMutation,
  useYardiSyncPreviewQuery,
  useResmanSyncPreviewQuery,
  useLazyIntegrationDataExportQuery,
  useGetSyncPetChargeEnableValidationQuery,
  useGetRealPageIntegrationSettingsQuery,
  useTpConnectMutation,
  useTpDisonnectMutation,
  useTpSyncUploadMutation,
} = integrationApi;
