import styled, { css, ThemeProps } from 'styled-components';
import { Colors } from 'core/CssVariables';
import {
  TextField as UIKitTextField,
  Password as UIKitPassword,
  TextArea as UIKitTextArea,
  Group as UIKitGroup,
} from '@landlord-tech/ui-kit-opp/dist/components/TextField';

import { TThemeProps } from 'antd/theme/theme';
import { TGroupProps, TInputProps, TInputStyles, EInputTypes, TPasswordProps, TTextAreaProps } from './TextField.types';

const inputStyles: TInputStyles = {
  [EInputTypes.Default]: {
    color: Colors.PrimaryColor,
    hoverColor: Colors.PrimaryColorHover,
    disabledColor: Colors.LightGrey,
  },
  [EInputTypes.SecondaryOutlined]: {
    color: Colors.PrimaryColor,
    backgroundColor: Colors.BackgroundColor,
    hoverColor: Colors.PrimaryColorHover,
    disabledColor: Colors.LightGrey,
  },
  [EInputTypes.Warning]: {
    color: Colors.WarningColor,
    hoverColor: Colors.WarningColorHover,
  },
  [EInputTypes.Success]: {
    color: Colors.SuccessColor,
    hoverColor: Colors.SuccessColorHover,
  },
  [EInputTypes.Danger]: {
    color: Colors.ErrorColor,
    hoverColor: Colors.ErrorColorHover,
  },
};

export const createInputStyles = (theme: TThemeProps, color?: EInputTypes) => css`
  &.ant-input-affix-wrapper,
  &.ant-input {
    padding: 8px 12px;
    width: 100%;
  }
  &.ant-input-affix-wrapper,
  &.ant-input,
  &.ant-input-search,
  &.ant-input-password {
    &,
    .ant-input {
      background-color: ${color
        ? inputStyles[color].backgroundColor || theme.input.backgroundColor
        : theme.input.backgroundColor};
      color: ${theme.input.textColorPrimary};
      border-color: ${color ? inputStyles[color].color : theme.input.borderColor};
      &::placeholder {
        color: ${theme.input.placeholder};
      }
      input {
        color: ${theme.input.textColorPrimary};
        background-color: ${theme.input.backgroundColor};
        &.ant-input {
          padding: 0 1px 0 3px;
        }
      }
      &.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
        &,
        &:hover {
          border-color: ${Colors.ErrorColor};
        }
      }
      &:hover {
        box-shadow: ${color
          ? `0px 0px 7px 0px ${inputStyles[color].color}21`
          : `0px 0px 7px 0px ${Colors.PrimaryColor}21`};
        border-color: ${color ? inputStyles[color].color : Colors.WarningColor};
      }
      &:active,
      &:focus,
      &.ant-input-affix-wrapper-focused {
        border-color: ${color ? inputStyles[color].color : Colors.PrimaryColor};
        box-shadow: none;
        &::placeholder {
          color: ${Colors.LightGrey};
        }
      }
      &:disabled,
      &:disabled:hover,
      &:disabled:focus,
      &:disabled:active {
        pointer-events: none;
        user-select: none;
        cursor: default;
        border-color: ${color
          ? inputStyles[color].disabledColor || `${inputStyles[color].color}4D`
          : theme.input.borderColor};
        &::placeholder {
          color: ${theme.input.disabledPlaceholder};
        }
        &:-webkit-autofill {
          background-color: ${theme.input.backgroundColor};
        }
      }
      &.ant-input-password {
        padding: 8px 12px 8px 4px;
        input {
          padding-left: 8px;
        }
      }
    }
  }
`;

export const SInput = styled(UIKitTextField)`
  &&& {
    ${(props: TInputProps & ThemeProps<TThemeProps>) => {
      return createInputStyles(props.theme, props.color || EInputTypes.Default);
    }}
  }
`;

export const SPassword = styled(UIKitPassword)`
  &&& {
    ${(props: TPasswordProps & ThemeProps<TThemeProps>) => {
      return createInputStyles(props.theme, props.color);
    }}
  }
`;

export const STextArea = styled(UIKitTextArea)`
  &&& {
    ${(props: TTextAreaProps & ThemeProps<TThemeProps>) => {
      return createInputStyles(props.theme, props.color);
    }}
  }
`;

export const SGroup = styled(UIKitGroup)`
  &&& {
    ${(props: TGroupProps & ThemeProps<TThemeProps>) => {
      return createInputStyles(props.theme, props.color);
    }}
  }
`;
