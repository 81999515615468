import { TCreateConverter, TFetchConverter } from 'antd/data/types/converter.types';
import {
  EMaintainerStatusDB,
  EMaintainerStatusUI,
  TAssignedPropertyDB,
  TAssignedPropertyUI,
  TAssignPropertiesDB,
  TAssignPropertiesUI,
  TInviteMaintainerDB,
  TInviteMaintainerUI,
  TMaintainerDB,
  TMaintainerDetailsDB,
  TMaintainerDetailsUI,
  TMaintainerUI,
  TUpdateCommunicationDB,
  TUpdateCommunicationUI,
} from 'antd/data/types/maintainer.types';

export const maintainerStatusConvertor: TFetchConverter<EMaintainerStatusUI, EMaintainerStatusDB> = {
  fromDb: (data) => {
    const dataMapper: { [key in EMaintainerStatusDB]: EMaintainerStatusUI } = {
      [EMaintainerStatusDB.INVITED]: EMaintainerStatusUI.INVITED,
      [EMaintainerStatusDB.ACTIVE]: EMaintainerStatusUI.ACTIVE,
      [EMaintainerStatusDB.INACTIVE]: EMaintainerStatusUI.INACTIVE,
    };
    return dataMapper[data];
  },
};

export const maintainerGetConverter: TFetchConverter<TMaintainerUI, TMaintainerDB> = {
  fromDb: (data) => ({
    id: data.maintainerId,
    fullName: data.fullName,
    phone: data.phone,
    photoUrl: data.photoUrl,
    propertyCount: data.propertyCount,
    status: maintainerStatusConvertor.fromDb(data.status),
    communicateWithResidents: data.communicateWithResidents,
  }),
};

export const maintainerDetailsConverter: TFetchConverter<TMaintainerDetailsUI, TMaintainerDetailsDB> = {
  fromDb: (data) => ({
    id: data.maintainerId,
    fullName: data.fullName,
    phone: data.phone,
    photoUrl: data.photoUrl,
    status: maintainerStatusConvertor.fromDb(data.status),
    communicateWithResidents: data.communicateWithResidents,
  }),
};

export const assignedPropertiesGetConverter: TFetchConverter<TAssignedPropertyUI, TAssignedPropertyDB> = {
  fromDb: (data) => ({
    id: data.id,
    name: data.name,
    address1: data.address1,
    type: data.type,
    unitCount: data.unitCount,
    photoUrl: data.photoUrl,
  }),
};

export const inviteMaintainerConverter: TCreateConverter<TInviteMaintainerUI, TInviteMaintainerDB> = {
  toDb: (data) => ({
    maintainerName: data.fullName,
    phone: `+${data.phoneNumber}`,
    communicateWithResidents: data.reachTenants,
    properties: data.properties.map((item) => item.value),
  }),
};

export const assignPropertiesConverter: TCreateConverter<TAssignPropertiesUI, TAssignPropertiesDB> = {
  toDb: (data) => ({
    maintainerId: data.maintainerId,
    properties: data.properties,
  }),
};

export const updateCommunicationConverter: TCreateConverter<TUpdateCommunicationUI, TUpdateCommunicationDB> = {
  toDb: (data) => ({
    maintainerId: data.maintainerId,
    communicateWithResidents: data.communicateWithResidents,
  }),
};
