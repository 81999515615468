import { TCreateConverter, TFetchConverter } from 'antd/data/types/converter.types';
import { formattedDate } from 'antd/helpers/utils';
import {
  complaintCommentsConvertor,
  complaintResidentConverter,
  complaintTitleConverter,
  complaintTypeConverter,
  complaintViolatorConvertor,
} from 'antd/data/converters/complaintGeneral.converters';
import { fileConverter } from './general.converters';
import {
  TChildComplaintDB,
  TChildComplaintUI,
  TNoticeDB,
  TNoticeUI,
  TAddAttachmentUI,
  TAddAttachmentDB,
  TAddCommentUI,
  TAddCommentDB,
  TNoticeDetailsUI,
  TNoticeDetailsDB,
  TReporterUI,
  TReporterDB,
} from '../types/notice.types';
import { complaintCreatorConvertor } from './complaint.converters';

export const noticeGetConverter: TFetchConverter<TNoticeUI, TNoticeDB> = {
  fromDb: (data) => {
    return {
      id: data.id,
      againstTo: data.againstTo || '',
      creator: complaintCreatorConvertor.fromDb({
        reporterId: data.reporterId,
        reporterName: data.reporterName,
        reporterPhoto: data.reporterPhoto,
        reporterProfileType: data.reporterProfileType,
      }),
      resident: complaintResidentConverter.fromDb({ residenceId: data.residenceId, residentName: data.residentName }),
      propertyName: data.propertyName,
      unitName: data.unitName,
      type: complaintTypeConverter.fromDb(data.type),
      title: complaintTitleConverter.fromDb(data.title),
      commentsCount: data.commentsCount,
      createdAt: formattedDate(`${data.createdAt}Z`, 'MM/DD/YYYY hh:mm A'),
      lastUpdateDate: formattedDate(`${data.lastUpdateDate}Z`, 'MM/DD/YYYY hh:mm A'),
      isViewed: data.isViewed,
      viewed: data.viewed,
      hasUnreadComment: data.hasUnreadComment,
    };
  },
};

export const childComplaintConvertor: TFetchConverter<TChildComplaintUI, TChildComplaintDB> = {
  fromDb: (data) => {
    return {
      id: data.id,
      createdAt: formattedDate(`${data.createdAt}Z`, 'MM/DD/YYYY hh:mm A'),
      type: complaintTypeConverter.fromDb(data.type),
      title: complaintTitleConverter.fromDb(data.title),
      description: data.description,
      commentsCount: data.commentsCount,
      hasUnreadComment: data.hasUnreadComment,
    };
  },
};

export const noticeDetailsConvertor: TFetchConverter<TNoticeDetailsUI, TNoticeDetailsDB> = {
  fromDb: (data) => ({
    id: data.id,
    conversationWith: data.conversationWith,
    creator: complaintCreatorConvertor.fromDb({
      reporterId: data.reporterId,
      reporterName: data.reporterName,
      reporterPhoto: data.reporterPhoto,
      reporterProfileType: data.reporterProfileType,
    }),
    violator: complaintViolatorConvertor.fromDb({
      violatorName: data.violatorName,
      violatorPropertyId: data.propertyId,
      violatorPropertyName: data.violatorPropertyName,
      violatorUnitName: data.violatorUnitName,
    }),
    createdAt: formattedDate(`${data.createdAt}Z`, 'MM/DD/YYYY hh:mm A'),
    type: complaintTypeConverter.fromDb(data.type),
    title: complaintTitleConverter.fromDb(data.title),
    description: data.description,
    hasUnreadComment: data.hasUnreadComment,
    attachments: data.attachments.map((attachment) => fileConverter.fromDb(attachment)),
    comments: complaintCommentsConvertor.fromDb({
      commentsCount: data.commentsCount,
      commentsList: data.comments,
    }),
    viewed: data.viewed,
    childComplaints: (data.childComplaints || []).map((child) => childComplaintConvertor.fromDb(child)),
  }),
};

export const addAttachmentNoticeConvertor: TCreateConverter<TAddAttachmentUI, TAddAttachmentDB> = {
  toDb: (data) => ({
    noticeId: data.noticeId,
    attachments: data.attachments.map((item) => fileConverter.toDb(item)) || [],
  }),
};
export const addCommentNoticeConvertor: TCreateConverter<TAddCommentUI, TAddCommentDB> = {
  toDb: (data) => ({
    noticeId: data.noticeId,
    message: data.comment,
  }),
};

export const reporterConvertor: TFetchConverter<TReporterUI, TReporterDB> = {
  fromDb: (data) => ({
    name: data.name,
    value: data.id,
  }),
};
