import { EVisitationUrl } from 'antd/data/urls/visitations.url';
import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import {
  TVisistationAnimalsDB,
  TVisistationAnimalsUI,
  TVisitationAnimalDB,
  TVisitationAnimalUI,
} from 'antd/data/types/visitation.types';
import { getVisitationAnimalConverter, visitationAnimalsConverter } from 'antd/data/converters/visitation.converter';
import {
  TPageableDataParamUI,
  TPageableDataWithContent,
  TPageableDataWithContentUi,
} from 'antd/data/types/pagableData.type';
import { queryParamConverter } from 'antd/data/converters/general.converters';
import { pageableDataConverter } from 'antd/data/converters/pagableData.converters';
import { authSplitApi } from '../helpers/slice.helpers';

const baseUrl = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EVisitationUrl.VISITATIONS}`;

export const visitationApi = authSplitApi('visitationApi', ['visitationAnimal', 'visitationAnimals']).injectEndpoints({
  endpoints: (build) => ({
    getVisitationAnimal: build.query<TVisitationAnimalUI, string>({
      query(id) {
        return {
          url: `${baseUrl}/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['visitationAnimalDetails'],
      transformResponse: (data: TVisitationAnimalDB) => getVisitationAnimalConverter.fromDb(data),
    }),
    dontApproveAnimalVisitation: build.mutation<void, { animalVisitationId: string; reason: string }>({
      query({ animalVisitationId, reason }) {
        return {
          url: `${baseUrl}/${EVisitationUrl.REJECT}`,
          method: 'PATCH',
          body: { animalVisitationId, reason },
        };
      },
      invalidatesTags: ['visitationAnimalDetails'],
    }),

    approveAnimalVisitation: build.mutation<void, { animalVisitationId: string }>({
      query({ animalVisitationId }) {
        return {
          url: `${baseUrl}/${EVisitationUrl.APPROVE}`,
          method: 'PATCH',
          body: { animalVisitationId },
        };
      },
      invalidatesTags: ['visitationAnimalDetails'],
    }),
    getVisitationAnimals: build.query<TPageableDataWithContentUi<TVisistationAnimalsUI[]>, TPageableDataParamUI>({
      query({ search, pagination }) {
        return {
          url: `${baseUrl}`,
          method: 'GET',
          params: queryParamConverter(search, pagination, {}),
        };
      },
      providesTags: ['visitationAnimals'],
      transformResponse: (data: TPageableDataWithContent<TVisistationAnimalsDB[]>) => {
        const { content, ...other } = data;
        return {
          content: data.content.map((item) => visitationAnimalsConverter.fromDb(item)),
          paginationData: pageableDataConverter.fromDb(other),
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetVisitationAnimalQuery,
  useDontApproveAnimalVisitationMutation,
  useApproveAnimalVisitationMutation,
  useGetVisitationAnimalsQuery,
} = visitationApi;
