import { EPropertyMangerUrl } from 'antd/data/urls/propertyManager.url';
import { EAuthUrls } from 'antd/data/urls/auth.url';
import {
  confirmSIgnUpConverter,
  resetPasswordCasesConverter,
  resetPasswordConverter,
  resetPasswordFormConverter,
  signInAmplifyConverter,
  signupDataConverter,
} from 'antd/data/converters/auth.converters';
import {
  EResetPasswordCasesDB,
  EResetPasswordCasesUI,
  TConfirmSignUpUI,
  TResetPasswordCasesUI,
  TResetPasswordDB,
  TResetPasswordFormUI,
  TResetPasswordUI,
  TSetPasswordFormUI,
  TSignInAmplifyUI,
  TSignupDataUI,
} from 'antd/data/types/auth.types';
import { Auth } from 'aws-amplify';
import { ICurrentAuthUser, TAmplifyError, TCurrentAuthUserUI } from 'antd/data/types/amplify.types';
import { currentAuthUserConvertor } from 'antd/data/converters/amplify.converters';
import { emptySplitApi } from '../helpers/slice.helpers';
import { QueryReturnValue } from '../store';
import { setNewPasswordService } from '../apiServices/auth.services';

const baseUrl = `${EPropertyMangerUrl.PROPERTY_MANAGER}/${EAuthUrls.AUTH}`;

export const authApi = emptySplitApi('auth').injectEndpoints({
  endpoints: (build) => ({
    signUp: build.mutation<string, TSignupDataUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EAuthUrls.SIGNUP}`,
          method: 'POST',
          body: signupDataConverter.toDb(data),
        };
      },
      transformResponse: (id: string) => id,
    }),
    signOut: build.mutation<void, void>({
      // @ts-ignore
      async queryFn(arg) {
        try {
          await Auth.signOut();
          return { data: null };
        } catch (e) {
          return { error: e };
        }
      },
    }),
    signIn: build.mutation<TCurrentAuthUserUI, TSignInAmplifyUI>({
      // @ts-ignore
      async queryFn(arg) {
        try {
          const { username, password } = signInAmplifyConverter.toDb(arg);
          const amplifyUser = await Auth.signIn(username, password, {
            version: 'v2',
          });
          const convertedUser = currentAuthUserConvertor.fromDb(amplifyUser);
          localStorage.setItem('user', JSON.stringify(amplifyUser));
          return {
            data: convertedUser,
          } as QueryReturnValue<ICurrentAuthUser>;
        } catch (e) {
          const error = e as TAmplifyError;
          return {
            error: {
              data: {
                name: error.name,
                code: error.code,
                message: error.message,
                username: arg.email,
              },
              status: error.code,
            },
          };
        }
      },
    }),
    resetPasswordCases: build.mutation<TResetPasswordUI, TResetPasswordCasesUI>({
      query(data) {
        return {
          url: `${baseUrl}/${EAuthUrls.FORGOT_PASSWORD}`,
          method: 'POST',
          body: resetPasswordCasesConverter.toDb(data),
        };
      },
      transformResponse: (data: TResetPasswordDB) => resetPasswordConverter.fromDb(data),
    }),
    resetPasswordAmplify: build.mutation<void, TResetPasswordFormUI>({
      // @ts-ignore
      async queryFn(data) {
        const { username, password, code } = resetPasswordFormConverter.toDb(data);
        try {
          await Auth.forgotPasswordSubmit(username, code, password);
          return { data: null };
        } catch (e) {
          const error = e as TAmplifyError;
          return {
            error: {
              data: {
                name: error.name,
                code: error.code,
                message: error.message,
              },
              status: error.code,
            },
          };
        }
      },
    }),
    sendConfirmationCode: build.mutation<void, string>({
      // @ts-ignore
      async queryFn(email) {
        try {
          await Auth.resendSignUp(email, { version: 'v2' });
          return { data: null };
        } catch (e) {
          const error = e as TAmplifyError;
          return {
            error: {
              data: {
                name: error.name,
                code: error.code,
                message: error.message,
              },
              status: error.code,
            },
          };
        }
      },
    }),
    setNewPassword: build.mutation<TCurrentAuthUserUI, TSetPasswordFormUI>({
      // @ts-ignore
      async queryFn(arg, { dispatch }, options, baseQuery) {
        try {
          await setNewPasswordService(baseQuery, arg);
          const amplifyUser = await Auth.signIn(arg.email, arg.password, {
            version: 'v2',
          });
          const convertedUser = currentAuthUserConvertor.fromDb(amplifyUser);
          localStorage.setItem('user', JSON.stringify(amplifyUser));
          return {
            data: convertedUser,
          } as QueryReturnValue<ICurrentAuthUser>;
        } catch (e) {
          const error = e as TAmplifyError;
          return {
            error: {
              data: {
                name: error.name,
                code: error.code,
                message: error.message,
              },
              status: error.code,
            },
          };
        }
      },
    }),
    confirmSignUp: build.mutation<void, TConfirmSignUpUI>({
      // @ts-ignore
      async queryFn(data, ba) {
        try {
          const { username, code } = confirmSIgnUpConverter.toDb(data);
          await Auth.confirmSignUp(username, code);
          return { data: null };
        } catch (e) {
          const error = e as TAmplifyError;
          return {
            error: {
              data: {
                name: error.name,
                code: error.code,
                message: error.message,
              },
              status: error.code,
            },
          };
        }
      },
    }),
    getIsAdmin: build.query<boolean, { username: string }>({
      query({ username }) {
        return {
          url: `${baseUrl}/${EAuthUrls.IS_ADMIN}`,
          method: 'GET',
          params: { username },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useSignUpMutation,
  useSignInMutation,
  useSignOutMutation,
  useResetPasswordCasesMutation,
  useResetPasswordAmplifyMutation,
  useSendConfirmationCodeMutation,
  useConfirmSignUpMutation,
  useSetNewPasswordMutation,
  useGetIsAdminQuery,
} = authApi;
